/*!-----------------------------------------------------------------
    Name: Godlike - Gaming HTML Template
    Version: 2.4.2
    Author: nK
    Website: https://nkdev.info/
    Purchase: https://1.envato.market/godlike-html-info
    Support: https://nk.ticksy.com/
    License: You must have a valid license purchased only from ThemeForest (the above link) in order to legally use the theme for your project.
    Copyright 2021.
-------------------------------------------------------------------*/
    @charset "UTF-8";
/*------------------------------------------------------------------
  [Typography]

  Body:     16px/1.7 Roboto Condensed, sans-serif;
  Headings: Marcellus SC, serif

  Note: Most of font sizes are relative to the base font size (rem)
 -------------------------------------------------------------------*/
/*------------------------------------------------------------------
  [Color codes]

  Main 1:    #e08821
  Main 2:    #39a228
  Main 3:    #2953b1
  Main 4:    #7c34a8
  Main 5:    #c82e2e

  Dark 1:    #0e0e0e
  Dark 2:    #181818
  Dark 3:    #222222
  Dark 4:    #2d2d2d

  Gray 1:    #fafafa
  Gray 2:    #f7f7f7
  Gray 3:    whitesmoke
  Gray 4:    #f2f2f2

  Bootstrap Primary:  #0275d8
  Bootstrap Success:  #5cb85c
  Bootstrap Info:     #5bc0de
  Bootstrap Warning:  #f0ad4e
  Bootstrap Danger:   #d9534f
 -------------------------------------------------------------------*/
/*------------------------------------------------------------------
  [Table of contents]
  
  1. Base
  2. Page Preloader
  3. Typography
  4. Bootstrap
  5. Helpers
  6. Cookie Alert
  7. Page Border
  8. Navbar
  9. Navbar Side
  10. Navbar Full
  11. Search
  12. Cart
  13. Sign Form
  14. Header Title
  15. Footer
  16. Side Buttons
  17. Share Buttons
  18. Widgets
  19. Elements
    - Element Buttons
    - Element Progress
    - Element Tabs
    - Element Accordion
    - Element Icon Boxes
    - Element Content Boxes
    - Element Counters
    - Element Dividers
    - Element Breadcrumbs
    - Element Dropcaps
    - Element Pagination
    - Element Pricing
    - Element Testimonials
    - Element Blockquotes
    - Element Info Boxes
    - Element Carousels
    - Element Image Boxes
    - Element Team Members
    - Element Video
    - Element Gif
    - Element Forms
    - Element Audio Player
    - Element Countdown
    - Element Text Typed
    - Element Link Effects
    - Element Icons
    - Element Isotope
    - Element Action Like
  20. Blog
  21. Media Gallery
  22. Store
  23. Forum
  24. Social Network
  25. Plugins
    - Plugin NanoScroller
    - Plugin PhotoSwipe
    - Plugin Summernote
 -------------------------------------------------------------------*/
/*------------------------------------------------------------------

  Base

 -------------------------------------------------------------------*/
body {
    color: #fff;
    word-wrap: break-word;
    background-color: #0e0e0e;
}

.nk-main {
    overflow: hidden;
}

body.nk-page-boxed {
    max-width: 1200px;
    margin: 0 auto;
}

a {
    color: #e08821;
}

a:focus,
a:hover {
    color: #b56d19;
}

::-moz-selection {
    color: #2d2d2d;
    background: rgba(255, 255, 255, 0.9);
}

::selection {
    color: #2d2d2d;
    background: rgba(255, 255, 255, 0.9);
}

.nk-block {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 1;
}

.nk-block::after, .nk-block::before {
    content: "";
    display: table;
    clear: both;
}

.bg-image,
.bg-video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 50% 50%;
    background-size: cover;
    z-index: -1;
}

.bg-image > div,
.bg-video > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 50% 50%;
    background-size: cover;
}

.bg-image img,
.bg-video img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: "object-fit: cover;";
    z-index: -1;
    -o-object-fit: cover;
       object-fit: cover;
}

.bg-image-row,
.bg-video-row,
.bg-map-row {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.bg-image-row > div,
.bg-video-row > div,
.bg-map-row > div {
    height: 100%;
    overflow: hidden;
}

@media (max-width: 991px) {
    .bg-image-row,
    .bg-video-row,
    .bg-map-row {
        position: relative;
    }
    .bg-image-row > div,
    .bg-video-row > div,
    .bg-map-row > div {
        padding-top: 56.25%;
    }
    .bg-image-row > div > div,
    .bg-video-row > div > div,
    .bg-map-row > div > div {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.bg-map-row {
    z-index: auto;
}

.bg-map-row .nk-gmaps {
    height: 100%;
    z-index: 1;
}

@media (max-width: 991px) {
    .bg-map-row {
        position: relative;
    }
    .bg-map-row > div {
        padding-top: 0;
    }
    .bg-map-row > div > div {
        position: relative;
    }
    .bg-map-row .nk-gmaps {
        height: 300px;
    }
    .bg-map-row .nk-gmaps-sm {
        height: 250px;
    }
    .bg-map-row .nk-gmaps-md {
        height: 450px;
    }
    .bg-map-row .nk-gmaps-lg {
        height: 550px;
    }
    .bg-map-row .nk-gmaps-full {
        height: 700px;
        height: 100vh;
        min-height: 700px;
    }
}

.nk-page-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 50% 50%;
    background-size: cover;
    z-index: -1;
}

.nk-page-background video,
.nk-page-background iframe {
    opacity: 0;
    transition: opacity .3s;
}

.nk-page-background.nk-page-background-loaded video,
.nk-page-background.nk-page-background-loaded iframe {
    opacity: 1;
}

.bg-color {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #0e0e0e;
    z-index: -1;
}

.nk-gap,
.nk-gap-1,
.nk-gap-2,
.nk-gap-3,
.nk-gap-4,
.nk-gap-5,
.nk-gap-6 {
    display: block;
    height: 20px;
}

.nk-gap::after, .nk-gap::before,
.nk-gap-1::after,
.nk-gap-1::before,
.nk-gap-2::after,
.nk-gap-2::before,
.nk-gap-3::after,
.nk-gap-3::before,
.nk-gap-4::after,
.nk-gap-4::before,
.nk-gap-5::after,
.nk-gap-5::before,
.nk-gap-6::after,
.nk-gap-6::before {
    content: "";
    display: table;
    clear: both;
}

.nk-gap-1 {
    height: 30px;
}

.nk-gap-2 {
    height: 40px;
}

.nk-gap-3 {
    height: 60px;
}

.nk-gap-4 {
    height: 80px;
}

.nk-gap-5 {
    height: 100px;
}

.nk-gap-6 {
    height: 120px;
}

.nk-body-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

.nk-gmaps {
    width: 100%;
    height: 300px;
}

.nk-gmaps-sm {
    height: 250px;
}

.nk-gmaps-md {
    height: 450px;
}

.nk-gmaps-lg {
    height: 550px;
}

.nk-gmaps-full {
    height: 700px;
    height: 100vh;
    min-height: 700px;
}

.nk-badge {
    display: inline-block;
    min-width: 15px;
    height: 15px;
    padding: 0 4px;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    background-color: #e08821;
    border-radius: 7.5px;
}

.nk-navbar li > a .nk-badge {
    position: absolute;
    top: 0;
    right: 5px;
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.nk-navbar li > a .nk-icon-toggle .nk-badge {
    right: -3px;
}

.nk-scroll-top {
    cursor: pointer;
}

pre {
    position: relative;
    max-height: 500px;
    padding: 25px;
    line-height: 1.3;
    color: #fff;
    background-color: #222222;
}

pre .tag {
    display: inline-block;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-align: inherit;
    white-space: inherit;
    vertical-align: inherit;
    border-radius: 0;
}

pre[class*="language-"] {
    margin: 0;
}

/* DEPRECATED, added for back compatibility */
.nk-vertical-center,
.nk-vertical-bottom,
.nk-vertical-top {
    display: table;
    width: 100%;
    height: 100%;
}

.nk-vertical-center > div,
.nk-vertical-bottom > div,
.nk-vertical-top > div {
    display: table-cell;
    vertical-align: middle;
}

.nk-vertical-bottom > div {
    vertical-align: bottom;
}

.nk-vertical-top > div {
    vertical-align: top;
}

.row.no-gap {
    margin-right: 0;
    margin-left: 0;
}

.row.no-gap > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

.text-xs-center {
    text-align: center;
}

.text-xs-left {
    text-align: left;
}

.text-xs-right {
    text-align: right;
}

.pull-xs-right {
    float: right;
}

.pull-xs-left {
    float: left;
}

.pull-xs-none {
    float: none;
}

/*------------------------------------------------------------------

 Page Preloader

 -------------------------------------------------------------------*/
.nk-preloader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 3000;
}

.nk-preloader .nk-preloader-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: cover;
}

.nk-preloader .nk-preloader-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.nk-preloader .nk-preloader-content > div {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
}

.nk-preloader .nk-preloader-skip {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 25px;
    font-size: .95rem;
    line-height: 1;
    cursor: pointer;
    -webkit-animation: nk-preloader-skip 6s linear;
            animation: nk-preloader-skip 6s linear;
    z-index: 1;
}

@-webkit-keyframes nk-preloader-skip {
    0%,
    90% {
        visibility: hidden;
        opacity: 0;
    }
    100% {
        visibility: visible;
        opacity: 1;
    }
}

@keyframes nk-preloader-skip {
    0%,
    90% {
        visibility: hidden;
        opacity: 0;
    }
    100% {
        visibility: visible;
        opacity: 1;
    }
}

.nk-preloader-animation {
    position: relative;
    display: block;
    margin: 30px auto;
    opacity: .7;
    -webkit-animation: nk-preloader-animation 1.2s linear infinite .4s;
            animation: nk-preloader-animation 1.2s linear infinite .4s;
}

.nk-preloader-animation::after, .nk-preloader-animation::before {
    content: "";
    position: absolute;
    display: block;
}

.nk-preloader-animation, .nk-preloader-animation::after, .nk-preloader-animation::before {
    width: 4px;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
}

.nk-preloader-animation::before {
    left: -20px;
    -webkit-animation: nk-preloader-animation 1.2s linear infinite 0s;
            animation: nk-preloader-animation 1.2s linear infinite 0s;
}

.nk-preloader-animation::after {
    right: -20px;
    -webkit-animation: nk-preloader-animation 1.2s linear infinite .8s;
            animation: nk-preloader-animation 1.2s linear infinite .8s;
}

@-webkit-keyframes nk-preloader-animation {
    0%,
    60%,
    100% {
        background-color: rgba(255, 255, 255, 0.3);
    }
    30% {
        background-color: #fff;
    }
}

@keyframes nk-preloader-animation {
    0%,
    60%,
    100% {
        background-color: rgba(255, 255, 255, 0.3);
    }
    30% {
        background-color: #fff;
    }
}

/*------------------------------------------------------------------

  Typography

 -------------------------------------------------------------------*/
html {
    font-size: 16px;
}

body {
    font-family: "Roboto Condensed", sans-serif;
    line-height: 1.7;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p,
blockquote {
    margin-bottom: 1.7rem;
}

b,
strong {
    font-weight: 600;
}

/* headings */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 1rem;
    font-family: "Marcellus SC", serif;
    font-weight: 600;
}

.display-1,
.display-2,
.display-3,
.display-4 {
    font-family: "Marcellus SC", serif;
    font-weight: 600;
}

.display-1 {
    font-size: 4.7rem;
}

.display-2 {
    font-size: 4.2rem;
}

.display-3 {
    font-size: 3.7rem;
}

.display-4 {
    font-size: 3.2rem;
}

/* Title */
.nk-title {
    position: relative;
    z-index: 1;
}

/* Sub Title */
.nk-sub-title {
    position: relative;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1.1rem;
    font-style: italic;
    font-weight: 400;
    color: #e08821;
    z-index: 1;
}

.nk-title + .nk-sub-title {
    margin-top: 1rem;
}

/* Back title */
.nk-title-back {
    margin-bottom: 0;
    font-size: 6rem;
    color: inherit;
    opacity: .1;
}

.nk-title-back + * {
    position: relative;
    margin-top: -40px;
}

.nk-title-back + .nk-sub-title {
    margin-top: -50px;
}

/* Title Separator */
.nk-title-sep {
    display: block;
    margin: 30px auto;
    line-height: 0;
    color: inherit;
}

.nk-title-sep::after {
    content: "";
    display: inline-block;
    width: 80%;
    max-width: 80px;
    vertical-align: middle;
    border-bottom: 1px dotted;
    opacity: .4;
}

.nk-title-sep-icon {
    display: block;
    margin: 30px auto;
    line-height: 0;
    color: inherit;
}

.nk-title-sep-icon .icon {
    font-size: 1.9rem;
    vertical-align: middle;
}

.nk-title-sep-icon::before, .nk-title-sep-icon::after {
    content: "";
    display: inline-block;
    width: 60px;
    vertical-align: middle;
    border-bottom: 1px dotted;
    opacity: .4;
}

.nk-title-sep-icon::before {
    margin-right: 20px;
}

.nk-title-sep-icon::after {
    margin-left: 20px;
}

/* mega menu titles */
.nk-mega-item > .dropdown > ul li > label {
    font-family: "Marcellus SC", serif;
}

/*------------------------------------------------------------------

  Bootstrap

 -------------------------------------------------------------------*/
.row > [class*="col-"] {
    position: relative;
}

.nk-sidebar-sticky-parent {
    display: flex;
    flex-direction: column;
}

.nk-sidebar-sticky-parent > .nk-sidebar {
    flex: 1;
}

.row.vertical-gap > [class*="col-"] {
    padding-top: 30px;
}

.row.vertical-gap {
    margin-top: -30px;
}

.row.sm-gap {
    margin-right: -7.5px;
    margin-left: -7.5px;
}

.row.sm-gap > [class*="col-"] {
    padding-right: 7.5px;
    padding-left: 7.5px;
}

.row.sm-gap.vertical-gap > [class*="col-"] {
    padding-top: 15px;
}

.row.sm-gap.vertical-gap {
    margin-top: -15px;
}

.row.md-gap {
    margin-right: -22.5px;
    margin-left: -22.5px;
}

.row.md-gap > [class*="col-"] {
    padding-right: 22.5px;
    padding-left: 22.5px;
}

.row.md-gap.vertical-gap > [class*="col-"] {
    padding-top: 45px;
}

.row.md-gap.vertical-gap {
    margin-top: -45px;
}

.row.lg-gap {
    margin-right: -30px;
    margin-left: -30px;
}

.row.lg-gap > [class*="col-"] {
    padding-right: 30px;
    padding-left: 30px;
}

.row.lg-gap.vertical-gap > [class*="col-"] {
    padding-top: 60px;
}

.row.lg-gap.vertical-gap {
    margin-top: -60px;
}

.row.xl-gap {
    margin-right: -35px;
    margin-left: -35px;
}

.row.xl-gap > [class*="col-"] {
    padding-right: 35px;
    padding-left: 35px;
}

.row.xl-gap.vertical-gap > [class*="col-"] {
    padding-top: 70px;
}

.row.xl-gap.vertical-gap {
    margin-top: -70px;
}

.table {
    color: inherit;
}

.thead-default th {
    color: inherit;
    background-color: #222222;
}

.table thead th,
.table td,
.table th {
    border-color: #222222;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.04);
}

.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.05);
}

.nk-modal .modal-content,
.nk-summernote-modal-style ~ .modal .modal-content {
    background-color: #0e0e0e;
    border: none;
    border-radius: 0;
}

.nk-modal .modal-header,
.nk-modal .modal-footer,
.nk-summernote-modal-style ~ .modal .modal-header,
.nk-summernote-modal-style ~ .modal .modal-footer {
    padding: 25px 30px;
    border-top-color: #000;
    border-bottom-color: #000;
}

.nk-modal .modal-header .close,
.nk-modal .modal-footer .close,
.nk-summernote-modal-style ~ .modal .modal-header .close,
.nk-summernote-modal-style ~ .modal .modal-footer .close {
    position: relative;
    margin-top: 0;
    z-index: 2;
}

.nk-modal .modal-body,
.nk-summernote-modal-style ~ .modal .modal-body {
    padding: 30px;
}

.nk-modal.fade .modal-dialog,
.nk-summernote-modal-style ~ .modal.fade .modal-dialog {
    transition: none;
    transform: translate(0, 0);
}

.nk-modal .close,
.nk-modal .close:focus,
.nk-modal .close:hover,
.nk-summernote-modal-style ~ .modal .close,
.nk-summernote-modal-style ~ .modal .close:focus,
.nk-summernote-modal-style ~ .modal .close:hover {
    color: inherit;
    text-shadow: none;
}

@media (min-width: 576px) {
    .nk-modal .modal-dialog,
    .nk-summernote-modal-style ~ .modal .modal-dialog {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}

code {
    padding: 4px 8px;
    color: #f0c491;
    background-color: #000;
}

.img-thumbnail {
    background-color: #000;
    border: none;
}

/*------------------------------------------------------------------

  Helpers

 -------------------------------------------------------------------*/
/*
 * Text Color Helper
 */
.text-main-1 {
    color: #e08821 !important;
}

.text-main-2 {
    color: #39a228 !important;
}

.text-main-3 {
    color: #2953b1 !important;
}

.text-main-4 {
    color: #7c34a8 !important;
}

.text-main-5 {
    color: #c82e2e !important;
}

.text-primary {
    color: #0275d8 !important;
}

.text-success {
    color: #5cb85c !important;
}

.text-info {
    color: #5bc0de !important;
}

.text-warning {
    color: #f0ad4e !important;
}

.text-danger {
    color: #d9534f !important;
}

.text-white {
    color: #fff !important;
}

.text-black {
    color: #000 !important;
}

.text-dark-1 {
    color: #0e0e0e !important;
}

.text-dark-2 {
    color: #181818 !important;
}

.text-dark-3 {
    color: #222222 !important;
}

.text-dark-4 {
    color: #2d2d2d !important;
}

.text-gray-1 {
    color: #fafafa !important;
}

.text-gray-2 {
    color: #f7f7f7 !important;
}

.text-gray-3 {
    color: whitesmoke !important;
}

.text-gray-4 {
    color: #f2f2f2 !important;
}

/*
 * Bg Color Helper
 */
.bg-main-1 {
    background-color: #e08821 !important;
}

.bg-main-2 {
    background-color: #39a228 !important;
}

.bg-main-3 {
    background-color: #2953b1 !important;
}

.bg-main-4 {
    background-color: #7c34a8 !important;
}

.bg-main-5 {
    background-color: #c82e2e !important;
}

.bg-primary {
    background-color: #0275d8 !important;
}

.bg-success {
    background-color: #5cb85c !important;
}

.bg-info {
    background-color: #5bc0de !important;
}

.bg-warning {
    background-color: #f0ad4e !important;
}

.bg-danger {
    background-color: #d9534f !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-black {
    background-color: #000 !important;
}

.bg-dark-1 {
    background-color: #0e0e0e !important;
}

.bg-dark-2 {
    background-color: #181818 !important;
}

.bg-dark-3 {
    background-color: #222222 !important;
}

.bg-dark-4 {
    background-color: #2d2d2d !important;
}

.bg-gray-1 {
    background-color: #fafafa !important;
}

.bg-gray-2 {
    background-color: #f7f7f7 !important;
}

.bg-gray-3 {
    background-color: whitesmoke !important;
}

.bg-gray-4 {
    background-color: #f2f2f2 !important;
}

/*
 * Image Fit
 */
.nk-img-fit,
.nk-img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 575px) {
    .nk-img-fit-xs-down {
        max-width: 100%;
        height: auto;
    }
}

@media (min-width: 576px) {
    .nk-img-fit-sm-up {
        max-width: 100%;
        height: auto;
    }
}

@media (max-width: 767px) {
    .nk-img-fit-sm-down {
        max-width: 100%;
        height: auto;
    }
}

@media (min-width: 768px) {
    .nk-img-fit-md-up {
        max-width: 100%;
        height: auto;
    }
}

@media (max-width: 991px) {
    .nk-img-fit-md-down {
        max-width: 100%;
        height: auto;
    }
}

@media (min-width: 992px) {
    .nk-img-fit-lg-up {
        max-width: 100%;
        height: auto;
    }
}

@media (max-width: 1199px) {
    .nk-img-fit-lg-down {
        max-width: 100%;
        height: auto;
    }
}

@media (min-width: 1200) {
    .nk-img-fit-xl-up {
        max-width: 100%;
        height: auto;
    }
}

/*
 * Image Stretch
 */
.nk-img-stretch {
    width: 100%;
    height: auto;
}

/*
 * Circle / Rounded Images Helper
 */
img.circle {
    border-radius: 50%;
}

img.rounded {
    border-radius: 5px;
}

/*
 * Text Align Helper
 */
.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.align-justify {
    text-align: justify;
}

/*
 * Vertical Align Helper
 */
.va-t {
    vertical-align: top !important;
}

.va-m {
    vertical-align: middle !important;
}

.va-b {
    vertical-align: bottom !important;
}

.va-s {
    vertical-align: super !important;
}

/*
 * Inline Block Helper
 */
.dib {
    display: inline-block !important;
}

/*
 * Block Helper
 */
.db {
    display: block;
}

/*
 * Cursor Pointer Helper
 */
.cursor {
    cursor: pointer !important;
}

/*
 * Text Transform Helper
 */
.text-uppercase {
    text-transform: uppercase !important;
}

/*
 * Font Weight Helper
 */
.fw-100 {
    font-weight: 100 !important;
}

.fw-200 {
    font-weight: 200 !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-900 {
    font-weight: 900 !important;
}

/*
 * Font Size Helper
 */
.fs-4 {
    font-size: 4px !important;
}

.fs-5 {
    font-size: 5px !important;
}

.fs-6 {
    font-size: 6px !important;
}

.fs-7 {
    font-size: 7px !important;
}

.fs-8 {
    font-size: 8px !important;
}

.fs-9 {
    font-size: 9px !important;
}

.fs-10 {
    font-size: 10px !important;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-19 {
    font-size: 19px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-26 {
    font-size: 26px !important;
}

.fs-28 {
    font-size: 28px !important;
}

.fs-30 {
    font-size: 30px !important;
}

.fs-35 {
    font-size: 35px !important;
}

.fs-40 {
    font-size: 40px !important;
}

.fs-45 {
    font-size: 45px !important;
}

.fs-50 {
    font-size: 50px !important;
}

/*
 * Line Height Helper
 */
.lh-0 {
    line-height: 0px !important;
}

.lh-5 {
    line-height: 5px !important;
}

.lh-10 {
    line-height: 10px !important;
}

.lh-15 {
    line-height: 15px !important;
}

.lh-20 {
    line-height: 20px !important;
}

.lh-25 {
    line-height: 25px !important;
}

.lh-30 {
    line-height: 30px !important;
}

.lh-35 {
    line-height: 35px !important;
}

.lh-40 {
    line-height: 40px !important;
}

.lh-45 {
    line-height: 45px !important;
}

.lh-50 {
    line-height: 50px !important;
}

/*
 * Inherit Font Family
 */
.nk-font-inherit {
    font-family: inherit !important;
}

/*
 * Text Ellipsis Helper
 */
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/*
 * auto height and width
 */
.hauto {
    height: auto !important;
}

.wauto {
    width: auto !important;
}

/*
 * Overflow Helper
 */
.of-a {
    overflow: auto !important;
}

.of-h {
    overflow: hidden !important;
}

.of-v {
    overflow: visible !important;
}

.of-x-a {
    overflow-x: auto !important;
}

.of-x-h {
    overflow-x: hidden !important;
}

.of-x-v {
    overflow-x: visible !important;
}

.of-y-a {
    overflow-y: auto !important;
}

.of-y-h {
    overflow-y: hidden !important;
}

.of-y-v {
    overflow-y: visible !important;
}

/*
 * Border Radius Helper
 */
.br-0 {
    border-radius: 0px !important;
}

.br-1 {
    border-radius: 1px !important;
}

.br-2 {
    border-radius: 2px !important;
}

.br-3 {
    border-radius: 3px !important;
}

.br-4 {
    border-radius: 4px !important;
}

.br-5 {
    border-radius: 5px !important;
}

.br-6 {
    border-radius: 6px !important;
}

.br-7 {
    border-radius: 7px !important;
}

.br-8 {
    border-radius: 8px !important;
}

.br-9 {
    border-radius: 9px !important;
}

.br-10 {
    border-radius: 10px !important;
}

.br-12 {
    border-radius: 12px !important;
}

.br-14 {
    border-radius: 14px !important;
}

.br-16 {
    border-radius: 16px !important;
}

.br-18 {
    border-radius: 18px !important;
}

.br-20 {
    border-radius: 20px !important;
}

.br-25 {
    border-radius: 25px !important;
}

.br-30 {
    border-radius: 30px !important;
}

.br-35 {
    border-radius: 35px !important;
}

/*
 * Paddings Helper
 */
.p-0 {
    padding: 0px !important;
}

.p-1 {
    padding: 1px !important;
}

.p-2 {
    padding: 2px !important;
}

.p-3 {
    padding: 3px !important;
}

.p-4 {
    padding: 4px !important;
}

.p-5 {
    padding: 5px !important;
}

.p-6 {
    padding: 6px !important;
}

.p-7 {
    padding: 7px !important;
}

.p-8 {
    padding: 8px !important;
}

.p-9 {
    padding: 9px !important;
}

.p-10 {
    padding: 10px !important;
}

.p-15 {
    padding: 15px !important;
}

.p-20 {
    padding: 20px !important;
}

.p-25 {
    padding: 25px !important;
}

.p-30 {
    padding: 30px !important;
}

.p-35 {
    padding: 35px !important;
}

.p-40 {
    padding: 40px !important;
}

.p-45 {
    padding: 45px !important;
}

.p-50 {
    padding: 50px !important;
}

.p-60 {
    padding: 60px !important;
}

.p-70 {
    padding: 70px !important;
}

.p-80 {
    padding: 80px !important;
}

.p-90 {
    padding: 90px !important;
}

.p-100 {
    padding: 100px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pt-1 {
    padding-top: 1px !important;
}

.pt-2 {
    padding-top: 2px !important;
}

.pt-3 {
    padding-top: 3px !important;
}

.pt-4 {
    padding-top: 4px !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pt-6 {
    padding-top: 6px !important;
}

.pt-7 {
    padding-top: 7px !important;
}

.pt-8 {
    padding-top: 8px !important;
}

.pt-9 {
    padding-top: 9px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-25 {
    padding-top: 25px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.pt-35 {
    padding-top: 35px !important;
}

.pt-40 {
    padding-top: 40px !important;
}

.pt-45 {
    padding-top: 45px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

.pt-60 {
    padding-top: 60px !important;
}

.pt-70 {
    padding-top: 70px !important;
}

.pt-80 {
    padding-top: 80px !important;
}

.pt-90 {
    padding-top: 90px !important;
}

.pt-100 {
    padding-top: 100px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.pl-1 {
    padding-left: 1px !important;
}

.pl-2 {
    padding-left: 2px !important;
}

.pl-3 {
    padding-left: 3px !important;
}

.pl-4 {
    padding-left: 4px !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.pl-6 {
    padding-left: 6px !important;
}

.pl-7 {
    padding-left: 7px !important;
}

.pl-8 {
    padding-left: 8px !important;
}

.pl-9 {
    padding-left: 9px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.pl-20 {
    padding-left: 20px !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.pl-30 {
    padding-left: 30px !important;
}

.pl-35 {
    padding-left: 35px !important;
}

.pl-40 {
    padding-left: 40px !important;
}

.pl-45 {
    padding-left: 45px !important;
}

.pl-50 {
    padding-left: 50px !important;
}

.pl-60 {
    padding-left: 60px !important;
}

.pl-70 {
    padding-left: 70px !important;
}

.pl-80 {
    padding-left: 80px !important;
}

.pl-90 {
    padding-left: 90px !important;
}

.pl-100 {
    padding-left: 100px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.pr-1 {
    padding-right: 1px !important;
}

.pr-2 {
    padding-right: 2px !important;
}

.pr-3 {
    padding-right: 3px !important;
}

.pr-4 {
    padding-right: 4px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pr-6 {
    padding-right: 6px !important;
}

.pr-7 {
    padding-right: 7px !important;
}

.pr-8 {
    padding-right: 8px !important;
}

.pr-9 {
    padding-right: 9px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pr-20 {
    padding-right: 20px !important;
}

.pr-25 {
    padding-right: 25px !important;
}

.pr-30 {
    padding-right: 30px !important;
}

.pr-35 {
    padding-right: 35px !important;
}

.pr-40 {
    padding-right: 40px !important;
}

.pr-45 {
    padding-right: 45px !important;
}

.pr-50 {
    padding-right: 50px !important;
}

.pr-60 {
    padding-right: 60px !important;
}

.pr-70 {
    padding-right: 70px !important;
}

.pr-80 {
    padding-right: 80px !important;
}

.pr-90 {
    padding-right: 90px !important;
}

.pr-100 {
    padding-right: 100px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pb-1 {
    padding-bottom: 1px !important;
}

.pb-2 {
    padding-bottom: 2px !important;
}

.pb-3 {
    padding-bottom: 3px !important;
}

.pb-4 {
    padding-bottom: 4px !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}

.pb-6 {
    padding-bottom: 6px !important;
}

.pb-7 {
    padding-bottom: 7px !important;
}

.pb-8 {
    padding-bottom: 8px !important;
}

.pb-9 {
    padding-bottom: 9px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-25 {
    padding-bottom: 25px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-35 {
    padding-bottom: 35px !important;
}

.pb-40 {
    padding-bottom: 40px !important;
}

.pb-45 {
    padding-bottom: 45px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.pb-60 {
    padding-bottom: 60px !important;
}

.pb-70 {
    padding-bottom: 70px !important;
}

.pb-80 {
    padding-bottom: 80px !important;
}

.pb-90 {
    padding-bottom: 90px !important;
}

.pb-100 {
    padding-bottom: 100px !important;
}

/*
 * Margins Helper
 */
.mauto {
    margin-right: auto;
    margin-left: auto;
}

.m-0 {
    margin: 0px !important;
}

.m-1 {
    margin: 1px !important;
}

.m-2 {
    margin: 2px !important;
}

.m-3 {
    margin: 3px !important;
}

.m-4 {
    margin: 4px !important;
}

.m-5 {
    margin: 5px !important;
}

.m-6 {
    margin: 6px !important;
}

.m-7 {
    margin: 7px !important;
}

.m-8 {
    margin: 8px !important;
}

.m-9 {
    margin: 9px !important;
}

.m-10 {
    margin: 10px !important;
}

.m-15 {
    margin: 15px !important;
}

.m-20 {
    margin: 20px !important;
}

.m-25 {
    margin: 25px !important;
}

.m-30 {
    margin: 30px !important;
}

.m-35 {
    margin: 35px !important;
}

.m-40 {
    margin: 40px !important;
}

.m-45 {
    margin: 45px !important;
}

.m-50 {
    margin: 50px !important;
}

.m-60 {
    margin: 60px !important;
}

.m-70 {
    margin: 70px !important;
}

.m-80 {
    margin: 80px !important;
}

.m-90 {
    margin: 90px !important;
}

.m-100 {
    margin: 100px !important;
}

.m-110 {
    margin: 110px !important;
}

.m-120 {
    margin: 120px !important;
}

.m-130 {
    margin: 130px !important;
}

.m-140 {
    margin: 140px !important;
}

.m-150 {
    margin: 150px !important;
}

.m-160 {
    margin: 160px !important;
}

.m-170 {
    margin: 170px !important;
}

.m-180 {
    margin: 180px !important;
}

.m-190 {
    margin: 190px !important;
}

.m-200 {
    margin: 200px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mt-1 {
    margin-top: 1px !important;
}

.mt-2 {
    margin-top: 2px !important;
}

.mt-3 {
    margin-top: 3px !important;
}

.mt-4 {
    margin-top: 4px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-6 {
    margin-top: 6px !important;
}

.mt-7 {
    margin-top: 7px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-9 {
    margin-top: 9px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-35 {
    margin-top: 35px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mt-45 {
    margin-top: 45px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-60 {
    margin-top: 60px !important;
}

.mt-70 {
    margin-top: 70px !important;
}

.mt-80 {
    margin-top: 80px !important;
}

.mt-90 {
    margin-top: 90px !important;
}

.mt-100 {
    margin-top: 100px !important;
}

.mt-110 {
    margin-top: 110px !important;
}

.mt-120 {
    margin-top: 120px !important;
}

.mt-130 {
    margin-top: 130px !important;
}

.mt-140 {
    margin-top: 140px !important;
}

.mt-150 {
    margin-top: 150px !important;
}

.mt-160 {
    margin-top: 160px !important;
}

.mt-170 {
    margin-top: 170px !important;
}

.mt-180 {
    margin-top: 180px !important;
}

.mt-190 {
    margin-top: 190px !important;
}

.mt-200 {
    margin-top: 200px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.ml-1 {
    margin-left: 1px !important;
}

.ml-2 {
    margin-left: 2px !important;
}

.ml-3 {
    margin-left: 3px !important;
}

.ml-4 {
    margin-left: 4px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.ml-6 {
    margin-left: 6px !important;
}

.ml-7 {
    margin-left: 7px !important;
}

.ml-8 {
    margin-left: 8px !important;
}

.ml-9 {
    margin-left: 9px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml-25 {
    margin-left: 25px !important;
}

.ml-30 {
    margin-left: 30px !important;
}

.ml-35 {
    margin-left: 35px !important;
}

.ml-40 {
    margin-left: 40px !important;
}

.ml-45 {
    margin-left: 45px !important;
}

.ml-50 {
    margin-left: 50px !important;
}

.ml-60 {
    margin-left: 60px !important;
}

.ml-70 {
    margin-left: 70px !important;
}

.ml-80 {
    margin-left: 80px !important;
}

.ml-90 {
    margin-left: 90px !important;
}

.ml-100 {
    margin-left: 100px !important;
}

.ml-110 {
    margin-left: 110px !important;
}

.ml-120 {
    margin-left: 120px !important;
}

.ml-130 {
    margin-left: 130px !important;
}

.ml-140 {
    margin-left: 140px !important;
}

.ml-150 {
    margin-left: 150px !important;
}

.ml-160 {
    margin-left: 160px !important;
}

.ml-170 {
    margin-left: 170px !important;
}

.ml-180 {
    margin-left: 180px !important;
}

.ml-190 {
    margin-left: 190px !important;
}

.ml-200 {
    margin-left: 200px !important;
}

.mr-0 {
    margin-right: 0px !important;
}

.mr-1 {
    margin-right: 1px !important;
}

.mr-2 {
    margin-right: 2px !important;
}

.mr-3 {
    margin-right: 3px !important;
}

.mr-4 {
    margin-right: 4px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-6 {
    margin-right: 6px !important;
}

.mr-7 {
    margin-right: 7px !important;
}

.mr-8 {
    margin-right: 8px !important;
}

.mr-9 {
    margin-right: 9px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-25 {
    margin-right: 25px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.mr-35 {
    margin-right: 35px !important;
}

.mr-40 {
    margin-right: 40px !important;
}

.mr-45 {
    margin-right: 45px !important;
}

.mr-50 {
    margin-right: 50px !important;
}

.mr-60 {
    margin-right: 60px !important;
}

.mr-70 {
    margin-right: 70px !important;
}

.mr-80 {
    margin-right: 80px !important;
}

.mr-90 {
    margin-right: 90px !important;
}

.mr-100 {
    margin-right: 100px !important;
}

.mr-110 {
    margin-right: 110px !important;
}

.mr-120 {
    margin-right: 120px !important;
}

.mr-130 {
    margin-right: 130px !important;
}

.mr-140 {
    margin-right: 140px !important;
}

.mr-150 {
    margin-right: 150px !important;
}

.mr-160 {
    margin-right: 160px !important;
}

.mr-170 {
    margin-right: 170px !important;
}

.mr-180 {
    margin-right: 180px !important;
}

.mr-190 {
    margin-right: 190px !important;
}

.mr-200 {
    margin-right: 200px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mb-1 {
    margin-bottom: 1px !important;
}

.mb-2 {
    margin-bottom: 2px !important;
}

.mb-3 {
    margin-bottom: 3px !important;
}

.mb-4 {
    margin-bottom: 4px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-6 {
    margin-bottom: 6px !important;
}

.mb-7 {
    margin-bottom: 7px !important;
}

.mb-8 {
    margin-bottom: 8px !important;
}

.mb-9 {
    margin-bottom: 9px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-35 {
    margin-bottom: 35px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-45 {
    margin-bottom: 45px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}

.mb-80 {
    margin-bottom: 80px !important;
}

.mb-90 {
    margin-bottom: 90px !important;
}

.mb-100 {
    margin-bottom: 100px !important;
}

.mb-110 {
    margin-bottom: 110px !important;
}

.mb-120 {
    margin-bottom: 120px !important;
}

.mb-130 {
    margin-bottom: 130px !important;
}

.mb-140 {
    margin-bottom: 140px !important;
}

.mb-150 {
    margin-bottom: 150px !important;
}

.mb-160 {
    margin-bottom: 160px !important;
}

.mb-170 {
    margin-bottom: 170px !important;
}

.mb-180 {
    margin-bottom: 180px !important;
}

.mb-190 {
    margin-bottom: 190px !important;
}

.mb-200 {
    margin-bottom: 200px !important;
}

/*
 * Negative Margins Helper
 */
.mn-0 {
    margin: 0px !important;
}

.mn-1 {
    margin: -1px !important;
}

.mn-2 {
    margin: -2px !important;
}

.mn-3 {
    margin: -3px !important;
}

.mn-4 {
    margin: -4px !important;
}

.mn-5 {
    margin: -5px !important;
}

.mn-6 {
    margin: -6px !important;
}

.mn-7 {
    margin: -7px !important;
}

.mn-8 {
    margin: -8px !important;
}

.mn-9 {
    margin: -9px !important;
}

.mn-10 {
    margin: -10px !important;
}

.mn-15 {
    margin: -15px !important;
}

.mn-20 {
    margin: -20px !important;
}

.mn-25 {
    margin: -25px !important;
}

.mn-30 {
    margin: -30px !important;
}

.mn-35 {
    margin: -35px !important;
}

.mn-40 {
    margin: -40px !important;
}

.mn-45 {
    margin: -45px !important;
}

.mn-50 {
    margin: -50px !important;
}

.mn-60 {
    margin: -60px !important;
}

.mn-70 {
    margin: -70px !important;
}

.mn-80 {
    margin: -80px !important;
}

.mn-90 {
    margin: -90px !important;
}

.mn-100 {
    margin: -100px !important;
}

.mn-110 {
    margin: -110px !important;
}

.mn-120 {
    margin: -120px !important;
}

.mn-130 {
    margin: -130px !important;
}

.mn-140 {
    margin: -140px !important;
}

.mn-150 {
    margin: -150px !important;
}

.mn-160 {
    margin: -160px !important;
}

.mn-170 {
    margin: -170px !important;
}

.mn-180 {
    margin: -180px !important;
}

.mn-190 {
    margin: -190px !important;
}

.mn-200 {
    margin: -200px !important;
}

.mnt-0 {
    margin-top: 0px !important;
}

.mnt-1 {
    margin-top: -1px !important;
}

.mnt-2 {
    margin-top: -2px !important;
}

.mnt-3 {
    margin-top: -3px !important;
}

.mnt-4 {
    margin-top: -4px !important;
}

.mnt-5 {
    margin-top: -5px !important;
}

.mnt-6 {
    margin-top: -6px !important;
}

.mnt-7 {
    margin-top: -7px !important;
}

.mnt-8 {
    margin-top: -8px !important;
}

.mnt-9 {
    margin-top: -9px !important;
}

.mnt-10 {
    margin-top: -10px !important;
}

.mnt-15 {
    margin-top: -15px !important;
}

.mnt-20 {
    margin-top: -20px !important;
}

.mnt-25 {
    margin-top: -25px !important;
}

.mnt-30 {
    margin-top: -30px !important;
}

.mnt-35 {
    margin-top: -35px !important;
}

.mnt-40 {
    margin-top: -40px !important;
}

.mnt-45 {
    margin-top: -45px !important;
}

.mnt-50 {
    margin-top: -50px !important;
}

.mnt-60 {
    margin-top: -60px !important;
}

.mnt-70 {
    margin-top: -70px !important;
}

.mnt-80 {
    margin-top: -80px !important;
}

.mnt-90 {
    margin-top: -90px !important;
}

.mnt-100 {
    margin-top: -100px !important;
}

.mnt-110 {
    margin-top: -110px !important;
}

.mnt-120 {
    margin-top: -120px !important;
}

.mnt-130 {
    margin-top: -130px !important;
}

.mnt-140 {
    margin-top: -140px !important;
}

.mnt-150 {
    margin-top: -150px !important;
}

.mnt-160 {
    margin-top: -160px !important;
}

.mnt-170 {
    margin-top: -170px !important;
}

.mnt-180 {
    margin-top: -180px !important;
}

.mnt-190 {
    margin-top: -190px !important;
}

.mnt-200 {
    margin-top: -200px !important;
}

.mnl-0 {
    margin-left: 0px !important;
}

.mnl-1 {
    margin-left: -1px !important;
}

.mnl-2 {
    margin-left: -2px !important;
}

.mnl-3 {
    margin-left: -3px !important;
}

.mnl-4 {
    margin-left: -4px !important;
}

.mnl-5 {
    margin-left: -5px !important;
}

.mnl-6 {
    margin-left: -6px !important;
}

.mnl-7 {
    margin-left: -7px !important;
}

.mnl-8 {
    margin-left: -8px !important;
}

.mnl-9 {
    margin-left: -9px !important;
}

.mnl-10 {
    margin-left: -10px !important;
}

.mnl-15 {
    margin-left: -15px !important;
}

.mnl-20 {
    margin-left: -20px !important;
}

.mnl-25 {
    margin-left: -25px !important;
}

.mnl-30 {
    margin-left: -30px !important;
}

.mnl-35 {
    margin-left: -35px !important;
}

.mnl-40 {
    margin-left: -40px !important;
}

.mnl-45 {
    margin-left: -45px !important;
}

.mnl-50 {
    margin-left: -50px !important;
}

.mnl-60 {
    margin-left: -60px !important;
}

.mnl-70 {
    margin-left: -70px !important;
}

.mnl-80 {
    margin-left: -80px !important;
}

.mnl-90 {
    margin-left: -90px !important;
}

.mnl-100 {
    margin-left: -100px !important;
}

.mnl-110 {
    margin-left: -110px !important;
}

.mnl-120 {
    margin-left: -120px !important;
}

.mnl-130 {
    margin-left: -130px !important;
}

.mnl-140 {
    margin-left: -140px !important;
}

.mnl-150 {
    margin-left: -150px !important;
}

.mnl-160 {
    margin-left: -160px !important;
}

.mnl-170 {
    margin-left: -170px !important;
}

.mnl-180 {
    margin-left: -180px !important;
}

.mnl-190 {
    margin-left: -190px !important;
}

.mnl-200 {
    margin-left: -200px !important;
}

.mnr-0 {
    margin-right: 0px !important;
}

.mnr-1 {
    margin-right: -1px !important;
}

.mnr-2 {
    margin-right: -2px !important;
}

.mnr-3 {
    margin-right: -3px !important;
}

.mnr-4 {
    margin-right: -4px !important;
}

.mnr-5 {
    margin-right: -5px !important;
}

.mnr-6 {
    margin-right: -6px !important;
}

.mnr-7 {
    margin-right: -7px !important;
}

.mnr-8 {
    margin-right: -8px !important;
}

.mnr-9 {
    margin-right: -9px !important;
}

.mnr-10 {
    margin-right: -10px !important;
}

.mnr-15 {
    margin-right: -15px !important;
}

.mnr-20 {
    margin-right: -20px !important;
}

.mnr-25 {
    margin-right: -25px !important;
}

.mnr-30 {
    margin-right: -30px !important;
}

.mnr-35 {
    margin-right: -35px !important;
}

.mnr-40 {
    margin-right: -40px !important;
}

.mnr-45 {
    margin-right: -45px !important;
}

.mnr-50 {
    margin-right: -50px !important;
}

.mnr-60 {
    margin-right: -60px !important;
}

.mnr-70 {
    margin-right: -70px !important;
}

.mnr-80 {
    margin-right: -80px !important;
}

.mnr-90 {
    margin-right: -90px !important;
}

.mnr-100 {
    margin-right: -100px !important;
}

.mnr-110 {
    margin-right: -110px !important;
}

.mnr-120 {
    margin-right: -120px !important;
}

.mnr-130 {
    margin-right: -130px !important;
}

.mnr-140 {
    margin-right: -140px !important;
}

.mnr-150 {
    margin-right: -150px !important;
}

.mnr-160 {
    margin-right: -160px !important;
}

.mnr-170 {
    margin-right: -170px !important;
}

.mnr-180 {
    margin-right: -180px !important;
}

.mnr-190 {
    margin-right: -190px !important;
}

.mnr-200 {
    margin-right: -200px !important;
}

.mnb-0 {
    margin-bottom: 0px !important;
}

.mnb-1 {
    margin-bottom: -1px !important;
}

.mnb-2 {
    margin-bottom: -2px !important;
}

.mnb-3 {
    margin-bottom: -3px !important;
}

.mnb-4 {
    margin-bottom: -4px !important;
}

.mnb-5 {
    margin-bottom: -5px !important;
}

.mnb-6 {
    margin-bottom: -6px !important;
}

.mnb-7 {
    margin-bottom: -7px !important;
}

.mnb-8 {
    margin-bottom: -8px !important;
}

.mnb-9 {
    margin-bottom: -9px !important;
}

.mnb-10 {
    margin-bottom: -10px !important;
}

.mnb-15 {
    margin-bottom: -15px !important;
}

.mnb-20 {
    margin-bottom: -20px !important;
}

.mnb-25 {
    margin-bottom: -25px !important;
}

.mnb-30 {
    margin-bottom: -30px !important;
}

.mnb-35 {
    margin-bottom: -35px !important;
}

.mnb-40 {
    margin-bottom: -40px !important;
}

.mnb-45 {
    margin-bottom: -45px !important;
}

.mnb-50 {
    margin-bottom: -50px !important;
}

.mnb-60 {
    margin-bottom: -60px !important;
}

.mnb-70 {
    margin-bottom: -70px !important;
}

.mnb-80 {
    margin-bottom: -80px !important;
}

.mnb-90 {
    margin-bottom: -90px !important;
}

.mnb-100 {
    margin-bottom: -100px !important;
}

.mnb-110 {
    margin-bottom: -110px !important;
}

.mnb-120 {
    margin-bottom: -120px !important;
}

.mnb-130 {
    margin-bottom: -130px !important;
}

.mnb-140 {
    margin-bottom: -140px !important;
}

.mnb-150 {
    margin-bottom: -150px !important;
}

.mnb-160 {
    margin-bottom: -160px !important;
}

.mnb-170 {
    margin-bottom: -170px !important;
}

.mnb-180 {
    margin-bottom: -180px !important;
}

.mnb-190 {
    margin-bottom: -190px !important;
}

.mnb-200 {
    margin-bottom: -200px !important;
}

/*
 * Opacity
 */
.op-0 {
    opacity: 0 !important;
}

.op-1 {
    opacity: 0.1 !important;
}

.op-2 {
    opacity: 0.2 !important;
}

.op-3 {
    opacity: 0.3 !important;
}

.op-4 {
    opacity: 0.4 !important;
}

.op-5 {
    opacity: 0.5 !important;
}

.op-6 {
    opacity: 0.6 !important;
}

.op-7 {
    opacity: 0.7 !important;
}

.op-8 {
    opacity: 0.8 !important;
}

.op-9 {
    opacity: 0.9 !important;
}

.op-10 {
    opacity: 1 !important;
}

/*
 * Responsive embeds
 */
.responsive-embed {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
    clear: both;
}

.responsive-embed.responsive-embed-16x9 {
    padding-bottom: 56.25%;
}

.responsive-embed.responsive-embed-4x3 {
    padding-bottom: 75%;
}

.responsive-embed > .embed-item,
.responsive-embed > iframe,
.responsive-embed > embed,
.responsive-embed > object {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

/*
 * Keyboard Key
 */
.nk-keyboard {
    display: inline-block;
    min-width: 30px;
    height: 30px;
    padding: 0 7px;
    margin: 5px;
    font-size: 12px;
    line-height: 28px;
    color: #0e0e0e;
    text-align: center;
    background-color: #fff;
    border: 1px solid #8e8e8e;
    border-radius: 3px;
}

/*------------------------------------------------------------------

 Cookie Alert

 -------------------------------------------------------------------*/
.nk-cookie-alert {
    position: fixed;
    bottom: 0;
    left: 0;
    max-width: 450px;
    padding: 35px;
    margin: 30px;
    background-color: #0e0e0e;
    z-index: 2000;
}

.nk-cookie-alert .nk-cookie-alert-close {
    float: right;
    padding-bottom: 10px;
    padding-left: 10px;
    cursor: pointer;
}

/*------------------------------------------------------------------

  Page Border

 -------------------------------------------------------------------*/
.nk-page-border .nk-page-border-t,
.nk-page-border .nk-page-border-r,
.nk-page-border .nk-page-border-b,
.nk-page-border .nk-page-border-l {
    position: fixed;
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-size: 100% 100%;
    z-index: 2000;
}

.nk-page-border .nk-page-border-t,
.nk-page-border .nk-page-border-b {
    left: 0;
    width: 100%;
    height: 100px;
}

.nk-page-border .nk-page-border-t {
    top: 0;
    background-image: url("../godlike-images/border-top.png");
}

.nk-page-border .nk-page-border-b {
    bottom: 0;
    background-image: url("../godlike-images/border-bottom.png");
}

.nk-page-border .nk-page-border-r,
.nk-page-border .nk-page-border-l {
    top: 0;
    width: 100px;
    height: 100%;
}

.nk-page-border .nk-page-border-r {
    right: 0;
    background-image: url("../godlike-images/border-right.png");
}

.nk-page-border .nk-page-border-l {
    left: 0;
    background-image: url("../godlike-images/border-left.png");
}

@media (max-width: 575px) {
    .nk-page-border .nk-page-border-t,
    .nk-page-border .nk-page-border-b {
        height: 50px;
    }
    .nk-page-border .nk-page-border-r,
    .nk-page-border .nk-page-border-l {
        width: 50px;
    }
}

/*------------------------------------------------------------------

  Navbar

 -------------------------------------------------------------------*/
/* Header */
.nk-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
}

.nk-header.nk-header-opaque {
    position: relative;
}

/* Logo Top */
.nk-logo-top {
    position: relative;
    padding: 70px 10px;
    text-align: center;
}

.nk-logo-top.nk-logo-top-light, .nk-logo-top.nk-logo-top-light.nk-logo-top-solid {
    color: #0e0e0e;
    background-color: rgba(255, 255, 255, 0.85);
}

.nk-header-opaque .nk-logo-top.nk-logo-top-light {
    background-color: #fff;
}

.nk-logo-top.nk-logo-top-transparent {
    background-color: transparent;
}

.nk-logo-top, .nk-logo-top.nk-logo-top-solid {
    background-color: rgba(14, 14, 14, 0.75);
}

.nk-header-opaque .nk-logo-top {
    background-color: #0e0e0e;
}

/* Contacts before Top Navbar */
.nk-contacts-top {
    position: relative;
    padding: 15px 0;
    font-size: .8rem;
    z-index: 1001;
}

.nk-contacts-top::after {
    content: "";
    display: table;
    clear: both;
}

.nk-contacts-top.nk-contacts-top-light, .nk-contacts-top.nk-contacts-top-light.nk-contacts-top-solid {
    color: #0e0e0e;
    background-color: rgba(250, 250, 250, 0.85);
}

.nk-header-opaque .nk-contacts-top.nk-contacts-top-light {
    background-color: #fafafa;
}

.nk-contacts-top.nk-contacts-top-transparent {
    background-color: transparent;
}

.nk-contacts-top, .nk-contacts-top.nk-contacts-top-solid {
    background-color: rgba(6, 6, 6, 0.75);
}

.nk-header-opaque .nk-contacts-top {
    background-color: #060606;
}

.nk-contacts-top .nk-contacts-left {
    float: left;
}

.nk-contacts-top .nk-contacts-right {
    float: right;
}

@media (max-width: 575px) {
    .nk-contacts-top .nk-contacts-left,
    .nk-contacts-top .nk-contacts-right {
        float: none;
        text-align: center;
    }
    .nk-contacts-top .nk-contacts-right {
        margin-top: 10px;
    }
}

.nk-contacts-top .nk-navbar {
    padding: 0;
    z-index: auto;
}

.nk-contacts-top .nk-navbar a {
    font-size: inherit;
}

.nk-contacts-top .nk-navbar,
.nk-header-opaque .nk-contacts-top .nk-navbar {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.nk-contacts-top .nk-navbar .nk-nav {
    padding: 0;
}

.nk-contacts-top .nk-navbar .nk-nav > li > a {
    padding: 3px 14px;
    border-radius: 3px;
    transition: .3s background-color;
}

.nk-contacts-top .nk-navbar .nk-nav > li + li {
    margin-left: 5px;
}

.nk-contacts-top .nk-navbar .nk-nav .dropdown::before {
    top: -17px;
}

/* Navbar */
.nk-navbar {
    position: relative;
    padding: 26px 0;
    transition: .3s background-color;
    z-index: 1000;
    will-change: background-color;
}

.nk-navbar.nk-navbar-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    transition: .2s transform, .2s visibility, .3s background-color;
    will-change: transform, visibility, background-color;
}

.nk-navbar.nk-navbar-fixed.nk-onscroll-hide {
    visibility: hidden;
    transform: translateY(-100%);
}

.nk-navbar.nk-navbar-fixed.nk-onscroll-show {
    visibility: visible;
    transform: translateY(0);
}

.nk-navbar.nk-navbar-light, .nk-navbar.nk-navbar-light.nk-navbar-solid {
    color: #0e0e0e;
    background-color: rgba(255, 255, 255, 0.85);
}

.nk-header-opaque .nk-navbar.nk-navbar-light {
    background-color: #fff;
}

.nk-navbar.nk-navbar-transparent {
    background-color: transparent;
}

.nk-navbar, .nk-navbar.nk-navbar-solid {
    background-color: rgba(14, 14, 14, 0.75);
}

.nk-header-opaque .nk-navbar {
    background-color: #0e0e0e;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
}

.nk-navbar.nk-navbar-align-center {
    text-align: center;
}

.nk-navbar.nk-navbar-align-right {
    text-align: right;
}

.nk-navbar .nk-nav-table {
    display: table;
    width: 100%;
    height: 100%;
}

.nk-navbar .nk-nav-table > * {
    display: table-cell;
    vertical-align: middle;
}

.nk-navbar .nk-nav-table > .nk-nav-row {
    display: table-row;
}

.nk-navbar .nk-nav-table > .nk-nav-row-full {
    height: 100%;
}

.nk-navbar .nk-nav-table > .nk-nav-row-center > * {
    display: table-cell;
    vertical-align: middle;
}

.nk-navbar .nk-nav-table > .nk-nav-icons,
.nk-navbar .nk-nav-table > .nk-nav-logo {
    width: 1%;
}

.nk-navbar .nk-nav-logo img {
    height: auto;
}

.nk-navbar .nk-nav-icons {
    white-space: nowrap;
}

.nk-navbar .nk-nav-icons > * {
    white-space: initial;
}

@media (max-width: 420px) {
    .nk-navbar.nk-navbar-top .nk-nav-logo {
        display: none;
    }
}

.nk-navbar a {
    overflow: hidden;
    font-family: "Marcellus SC", serif;
    font-weight: 600;
    color: inherit;
}

.nk-navbar ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.nk-navbar ul > li > a {
    white-space: nowrap;
}

.nk-navbar ul > li > a:hover, .nk-navbar ul > li > a:focus {
    text-decoration: none;
}

.nk-navbar .nk-navbar-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.nk-navbar.nk-navbar-no-link-effect li > a {
    transition: .2s opacity;
}

.nk-navbar.nk-navbar-no-link-effect li > a:hover {
    opacity: .7;
}

.nk-navbar .nk-nav {
    position: relative;
}

.nk-navbar .nk-nav .nk-nav {
    padding-right: 0;
    padding-left: 0;
}

.nk-navbar .nk-nav li.single-icon > a {
    padding: 0 12px;
}

.nk-navbar .nk-nav li.single-icon > a::before {
    content: none;
}

.nk-navbar .nk-nav li.single-icon > a > i,
.nk-navbar .nk-nav li.single-icon > a > span {
    height: 32px;
    font-size: 1rem;
    line-height: 32px;
    vertical-align: middle;
}

.nk-navbar .nk-nav li > a .nk-item-descr {
    display: block;
    font-family: "Roboto Condensed", sans-serif;
    font-size: .7em;
    opacity: .7;
}

.nk-navbar .nk-nav > li {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.nk-navbar .nk-nav > li > a {
    position: relative;
    display: block;
    padding: 5px 25px;
}

@media (max-width: 1199px) {
    .nk-navbar .nk-nav > li > a {
        padding: 5px 23px;
    }
}

.nk-navbar .nk-nav li.active > a {
    color: #e08821;
}

.nk-navbar .nk-nav .dropdown {
    position: absolute;
    display: none;
    padding: 30px 0;
    margin-top: 28px;
    background-color: #0e0e0e;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
    opacity: 0;
    z-index: 1;
}

.nk-navbar .nk-nav .dropdown::before {
    content: "";
    position: absolute;
    display: block;
    top: -28px;
    left: 0;
    width: 100%;
    height: 90px;
    z-index: -1;
}

.nk-navbar .nk-nav .dropdown > li {
    position: relative;
}

.nk-navbar .nk-nav .dropdown > li > a {
    display: block;
    padding: 8px 40px;
    padding-right: 50px;
}

.nk-navbar .nk-nav .dropdown > li > a:hover {
    text-decoration: none;
}

.nk-navbar .nk-nav .nk-drop-item > a::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    left: 50%;
    width: 3px;
    height: 3px;
    margin-left: -1px;
    border-radius: 1.5px;
    box-shadow: 5px 0 0 0, -5px 0 0 0, inset 0 0 0 3px;
    opacity: .4;
    transition: .2s opacity;
}

.nk-navbar .nk-nav .nk-drop-item > a:hover::after,
.nk-navbar .nk-nav .nk-drop-item > a.hover::after,
.nk-navbar .nk-nav .nk-drop-item.open > a::after {
    opacity: .8;
}

.nk-navbar .nk-nav .nk-drop-item .nk-drop-item > a::after {
    right: 30px;
    bottom: 50%;
    left: auto;
    margin-bottom: -2px;
}

.nk-navbar .nk-nav .nk-drop-item .nk-drop-item .dropdown {
    top: 0;
    margin-top: -30px;
    margin-left: 100%;
    margin-left: calc(100% + 2px);
}

.nk-navbar .nk-nav .nk-mega-item {
    position: static;
}

.nk-navbar .nk-nav .nk-mega-item > .dropdown {
    left: 0;
    overflow: hidden;
}

.nk-navbar .nk-nav .nk-mega-item > .dropdown > ul {
    display: table;
}

.nk-navbar .nk-nav .nk-mega-item > .dropdown > ul > li {
    display: table-cell;
    min-width: 200px;
}

.nk-navbar .nk-nav .nk-mega-item > .dropdown > ul,
.nk-navbar .nk-nav .nk-mega-item > .dropdown > ul ul,
.nk-navbar .nk-nav .nk-mega-item > .dropdown > ul li {
    padding: 0;
    list-style-type: none;
}

.nk-navbar .nk-nav .nk-mega-item > .dropdown > ul li {
    flex-basis: 0;
    flex-grow: 1;
    padding-right: 10px;
    padding-left: 10px;
}

.nk-navbar .nk-nav .nk-mega-item > .dropdown > ul li > label {
    position: relative;
    display: block;
    padding-right: 50px;
    margin: 12px 20px;
    margin-bottom: 30px;
    font-size: 1.1rem;
    line-height: 1;
    white-space: nowrap;
}

.nk-navbar .nk-nav .nk-mega-item > .dropdown > ul li > label::before {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 100%;
    height: 0;
    border-bottom: 1px solid;
    opacity: .3;
}

.nk-navbar .nk-nav .nk-mega-item > .dropdown > ul li li {
    padding: 0;
}

.nk-navbar .nk-nav .nk-mega-item > .dropdown > ul li li a {
    display: block;
    padding: 8px 20px;
    padding-right: 50px;
}

@media (max-width: 767px) {
    .nk-navbar .nk-nav .nk-mega-item > .dropdown {
        right: 0;
        left: 0;
    }
    .nk-navbar .nk-nav .nk-mega-item > .dropdown > ul {
        display: block;
        flex-flow: row nowrap;
        justify-content: flex-start;
        margin: 0;
    }
    .nk-navbar .nk-nav .nk-mega-item > .dropdown > ul li {
        flex-basis: auto;
        flex-grow: 0;
        width: 100%;
        padding: 0;
    }
    .nk-navbar .nk-nav .nk-mega-item > .dropdown > ul > li + li {
        margin-top: 40px;
    }
}

.nk-navbar.nk-navbar-light .nk-nav .dropdown {
    background-color: #fff;
}

.nk-navbar .nk-nav-right {
    text-align: right;
}

.nk-navbar .nk-nav-right > * {
    text-align: left;
}

.nk-navbar .nk-nav-right .nk-mega-item > .dropdown {
    right: 0;
    left: auto;
}

.nk-navbar .nk-nav-center {
    text-align: center;
}

.nk-navbar .nk-nav-center > * {
    text-align: left;
}

.nk-navbar .nk-nav-center .nk-mega-item > .dropdown {
    right: 0;
    left: auto;
}

.nk-navbar .nk-drop-item.nk-drop-left > a::after {
    border-top: 2px solid transparent;
    border-right: 2px solid;
    border-bottom: 2px solid transparent;
    border-left: none;
}

.nk-navbar .nk-drop-item.nk-drop-left .dropdown {
    margin-left: -100%;
}

/* Navbar Left */
.nk-navbar-left.nk-navbar-side {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 280px;
    z-index: 999;
}

.nk-navbar-left.nk-navbar-side ~ .nk-main,
.nk-navbar-left.nk-navbar-side ~ .nk-search,
.nk-navbar-left.nk-navbar-side ~ .nk-cart,
.nk-navbar-left.nk-navbar-side ~ .nk-sign-form {
    margin-left: 280px;
}

.nk-navbar-left.nk-navbar-side.nk-navbar-lg {
    width: 300px;
}

.nk-navbar-left.nk-navbar-side.nk-navbar-lg ~ .nk-main,
.nk-navbar-left.nk-navbar-side.nk-navbar-lg ~ .nk-search,
.nk-navbar-left.nk-navbar-side.nk-navbar-lg ~ .nk-cart,
.nk-navbar-left.nk-navbar-side.nk-navbar-lg ~ .nk-sign-form {
    margin-left: 300px;
}

@media (max-width: 991px) {
    .nk-navbar-left.nk-navbar-side {
        display: none;
    }
    .nk-navbar-left.nk-navbar-side ~ .nk-main,
    .nk-navbar-left.nk-navbar-side ~ .nk-search,
    .nk-navbar-left.nk-navbar-side ~ .nk-cart,
    .nk-navbar-left.nk-navbar-side ~ .nk-sign-form, .nk-navbar-left.nk-navbar-side.nk-navbar-lg ~ .nk-main,
    .nk-navbar-left.nk-navbar-side.nk-navbar-lg ~ .nk-search,
    .nk-navbar-left.nk-navbar-side.nk-navbar-lg ~ .nk-cart,
    .nk-navbar-left.nk-navbar-side.nk-navbar-lg ~ .nk-sign-form {
        margin-left: 0;
    }
}

/* Nav Togglers */
.nk-nav-toggler-right,
.nk-nav-toggler-left {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 1000;
}

.nk-nav-toggler-right > li,
.nk-nav-toggler-left > li {
    display: inline-block;
}

.nk-nav-toggler-right > li + li,
.nk-nav-toggler-left > li + li {
    margin-left: 15px;
}

.nk-nav-toggler-right .nk-btn,
.nk-nav-toggler-left .nk-btn {
    line-height: 10px;
}

.nk-nav-toggler-left {
    right: auto;
    left: 20px;
}

/*------------------------------------------------------------------

  Navbar Side

 -------------------------------------------------------------------*/
.nk-navbar-side {
    position: fixed;
    top: 0;
    bottom: 0;
    padding: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    overflow: hidden;
    background-color: #0e0e0e;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.5);
    z-index: 1002;
}

.nk-navbar-side.nk-navbar-left-side {
    left: 0;
    width: 250px;
    margin-left: -250px;
}

@media (max-width: 400px) {
    .nk-navbar-side.nk-navbar-left-side {
        width: 200px;
        margin-left: -200px;
    }
}

.nk-navbar-side.nk-navbar-right-side {
    right: 0;
    width: 250px;
    margin-right: -250px;
}

@media (max-width: 400px) {
    .nk-navbar-side.nk-navbar-right-side {
        width: 200px;
        margin-right: -200px;
    }
}

.nk-navbar-side.nk-navbar-lg.nk-navbar-left-side {
    width: 350px;
    margin-left: -350px;
}

@media (max-width: 550px) {
    .nk-navbar-side.nk-navbar-lg.nk-navbar-left-side {
        width: 300px;
        margin-left: -300px;
    }
}

@media (max-width: 450px) {
    .nk-navbar-side.nk-navbar-lg.nk-navbar-left-side {
        width: 250px;
        margin-left: -250px;
    }
}

.nk-navbar-side.nk-navbar-lg.nk-navbar-right-side {
    width: 350px;
    margin-right: -350px;
}

@media (max-width: 550px) {
    .nk-navbar-side.nk-navbar-lg.nk-navbar-right-side {
        width: 300px;
        margin-right: -300px;
    }
}

@media (max-width: 450px) {
    .nk-navbar-side.nk-navbar-lg.nk-navbar-right-side {
        width: 250px;
        margin-right: -250px;
    }
}

.nk-navbar-side .nk-nav-logo {
    display: block;
    width: 100%;
    padding: 15px 35px;
    padding-top: 0;
}

.nk-navbar-side.nk-navbar-lg .nk-nav-logo {
    padding-bottom: 60px;
}

.nk-navbar-side .nano {
    height: 100%;
    overflow-y: auto;
}

.nk-navbar-side .nk-nav {
    position: relative;
    padding: 0;
    overflow: hidden;
}

.nk-navbar-side .nk-nav .dropdown > li,
.nk-navbar-side .nk-nav > li {
    position: static;
    display: block;
}

.nk-navbar-side .nk-nav .dropdown > li > a,
.nk-navbar-side .nk-nav > li > a {
    padding: 12px 40px;
}

.nk-navbar-side .nk-nav .nk-drop-item > a,
.nk-navbar-side .nk-nav > .nk-drop-item > a,
.nk-navbar-side .nk-nav .bropdown-back > a {
    position: relative;
}

.nk-navbar-side .nk-nav .nk-drop-item .nk-drop-item > a::after,
.nk-navbar-side .nk-nav .nk-drop-item > a::after,
.nk-navbar-side .nk-nav > .nk-drop-item > a::after {
    right: 30px;
    bottom: 50%;
    left: auto;
    margin-bottom: -1px;
}

.nk-navbar-side .nk-nav .dropdown {
    display: block;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    opacity: 1;
}

.nk-navbar-side .nk-nav .dropdown::before {
    content: none;
}

.nk-navbar-side .nk-nav .nk-drop-item > .dropdown,
.nk-navbar-side .nk-nav .nk-drop-item .nk-drop-item > .dropdown {
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 0;
    margin-left: 0;
}

.nk-navbar-side .nk-nav .nk-drop-item > .dropdown > li > a,
.nk-navbar-side .nk-nav .nk-drop-item .nk-drop-item > .dropdown > li > a {
    display: none;
}

.nk-navbar-side .dropdown > .bropdown-back > a::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 50%;
    left: 30px;
    width: 3px;
    height: 3px;
    margin-bottom: -1px;
    margin-left: -1px;
    border-radius: 1.5px;
    box-shadow: 5px 0 0 0, -5px 0 0 0, inset 0 0 0 3px;
    opacity: .4;
    transition: .2s opacity;
}

.nk-navbar-side .dropdown > .bropdown-back > a:hover::after,
.nk-navbar-side .dropdown > .bropdown-back > a.hover::after {
    opacity: .8;
}

.nk-navbar-side .nk-nav-footer {
    padding: 40px;
    padding-bottom: 0;
    color: #999;
    text-align: left;
}

.nk-navbar-side .nk-nav-icons {
    padding-bottom: 30px;
    list-style: none;
}

.nk-navbar-side .nk-nav-icons li {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.nk-navbar-side .nk-nav-icons li > a {
    display: inline-block;
    padding: 0 15px;
}

.nk-navbar-side .nk-nav-icons li > a > i,
.nk-navbar-side .nk-nav-icons li > a > span {
    height: 32px;
    line-height: 32px;
    vertical-align: middle;
}

.nk-navbar-side .nk-nav-logo + .nk-nav-icons {
    margin-top: -30px;
}

.nk-navbar-side.nk-navbar-light {
    background-color: #fff;
}

.nk-navbar-side.nk-navbar-light .nk-nav .dropdown > li > a,
.nk-navbar-side.nk-navbar-light .nk-nav > li > a {
    color: rgba(45, 45, 45, 0.85);
}

.nk-navbar-side.nk-navbar-light .nk-nav .dropdown {
    background-color: transparent;
}

.nk-navbar-overlay {
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: url("../godlike-images/cursor-nav-close.svg") 24 24, pointer;
    background-color: #0e0e0e;
    opacity: 0;
    z-index: 1001;
}

/*------------------------------------------------------------------

  Navbar Full

 -------------------------------------------------------------------*/
.nk-navbar-full {
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    overflow: hidden;
    background: #0e0e0e;
    opacity: 0;
    z-index: 999;
}

.nk-navbar-full .nano {
    display: table-cell;
    height: 100%;
    overflow-y: auto;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .nk-navbar-full .nano {
        display: block;
    }
}

.nk-navbar-full .nk-nav {
    position: relative;
    padding: 0;
    overflow: hidden;
}

.nk-navbar-full .nk-nav li > a .nk-item-descr {
    font-size: .5em;
}

.nk-navbar-full .nk-nav .dropdown > li,
.nk-navbar-full .nk-nav > li {
    position: static;
    display: block;
    max-width: 350px;
    margin: 0 auto;
}

.nk-navbar-full .nk-nav .dropdown > li > a,
.nk-navbar-full .nk-nav > li > a {
    padding: 12px 40px;
    font-size: 2rem;
}

.nk-navbar-full .nk-nav .nk-drop-item > a,
.nk-navbar-full .nk-nav > .nk-drop-item > a,
.nk-navbar-full .nk-nav .bropdown-back > a {
    position: relative;
}

.nk-navbar-full .nk-nav .nk-drop-item > a::after,
.nk-navbar-full .nk-nav .nk-drop-item .nk-drop-item > a::after,
.nk-navbar-full .nk-nav > .nk-drop-item > a::after {
    right: 22px;
    bottom: 50%;
    left: auto;
    margin-bottom: -1px;
}

.nk-navbar-full .nk-nav .dropdown {
    display: block;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    opacity: 1;
}

.nk-navbar-full .nk-nav .dropdown::before {
    content: none;
}

.nk-navbar-full .nk-nav .dropdown > li > a {
    display: none;
}

.nk-navbar-full .nk-nav .nk-drop-item .dropdown,
.nk-navbar-full .nk-nav .nk-drop-item .nk-drop-item .dropdown {
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 0;
    margin-left: 0;
}

.nk-navbar-full .dropdown > .bropdown-back > a::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 50%;
    left: 22px;
    width: 3px;
    height: 3px;
    margin-bottom: -1px;
    margin-left: -1px;
    border-radius: 1.5px;
    box-shadow: 5px 0 0 0, -5px 0 0 0, inset 0 0 0 3px;
    opacity: .4;
    transition: .2s opacity;
}

.nk-navbar-full .dropdown > .bropdown-back > a:hover::after,
.nk-navbar-full .dropdown > .bropdown-back > a.hover::after {
    opacity: .8;
}

.nk-navbar-full .nk-nav-footer {
    padding-top: 40px;
    padding-bottom: 40px;
    color: #999;
}

.nk-navbar-full.nk-navbar-light {
    background: #fff;
}

.nk-navbar-full.nk-navbar-light .nk-nav .dropdown > li > a,
.nk-navbar-full.nk-navbar-light .nk-nav > li > a {
    color: rgba(45, 45, 45, 0.85);
}

.nk-navbar-full.nk-navbar-light .nk-nav .dropdown {
    background-color: transparent;
}

/*------------------------------------------------------------------

  Search

 -------------------------------------------------------------------*/
.nk-search {
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    background: #0e0e0e;
    opacity: 0;
    z-index: 999;
}

.nk-search .nk-search-field {
    position: relative;
    margin-top: 60px;
    margin-bottom: 130px;
}

.nk-search .nk-search-field label {
    position: absolute;
    top: 0;
    left: 0;
    height: 124px;
    margin: 0;
    font-size: 4rem;
    line-height: 124px;
    transition: .3s color;
    will-change: color;
}

.nk-search .nk-search-field input {
    width: 93%;
    width: calc(100% - 70px);
    height: auto;
    padding: 1rem 1rem;
    margin-left: 70px;
    font-size: 5rem;
    color: inherit;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 0;
    transition: .3s border, .3s box-shadow;
    will-change: border, box-shadow;
}

.nk-search .nk-search-field input:focus, .nk-search .nk-search-field input.focus {
    border-bottom-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.8);
}

@media (max-width: 767px) {
    .nk-search .nk-search-field {
        margin-top: 30px;
        margin-bottom: 50px;
    }
    .nk-search .nk-search-field label {
        height: 106px;
        font-size: 3rem;
        line-height: 106px;
    }
    .nk-search .nk-search-field input {
        width: calc(100% - 50px);
        margin-left: 50px;
        font-size: 4rem;
    }
}

@media (max-width: 575px) {
    .nk-search .nk-search-field label {
        height: 68px;
        font-size: 2rem;
        line-height: 68px;
    }
    .nk-search .nk-search-field input {
        width: calc(100% - 40px);
        margin-left: 40px;
        font-size: 2rem;
    }
}

.nk-search.nk-search-light {
    color: #0e0e0e;
    background: #fff;
}

.nk-search.nk-search-light .nk-search-field input {
    border-bottom: 1px solid rgba(14, 14, 14, 0.3);
}

.nk-search.nk-search-light .nk-search-field input:focus, .nk-search.nk-search-light .nk-search-field input.focus {
    border-bottom-color: rgba(14, 14, 14, 0.8);
    box-shadow: 0 1px 0 0 rgba(14, 14, 14, 0.8);
}

/*------------------------------------------------------------------

  Cart

 -------------------------------------------------------------------*/
.nk-cart {
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    background-color: #0e0e0e;
    opacity: 0;
    z-index: 999;
}

.nk-cart .nk-cart-total {
    font-size: 1.4rem;
    font-style: italic;
}

.nk-cart .nk-cart-total > span {
    margin-left: 10px;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
}

.nk-cart .nk-cart-total,
.nk-cart .nk-cart-btns {
    text-align: center;
}

.nk-cart.nk-cart-light {
    color: #0e0e0e;
    background-color: #fff;
}

/*------------------------------------------------------------------

  Sign Form

 -------------------------------------------------------------------*/
.nk-sign-form {
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    background-color: #0e0e0e;
    opacity: 0;
    z-index: 999;
}

.nk-sign-form .nk-sign-form-container {
    position: relative;
}

.nk-sign-form .nk-sign-form-toggle {
    text-align: center;
}

.nk-sign-form .nk-sign-form-toggle a {
    display: inline-block;
    color: inherit;
    text-decoration: none;
    opacity: .4;
    transition: opacity .2s, transform .2s;
    transform: scale(0.7);
}

.nk-sign-form .nk-sign-form-toggle a:hover {
    opacity: .6;
}

.nk-sign-form .nk-sign-form-toggle .active {
    opacity: 1;
    transform: scale(1);
}

.nk-sign-form .nk-sign-form-toggle .active:hover {
    opacity: 1;
}

.nk-sign-form .nk-sign-form-login,
.nk-sign-form .nk-sign-form-lost,
.nk-sign-form .nk-sign-form-register {
    display: none;
    width: 100%;
}

.nk-sign-form .nk-sign-form-login.active,
.nk-sign-form .nk-sign-form-lost.active,
.nk-sign-form .nk-sign-form-register.active {
    display: block;
}

.nk-sign-form.nk-sign-form-light {
    color: #0e0e0e;
    background-color: #fff;
}

/*------------------------------------------------------------------

  Header Title

 -------------------------------------------------------------------*/
.nk-header-title {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    overflow: hidden;
    color: #fff;
    text-align: center;
    background-color: #0e0e0e;
    opacity: .9999;
}

.nk-header-title.nk-header-title-boxed {
    max-width: 1250px;
}

.nk-header-title .nk-header-table {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 450px;
    padding-top: 75px;
}

.nk-header-title.nk-header-title-sm .nk-header-table {
    min-height: 350px;
}

.nk-header-title.nk-header-title-md .nk-header-table {
    min-height: 550px;
}

.nk-header-title.nk-header-title-lg .nk-header-table {
    min-height: 650px;
}

.nk-header-title.nk-header-title-xl .nk-header-table {
    min-height: 750px;
}

.nk-header-title.nk-header-title-full .nk-header-table {
    min-height: 100vh;
}

.nk-header-title .nk-header-table-cell {
    flex: 1;
    width: 100%;
    padding: 80px 0;
}

.nk-header-title .nk-sub-title {
    color: inherit;
}

.nk-header-title .nk-header-text-bottom {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: rgba(14, 14, 14, 0.4);
}

.nk-header-title.nk-header-title-full .nk-header-text-bottom {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
}

/*------------------------------------------------------------------

  Footer

 -------------------------------------------------------------------*/
.nk-footer {
    position: relative;
    color: #eee;
    background-color: #000;
    z-index: 1;
}

.nk-footer-top-corner {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    max-height: 250px;
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.nk-footer-logo {
    text-align: center;
}

.nk-footer-social {
    text-align: center;
}

.nk-footer-social > a {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: 6px 4px;
    font-size: 1.15rem;
    line-height: 40px;
    color: inherit;
    border: 1px solid;
    border-radius: 20px;
    transition: .3s color, .3s background-color, .3s border-color;
    will-change: color, background-color;
}

.nk-footer-social > a:hover, .nk-footer-social > a.hover {
    color: #0e0e0e;
    background-color: #fff;
    border-color: #fff;
}

.nk-footer-social.nk-footer-social-inverted > a:hover, .nk-footer-social.nk-footer-social-inverted > a.hover {
    color: #fff;
    background-color: #0e0e0e;
    border-color: #0e0e0e;
}

.nk-footer-social-2 {
    text-align: center;
}

.nk-footer-social-2 > a {
    display: inline-block;
    margin: 15px;
    color: inherit;
    text-decoration: none;
    transition: .3s opacity;
    will-change: opacity;
}

.nk-footer-social-2 > a:hover, .nk-footer-social-2 > a.hover {
    opacity: .6;
}

.nk-footer-social-2 > a > * {
    display: block;
    margin: 0;
}

.nk-footer-social-2 > a > .icon {
    margin-bottom: 5px;
    font-size: 2rem;
}

.nk-footer-logos a {
    display: inline-block;
    margin: 25px;
}

.nk-footer-logos {
    margin-right: -25px;
    margin-left: -25px;
}

.nk-footer-logos img {
    height: auto;
}

.nk-footer-links > span {
    margin-right: 10px;
    margin-left: 10px;
}

.nk-copyright,
.nk-copyright-2 {
    font-size: .95rem;
    color: rgba(255, 255, 255, 0.7);
}

.nk-copyright a,
.nk-copyright-2 a {
    color: #fff;
}

.nk-copyright {
    padding: 2rem 0;
    background-color: rgba(14, 14, 14, 0.6);
}

/*------------------------------------------------------------------

  Side Buttons

 -------------------------------------------------------------------*/
.nk-side-buttons {
    position: fixed;
    right: 0;
    bottom: 0;
    padding-top: 25px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    transition: .2s transform ease-in-out, .3s margin-bottom;
    transform: translateX(50px) translateY(60%);
    z-index: 1000;
    will-change: transform;
}

.nk-side-buttons.nk-side-buttons-left {
    right: auto;
    left: 0;
    padding-right: 15px;
    padding-left: 20px;
}

.nk-side-buttons .nk-scroll-top {
    transition: .2s transform ease-in-out;
    transform: translateX(20px);
}

.nk-side-buttons ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.nk-side-buttons ul > li {
    display: inline-block;
    text-align: center;
}

.nk-side-buttons ul > li > a,
.nk-side-buttons ul > li > span {
    display: block;
    cursor: pointer;
}

.nk-side-buttons ul > li + li {
    margin-left: 5px;
}

.nk-side-buttons.nk-side-buttons-visible, .nk-side-buttons:hover, .nk-side-buttons.hover {
    transform: translateX(50px) translateY(0) translate3d(0, 0, 0);
}

@media (max-width: 767px) {
    .nk-side-buttons {
        transform: translateX(50px) translateY(0) translate3d(0, 0, 0);
    }
}

.nk-side-buttons.nk-side-buttons-left, .nk-side-buttons.nk-side-buttons-show-scroll-top {
    transform: translateX(0) translateY(60%);
}

.nk-side-buttons.nk-side-buttons-left .nk-scroll-top, .nk-side-buttons.nk-side-buttons-show-scroll-top .nk-scroll-top {
    transform: translateX(0);
}

.nk-side-buttons.nk-side-buttons-left.nk-side-buttons-visible, .nk-side-buttons.nk-side-buttons-left:hover, .nk-side-buttons.nk-side-buttons-left.hover, .nk-side-buttons.nk-side-buttons-show-scroll-top.nk-side-buttons-visible, .nk-side-buttons.nk-side-buttons-show-scroll-top:hover, .nk-side-buttons.nk-side-buttons-show-scroll-top.hover {
    transform: translateX(0) translateY(0) translate3d(0, 0, 0);
}

.nk-side-buttons.nk-side-buttons-left.nk-side-buttons-visible .nk-scroll-top, .nk-side-buttons.nk-side-buttons-left:hover .nk-scroll-top, .nk-side-buttons.nk-side-buttons-left.hover .nk-scroll-top, .nk-side-buttons.nk-side-buttons-show-scroll-top.nk-side-buttons-visible .nk-scroll-top, .nk-side-buttons.nk-side-buttons-show-scroll-top:hover .nk-scroll-top, .nk-side-buttons.nk-side-buttons-show-scroll-top.hover .nk-scroll-top {
    transform: translateX(0);
}

@media (max-width: 767px) {
    .nk-side-buttons.nk-side-buttons-left, .nk-side-buttons.nk-side-buttons-show-scroll-top {
        transform: translateX(0) translateY(0) translate3d(0, 0, 0);
    }
    .nk-side-buttons.nk-side-buttons-left .nk-scroll-top, .nk-side-buttons.nk-side-buttons-show-scroll-top .nk-scroll-top {
        transform: translateX(0);
    }
}

/*------------------------------------------------------------------

  Share Buttons

 -------------------------------------------------------------------*/
.nk-share-buttons {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    text-align: right;
    pointer-events: none;
    opacity: 1;
    transition: .3s opacity;
    z-index: 999;
}

.nk-share-buttons ul {
    padding: 0;
    margin: 0;
    list-style: none;
    pointer-events: auto;
}

.nk-share-buttons ul > li {
    position: relative;
}

.nk-share-buttons ul > li::after {
    content: "";
    display: table;
    width: 100%;
    clear: both;
}

.nk-share-buttons .nk-share-icon,
.nk-share-buttons .nk-share-name {
    display: inline-block;
}

.nk-share-buttons .nk-share-icon {
    position: relative;
    float: right;
    width: 90px;
    padding-top: 18px;
    padding-bottom: 18px;
    margin-left: -30px;
    text-align: center;
    cursor: pointer;
    opacity: .7;
    transition: .2s opacity;
    z-index: 1;
}

.nk-share-buttons .nk-share-icon:hover, .nk-share-buttons .nk-share-icon.hover {
    opacity: 1;
}

.nk-share-buttons .nk-share-icon:hover ~ .nk-share-name, .nk-share-buttons .nk-share-icon.hover ~ .nk-share-name {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
}

.nk-share-buttons .nk-share-name {
    position: absolute;
    top: 0;
    right: 60px;
    padding: 18px;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: .2s opacity, .2s visibility, .2s transform;
    transform: translateX(-10px);
    z-index: 0;
}

.nk-share-buttons.nk-share-buttons-left {
    right: auto;
    left: 0;
}

.nk-share-buttons.nk-share-buttons-left .nk-share-icon {
    float: left;
    margin-right: -30px;
    margin-left: 0;
}

.nk-share-buttons.nk-share-buttons-left .nk-share-name {
    right: auto;
    left: 60px;
}

.nk-navbar-left ~ .nk-share-buttons.nk-share-buttons-left {
    left: 300px;
}

@media (max-width: 991px) {
    .nk-navbar-left ~ .nk-share-buttons.nk-share-buttons-left {
        left: 0;
    }
}

@media (max-width: 575px) {
    .nk-share-buttons .nk-share-icon {
        width: 60px;
    }
    .nk-share-buttons .nk-share-name {
        display: none;
    }
}

@media screen and (max-height: 500px) {
    .nk-share-buttons {
        display: none;
    }
}

body.nk-body-overflow .nk-share-buttons {
    opacity: 0;
}

/*------------------------------------------------------------------

  Widgets

 -------------------------------------------------------------------*/
.nk-widget {
    overflow: hidden;
}

.nk-widget ~ .nk-widget {
    margin-top: 65px;
}

.nk-widget .nk-widget-title {
    margin-bottom: 35px;
    font-size: 1.25rem;
}

.nk-widget-post {
    padding-left: 130px;
}

.nk-widget-post + .nk-widget-post {
    margin-top: 35px;
}

.nk-widget-post::after {
    content: "";
    display: table;
    clear: both;
}

.nk-widget-post a {
    text-decoration: none;
}

.nk-widget-post .nk-post-image {
    float: left;
    width: 100px;
    margin-left: -130px;
    overflow: hidden;
}

.nk-widget-post .nk-post-image img {
    width: 100%;
    height: auto;
}

.nk-widget-post .nk-post-title {
    display: inline-block;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 5px;
    font-size: 1.15rem;
}

.nk-widget-post .nk-post-title a {
    color: inherit;
    transition: .3s opacity;
}

.nk-widget-post .nk-post-title a:hover, .nk-widget-post .nk-post-title a:focus {
    opacity: .7;
}

.nk-widget-post .nk-post-meta-date {
    margin-right: 10px;
    font-size: .9rem;
    opacity: .6;
}

.nk-widget-post .nk-post-meta-date a {
    color: inherit;
    transition: .3s opacity;
}

.nk-widget-post .nk-post-meta-date a:hover, .nk-widget-post .nk-post-meta-date a:focus {
    opacity: .7;
}

.nk-widget-post .nk-post-meta-comments i {
    font-size: 1.2rem;
    line-height: 1.2rem;
    vertical-align: middle;
}

.nk-widget-post .nk-post-meta-comments span {
    font-size: .8rem;
    line-height: .8rem;
    vertical-align: middle;
}

.nk-widget-post .nk-product-price {
    margin-top: 20px;
}

.nk-widget-categories {
    font-size: .9rem;
}

.nk-widget-categories,
.nk-widget-categories ul {
    padding: 0;
    margin: 0;
    margin-top: -14px;
    list-style-type: none;
}

.nk-widget-categories .nk-widget-categories {
    margin-top: 0;
    margin-left: 25px;
}

.nk-widget-categories .nk-widget-categories li {
    border-top: 1px solid rgba(242, 242, 242, 0.3);
}

.nk-widget-categories li {
    display: block;
}

.nk-widget-categories li + li {
    border-top: 1px solid rgba(242, 242, 242, 0.3);
}

.nk-widget-categories li a {
    display: block;
    padding: 16px 0;
    color: inherit;
    text-decoration: none;
    transition: .3s opacity;
}

.nk-widget-categories li a:hover, .nk-widget-categories li a:focus {
    opacity: .7;
}

.nk-widget-categories li a::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    margin-right: 15px;
    vertical-align: middle;
    background-color: #fff;
    border-radius: 2px;
}

.nk-widget-categories li .nk-widget-categories-count {
    float: right;
    color: inherit;
    opacity: .5;
}

.nk-widget-tags,
.nk-post-tags {
    margin-bottom: -5px;
    font-size: .9rem;
    font-style: normal;
}

.nk-widget-tags > a,
.nk-post-tags > a {
    position: relative;
    display: inline-block;
    padding: 5px 25px;
    margin-right: 3px;
    margin-bottom: 8px;
    margin-left: 3px;
    font-size: .8rem;
    color: inherit;
    text-decoration: none;
    border-radius: 2px;
    transition: .3s background-color, .3s color;
    z-index: 1;
}

.nk-widget-tags > a::before,
.nk-post-tags > a::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid;
    border-radius: 2px;
    opacity: .6;
    transition: .3s opacity;
    z-index: -1;
}

.nk-widget-tags > a:hover, .nk-widget-tags > a.hover,
.nk-post-tags > a:hover,
.nk-post-tags > a.hover {
    color: #0e0e0e;
    background-color: #fff;
}

.nk-widget-tags > a:hover::before, .nk-widget-tags > a.hover::before,
.nk-post-tags > a:hover::before,
.nk-post-tags > a.hover::before {
    opacity: 0;
}

.nk-widget-contact {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.nk-widget-contact > li {
    position: relative;
    padding-left: 25px;
}

.nk-widget-contact > li + li {
    margin-top: 15px;
}

.nk-widget-contact .nk-widget-contact-icon {
    position: absolute;
    left: 0;
    font-size: 1.1rem;
}

.nk-widget-social {
    text-align: center;
}

.nk-widget-social > a {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: 6px 4px;
    font-size: 1.15rem;
    line-height: 40px;
    color: inherit;
    text-align: center;
    border: 1px solid;
    border-radius: 20px;
    transition: .3s color, .3s background-color, .3s border-color;
    will-change: color, background-color;
}

.nk-widget-social > a:hover, .nk-widget-social > a.hover {
    color: #0e0e0e;
    background-color: #fff;
    border-color: #fff;
}

.nk-widget-social.nk-widget-social-inverted > a:hover, .nk-widget-social.nk-widget-social-inverted > a.hover {
    color: #fff;
    background-color: #0e0e0e;
    border-color: #0e0e0e;
}

.nk-twitter {
    position: relative;
    padding-left: 40px;
}

.nk-twitter + .nk-twitter {
    margin-top: 30px;
}

.nk-twitter .nk-twitter-date {
    margin-top: 5px;
    font-size: .9rem;
    line-height: 1.4;
    opacity: .6;
}

.nk-twitter .nk-twitter-icon {
    position: absolute;
    top: 12px;
    left: 0;
    font-size: 1.6rem;
}

.nk-nav .nk-widget-store-cart {
    width: 354px;
    padding-top: 5px;
    padding-right: 25px;
    padding-bottom: 5px;
    padding-left: 25px;
}

.nk-widget-store-cart .nk-widget-store-cart-actions {
    margin-top: 25px;
}

.nk-widget-store-cart .nk-widget-store-cart-actions a + a {
    margin-left: 15px;
}

.nk-widget-store-cart .nk-widget-store-cart-total {
    margin-top: 25px;
    margin-bottom: 25px;
}

.nk-widget-store-cart .nk-widget-store-cart-total::before, .nk-widget-store-cart .nk-widget-store-cart-total::after {
    content: "";
    display: table;
    width: 100%;
    clear: both;
    border-top: 1px dotted #777;
}

.nk-widget-store-cart .nk-widget-store-cart-total::before {
    margin-bottom: 15px;
}

.nk-widget-store-cart .nk-widget-store-cart-total::after {
    margin-top: 15px;
}

.nk-widget-store-cart .nk-widget-store-cart-total > a {
    color: inherit;
    text-decoration: none;
    transition: .3s opacity;
}

.nk-widget-store-cart .nk-widget-store-cart-total > a:hover, .nk-widget-store-cart .nk-widget-store-cart-total > a:focus {
    opacity: .7;
}

.nk-widget-store-cart .nk-widget-store-cart-total > span {
    float: right;
}

/* Sidebars */
.nk-sidebar {
    padding-left: 40px;
}

.container-fluid > .row > div > .nk-sidebar.nk-sidebar-left {
    padding-left: 25px;
}

.container-fluid > .row > div > .nk-sidebar.nk-sidebar-right {
    padding-right: 25px;
}

.nk-sidebar.nk-sidebar-left {
    padding-right: 40px;
    padding-left: 0;
}

@media (max-width: 991px) {
    .nk-sidebar, .nk-sidebar.nk-sidebar-left {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}

/*------------------------------------------------------------------

  Elements

 -------------------------------------------------------------------*/
/*---------------------------
  Element Buttons
 ----------------------------*/
.nk-btn {
    position: relative;
    display: inline-block;
    padding: 12px 30px;
    font-family: "Marcellus SC", serif;
    font-size: .9rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: rgba(14, 14, 14, 0.9);
    border: none;
    border-radius: 2px;
    transition: .2s box-shadow, .2s background-color, .2s color, .2s transform;
}

.nk-btn, .nk-btn:hover, .nk-btn.hover, .nk-btn:active, .nk-btn.active, .nk-btn:focus {
    color: inherit;
    text-decoration: none;
}

.nk-btn:hover, .nk-btn.hover {
    background-color: #0e0e0e;
    box-shadow: 0 5px 15px 0 rgba(14, 14, 14, 0.4);
    transform: translateY(-2px);
}

.nk-btn::before {
    content: "";
    position: absolute;
    display: block;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    border: 1px solid;
    border-radius: 2px;
    transition: .3s background-color, .3s border-color;
    z-index: 1;
}

.nk-btn > .icon {
    margin-right: 4px;
    font-size: .8rem;
    vertical-align: middle;
}

.nk-btn > span + .icon {
    margin-right: 0;
    margin-left: 4px;
}

.nk-btn-icon {
    padding: 12px;
}

.nk-btn-icon .icon {
    display: inline-block;
    min-width: 18px;
    margin: 0;
}

.nk-btn-xs {
    padding: 8px 21px;
    font-size: .8rem;
}

.nk-btn-xs.nk-btn-icon {
    padding: 8px;
}

.nk-btn-sm {
    padding: 11px 24px;
    font-size: .85rem;
}

.nk-btn-sm.nk-btn-icon {
    padding: 10px;
}

.nk-btn-lg {
    padding: 14px 36px;
}

.nk-btn-lg.nk-btn-icon {
    padding: 14px;
}

.nk-btn-x2 {
    padding: 16px 40px;
    font-size: 1rem;
}

.nk-btn-x2.nk-btn-icon {
    padding: 16px;
}

.nk-btn-x3 {
    padding: 20px 50px;
    font-size: 1.1rem;
}

.nk-btn-x3.nk-btn-icon {
    padding: 20px;
}

.nk-btn-x4 {
    padding: 22px 60px;
    font-size: 1.2rem;
}

.nk-btn-x4.nk-btn-icon {
    padding: 22px;
}

.nk-btn-color-main-1 {
    color: #e08821;
}

.nk-btn-color-main-1, .nk-btn-color-main-1::after, .nk-btn-color-main-1::before {
    border-color: #b56d19;
}

.nk-btn-color-main-1:hover, .nk-btn-color-main-1.hover {
    color: #e08821;
}

.nk-btn-color-main-1:active, .nk-btn-color-main-1.active {
    color: #885213;
}

.nk-btn-color-main-2 {
    color: #39a228;
}

.nk-btn-color-main-2, .nk-btn-color-main-2::after, .nk-btn-color-main-2::before {
    border-color: #2b791e;
}

.nk-btn-color-main-2:hover, .nk-btn-color-main-2.hover {
    color: #39a228;
}

.nk-btn-color-main-2:active, .nk-btn-color-main-2.active {
    color: #1c5014;
}

.nk-btn-color-main-3 {
    color: #2953b1;
}

.nk-btn-color-main-3, .nk-btn-color-main-3::after, .nk-btn-color-main-3::before {
    border-color: #1f4088;
}

.nk-btn-color-main-3:hover, .nk-btn-color-main-3.hover {
    color: #2953b1;
}

.nk-btn-color-main-3:active, .nk-btn-color-main-3.active {
    color: #162c5e;
}

.nk-btn-color-main-4 {
    color: #7c34a8;
}

.nk-btn-color-main-4, .nk-btn-color-main-4::after, .nk-btn-color-main-4::before {
    border-color: #5f2881;
}

.nk-btn-color-main-4:hover, .nk-btn-color-main-4.hover {
    color: #7c34a8;
}

.nk-btn-color-main-4:active, .nk-btn-color-main-4.active {
    color: #431c5a;
}

.nk-btn-color-main-5 {
    color: #c82e2e;
}

.nk-btn-color-main-5, .nk-btn-color-main-5::after, .nk-btn-color-main-5::before {
    border-color: #9f2424;
}

.nk-btn-color-main-5:hover, .nk-btn-color-main-5.hover {
    color: #c82e2e;
}

.nk-btn-color-main-5:active, .nk-btn-color-main-5.active {
    color: #751b1b;
}

.nk-btn-color-primary {
    color: #0275d8;
}

.nk-btn-color-primary, .nk-btn-color-primary::after, .nk-btn-color-primary::before {
    border-color: #025aa5;
}

.nk-btn-color-primary:hover, .nk-btn-color-primary.hover {
    color: #0275d8;
}

.nk-btn-color-primary:active, .nk-btn-color-primary.active {
    color: #013e73;
}

.nk-btn-color-success {
    color: #5cb85c;
}

.nk-btn-color-success, .nk-btn-color-success::after, .nk-btn-color-success::before {
    border-color: #449d44;
}

.nk-btn-color-success:hover, .nk-btn-color-success.hover {
    color: #5cb85c;
}

.nk-btn-color-success:active, .nk-btn-color-success.active {
    color: #357935;
}

.nk-btn-color-info {
    color: #5bc0de;
}

.nk-btn-color-info, .nk-btn-color-info::after, .nk-btn-color-info::before {
    border-color: #31b0d5;
}

.nk-btn-color-info:hover, .nk-btn-color-info.hover {
    color: #5bc0de;
}

.nk-btn-color-info:active, .nk-btn-color-info.active {
    color: #2390b0;
}

.nk-btn-color-warning {
    color: #f0ad4e;
}

.nk-btn-color-warning, .nk-btn-color-warning::after, .nk-btn-color-warning::before {
    border-color: #ec971f;
}

.nk-btn-color-warning:hover, .nk-btn-color-warning.hover {
    color: #f0ad4e;
}

.nk-btn-color-warning:active, .nk-btn-color-warning.active {
    color: #c77c11;
}

.nk-btn-color-danger {
    color: #d9534f;
}

.nk-btn-color-danger, .nk-btn-color-danger::after, .nk-btn-color-danger::before {
    border-color: #c9302c;
}

.nk-btn-color-danger:hover, .nk-btn-color-danger.hover {
    color: #d9534f;
}

.nk-btn-color-danger:active, .nk-btn-color-danger.active {
    color: #a02622;
}

.nk-btn-color-white {
    color: #fff;
}

.nk-btn-color-white, .nk-btn-color-white::after, .nk-btn-color-white::before {
    border-color: #e6e6e6;
}

.nk-btn-color-white:hover, .nk-btn-color-white.hover {
    color: #fff;
}

.nk-btn-color-white:active, .nk-btn-color-white.active {
    color: #cccccc;
}

.nk-btn-color-black {
    color: #000;
}

.nk-btn-color-black, .nk-btn-color-black::after, .nk-btn-color-black::before {
    border-color: black;
}

.nk-btn-color-black:hover, .nk-btn-color-black.hover {
    color: #000;
}

.nk-btn-color-black:active, .nk-btn-color-black.active {
    color: black;
}

.nk-btn-color-dark-1 {
    color: #0e0e0e;
}

.nk-btn-color-dark-1, .nk-btn-color-dark-1::after, .nk-btn-color-dark-1::before {
    border-color: black;
}

.nk-btn-color-dark-1:hover, .nk-btn-color-dark-1.hover {
    color: #0e0e0e;
}

.nk-btn-color-dark-1:active, .nk-btn-color-dark-1.active {
    color: black;
}

.nk-btn-color-dark-2 {
    color: #181818;
}

.nk-btn-color-dark-2, .nk-btn-color-dark-2::after, .nk-btn-color-dark-2::before {
    border-color: black;
}

.nk-btn-color-dark-2:hover, .nk-btn-color-dark-2.hover {
    color: #181818;
}

.nk-btn-color-dark-2:active, .nk-btn-color-dark-2.active {
    color: black;
}

.nk-btn-color-dark-3 {
    color: #222222;
}

.nk-btn-color-dark-3, .nk-btn-color-dark-3::after, .nk-btn-color-dark-3::before {
    border-color: #090909;
}

.nk-btn-color-dark-3:hover, .nk-btn-color-dark-3.hover {
    color: #222222;
}

.nk-btn-color-dark-3:active, .nk-btn-color-dark-3.active {
    color: black;
}

.nk-btn-color-dark-4 {
    color: #2d2d2d;
}

.nk-btn-color-dark-4, .nk-btn-color-dark-4::after, .nk-btn-color-dark-4::before {
    border-color: #131313;
}

.nk-btn-color-dark-4:hover, .nk-btn-color-dark-4.hover {
    color: #2d2d2d;
}

.nk-btn-color-dark-4:active, .nk-btn-color-dark-4.active {
    color: black;
}

.nk-btn-color-gray-1 {
    color: #fafafa;
}

.nk-btn-color-gray-1, .nk-btn-color-gray-1::after, .nk-btn-color-gray-1::before {
    border-color: #e1e1e1;
}

.nk-btn-color-gray-1:hover, .nk-btn-color-gray-1.hover {
    color: #fafafa;
}

.nk-btn-color-gray-1:active, .nk-btn-color-gray-1.active {
    color: #c7c7c7;
}

.nk-btn-color-gray-2 {
    color: #f7f7f7;
}

.nk-btn-color-gray-2, .nk-btn-color-gray-2::after, .nk-btn-color-gray-2::before {
    border-color: #dedede;
}

.nk-btn-color-gray-2:hover, .nk-btn-color-gray-2.hover {
    color: #f7f7f7;
}

.nk-btn-color-gray-2:active, .nk-btn-color-gray-2.active {
    color: #c4c4c4;
}

.nk-btn-color-gray-3 {
    color: whitesmoke;
}

.nk-btn-color-gray-3, .nk-btn-color-gray-3::after, .nk-btn-color-gray-3::before {
    border-color: #dbdbdb;
}

.nk-btn-color-gray-3:hover, .nk-btn-color-gray-3.hover {
    color: whitesmoke;
}

.nk-btn-color-gray-3:active, .nk-btn-color-gray-3.active {
    color: #c2c2c2;
}

.nk-btn-color-gray-4 {
    color: #f2f2f2;
}

.nk-btn-color-gray-4, .nk-btn-color-gray-4::after, .nk-btn-color-gray-4::before {
    border-color: #d9d9d9;
}

.nk-btn-color-gray-4:hover, .nk-btn-color-gray-4.hover {
    color: #f2f2f2;
}

.nk-btn-color-gray-4:active, .nk-btn-color-gray-4.active {
    color: #bfbfbf;
}

.nk-btn-bg-main-1 {
    background-color: rgba(224, 136, 33, 0.9);
}

.nk-btn-bg-main-1:hover, .nk-btn-bg-main-1.hover {
    background-color: #e08821;
}

.nk-btn-bg-main-2 {
    background-color: rgba(57, 162, 40, 0.9);
}

.nk-btn-bg-main-2:hover, .nk-btn-bg-main-2.hover {
    background-color: #39a228;
}

.nk-btn-bg-main-3 {
    background-color: rgba(41, 83, 177, 0.9);
}

.nk-btn-bg-main-3:hover, .nk-btn-bg-main-3.hover {
    background-color: #2953b1;
}

.nk-btn-bg-main-4 {
    background-color: rgba(124, 52, 168, 0.9);
}

.nk-btn-bg-main-4:hover, .nk-btn-bg-main-4.hover {
    background-color: #7c34a8;
}

.nk-btn-bg-main-5 {
    background-color: rgba(200, 46, 46, 0.9);
}

.nk-btn-bg-main-5:hover, .nk-btn-bg-main-5.hover {
    background-color: #c82e2e;
}

.nk-btn-bg-primary {
    background-color: rgba(2, 117, 216, 0.9);
}

.nk-btn-bg-primary:hover, .nk-btn-bg-primary.hover {
    background-color: #0275d8;
}

.nk-btn-bg-success {
    background-color: rgba(92, 184, 92, 0.9);
}

.nk-btn-bg-success:hover, .nk-btn-bg-success.hover {
    background-color: #5cb85c;
}

.nk-btn-bg-info {
    background-color: rgba(91, 192, 222, 0.9);
}

.nk-btn-bg-info:hover, .nk-btn-bg-info.hover {
    background-color: #5bc0de;
}

.nk-btn-bg-warning {
    background-color: rgba(240, 173, 78, 0.9);
}

.nk-btn-bg-warning:hover, .nk-btn-bg-warning.hover {
    background-color: #f0ad4e;
}

.nk-btn-bg-danger {
    background-color: rgba(217, 83, 79, 0.9);
}

.nk-btn-bg-danger:hover, .nk-btn-bg-danger.hover {
    background-color: #d9534f;
}

.nk-btn-bg-white {
    background-color: rgba(255, 255, 255, 0.9);
}

.nk-btn-bg-white:hover, .nk-btn-bg-white.hover {
    background-color: #fff;
}

.nk-btn-bg-black {
    background-color: rgba(0, 0, 0, 0.9);
}

.nk-btn-bg-black:hover, .nk-btn-bg-black.hover {
    background-color: #000;
}

.nk-btn-bg-dark-1 {
    background-color: rgba(14, 14, 14, 0.9);
}

.nk-btn-bg-dark-1:hover, .nk-btn-bg-dark-1.hover {
    background-color: #0e0e0e;
}

.nk-btn-bg-dark-2 {
    background-color: rgba(24, 24, 24, 0.9);
}

.nk-btn-bg-dark-2:hover, .nk-btn-bg-dark-2.hover {
    background-color: #181818;
}

.nk-btn-bg-dark-3 {
    background-color: rgba(34, 34, 34, 0.9);
}

.nk-btn-bg-dark-3:hover, .nk-btn-bg-dark-3.hover {
    background-color: #222222;
}

.nk-btn-bg-dark-4 {
    background-color: rgba(45, 45, 45, 0.9);
}

.nk-btn-bg-dark-4:hover, .nk-btn-bg-dark-4.hover {
    background-color: #2d2d2d;
}

.nk-btn-bg-gray-1 {
    background-color: rgba(250, 250, 250, 0.9);
}

.nk-btn-bg-gray-1:hover, .nk-btn-bg-gray-1.hover {
    background-color: #fafafa;
}

.nk-btn-bg-gray-2 {
    background-color: rgba(247, 247, 247, 0.9);
}

.nk-btn-bg-gray-2:hover, .nk-btn-bg-gray-2.hover {
    background-color: #f7f7f7;
}

.nk-btn-bg-gray-3 {
    background-color: rgba(245, 245, 245, 0.9);
}

.nk-btn-bg-gray-3:hover, .nk-btn-bg-gray-3.hover {
    background-color: whitesmoke;
}

.nk-btn-bg-gray-4 {
    background-color: rgba(242, 242, 242, 0.9);
}

.nk-btn-bg-gray-4:hover, .nk-btn-bg-gray-4.hover {
    background-color: #f2f2f2;
}

.nk-btn-circle,
.nk-btn-circle::after,
.nk-btn-circle::before {
    border-radius: 25px;
}

.nk-btn-circle.nk-btn-xs,
.nk-btn-circle::after.nk-btn-xs,
.nk-btn-circle::before.nk-btn-xs {
    border-radius: 20px;
}

.nk-btn-circle.nk-btn-sm,
.nk-btn-circle::after.nk-btn-sm,
.nk-btn-circle::before.nk-btn-sm {
    border-radius: 23px;
}

.nk-btn-circle.nk-btn-lg,
.nk-btn-circle::after.nk-btn-lg,
.nk-btn-circle::before.nk-btn-lg {
    border-radius: 27px;
}

.nk-btn-circle.nk-btn-x2,
.nk-btn-circle::after.nk-btn-x2,
.nk-btn-circle::before.nk-btn-x2 {
    border-radius: 30px;
}

.nk-btn-circle.nk-btn-x3,
.nk-btn-circle::after.nk-btn-x3,
.nk-btn-circle::before.nk-btn-x3 {
    border-radius: 34.5px;
}

.nk-btn-circle.nk-btn-x4,
.nk-btn-circle::after.nk-btn-x4,
.nk-btn-circle::before.nk-btn-x4 {
    border-radius: 38px;
}

.nk-btn-block {
    display: block;
    width: 100%;
}

/*---------------------------
  Element Progress
 ----------------------------*/
.nk-progress {
    display: block;
    width: 100%;
    margin-bottom: 25px;
}

.nk-progress .nk-progress-line {
    width: 100%;
    height: 5px;
    background-color: #222222;
}

.nk-progress .nk-progress-line > div {
    height: 100%;
    text-align: right;
    background-color: #e6e6e6;
}

.nk-progress .nk-progress-title {
    margin-bottom: 5px;
    font-family: "Marcellus SC", serif;
}

.nk-progress .nk-progress-percent {
    display: inline-block;
    margin-top: 5px;
    font-size: .7rem;
    vertical-align: top;
}

.nk-progress.nk-progress-percent-static {
    padding-right: 30px;
}

.nk-progress.nk-progress-percent-static .nk-progress-line {
    position: relative;
}

.nk-progress.nk-progress-percent-static .nk-progress-percent {
    position: absolute;
    top: 0;
    left: 100%;
    margin-top: 2px;
    margin-left: 10px;
}

.nk-progress.nk-progress-xs .nk-progress-line {
    height: 1px;
}

.nk-progress.nk-progress-xs .nk-progress-percent {
    margin-top: 1px;
}

.nk-progress.nk-progress-xs.nk-progress-percent-static .nk-progress-percent {
    margin-top: -1px;
}

.nk-progress.nk-progress-sm .nk-progress-line {
    height: 3px;
}

.nk-progress.nk-progress-sm .nk-progress-percent {
    margin-top: 3px;
}

.nk-progress.nk-progress-sm.nk-progress-percent-static .nk-progress-percent {
    margin-top: 1px;
}

.nk-progress.nk-progress-md .nk-progress-line {
    height: 8px;
}

.nk-progress.nk-progress-md .nk-progress-percent {
    margin-top: 8px;
}

.nk-progress.nk-progress-md.nk-progress-percent-static .nk-progress-percent {
    margin-top: 7px;
}

.nk-progress.nk-progress-lg .nk-progress-line {
    height: 12px;
}

.nk-progress.nk-progress-lg .nk-progress-percent {
    margin-top: 12px;
}

.nk-progress.nk-progress-lg.nk-progress-percent-static .nk-progress-percent {
    margin-top: 12px;
}

.nk-progress.nk-progress-xlg .nk-progress-line {
    height: 16px;
}

.nk-progress.nk-progress-xlg .nk-progress-percent {
    margin-top: 16px;
}

.nk-progress.nk-progress-xlg.nk-progress-percent-static .nk-progress-percent {
    margin-top: 15px;
}

/*---------------------------
  Element Tabs
 ----------------------------*/
.nk-tabs .nav-tabs {
    border-bottom: none;
}

.nk-tabs .nav-tabs::after {
    content: "";
    display: block;
    border-bottom: 1px solid;
    opacity: .2;
}

.nk-tabs .nav-tabs .nav-item, .nk-tabs .nav-tabs .nav-item:focus, .nk-tabs .nav-tabs .nav-item:hover,
.nk-tabs .nav-tabs .nav-link,
.nk-tabs .nav-tabs .nav-link:focus,
.nk-tabs .nav-tabs .nav-link:hover,
.nk-tabs .nav-tabs .nav-item.open .nav-link,
.nk-tabs .nav-tabs .nav-item.open .nav-link:focus,
.nk-tabs .nav-tabs .nav-item.open .nav-link:hover,
.nk-tabs .nav-tabs .nav-link.active,
.nk-tabs .nav-tabs .nav-link.active:focus,
.nk-tabs .nav-tabs .nav-link.active:hover {
    color: inherit;
    background-color: transparent;
}

.nk-tabs .nav-tabs .nav-link {
    padding: 10px 20px;
    border: none;
    border-bottom: 2px solid transparent;
}

.nk-tabs .nav-tabs .nav-link.active, .nk-tabs .nav-tabs .nav-link.active:focus, .nk-tabs .nav-tabs .nav-link.active:hover {
    border-bottom: 2px solid;
}

/*---------------------------
  Element Accordion
 ----------------------------*/
.nk-accordion .panel-heading::after {
    content: "";
    display: block;
    border-bottom: 1px solid;
    opacity: .2;
}

.nk-accordion .panel-heading a {
    display: block;
    padding: 10px 20px;
    color: inherit;
    text-decoration: none;
}

.nk-accordion .panel-heading a::after {
    content: ">";
    float: right;
    margin-top: 2px;
    font-family: monospace;
    font-size: .8em;
    font-weight: 600;
    opacity: .6;
    transition: .2s transform;
    transform: rotate(90deg);
}

.nk-accordion .panel-heading a.collapsed::after {
    transform: rotate(0);
}

/*---------------------------
  Element Icon Boxes
 ----------------------------*/
.nk-ibox {
    position: relative;
    text-align: center;
}

.nk-ibox-icon {
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 40px;
    font-size: 4rem;
    line-height: 70px;
    text-align: center;
}

.nk-ibox .nk-ibox-title {
    margin-bottom: 40px;
}

/* Titles */
.nk-ibox-title {
    font-size: 1.4rem;
}

.nk-ibox-title a {
    color: inherit;
    text-decoration: none;
}

/* Feature Hovered + Colors */
.nk-ibox-hover .nk-ibox-icon {
    transition: .3s background-color, .3s color;
}

.nk-ibox-hover .nk-ibox-icon::after {
    transition: .3s opacity;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-main-1,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-1,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-1 {
    color: #fff;
    background-color: #e08821;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-main-1::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-1::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-1::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-main-1,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-main-1,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-main-1 {
    color: #e08821;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-main-2,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-2,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-2 {
    color: #fff;
    background-color: #39a228;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-main-2::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-2::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-2::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-main-2,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-main-2,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-main-2 {
    color: #39a228;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-main-3,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-3,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-3 {
    color: #fff;
    background-color: #2953b1;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-main-3::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-3::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-3::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-main-3,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-main-3,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-main-3 {
    color: #2953b1;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-main-4,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-4,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-4 {
    color: #fff;
    background-color: #7c34a8;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-main-4::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-4::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-4::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-main-4,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-main-4,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-main-4 {
    color: #7c34a8;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-main-5,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-5,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-5 {
    color: #fff;
    background-color: #c82e2e;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-main-5::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-5::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-main-5::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-main-5,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-main-5,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-main-5 {
    color: #c82e2e;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-primary,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-primary,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-primary {
    color: #fff;
    background-color: #0275d8;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-primary::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-primary::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-primary::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-primary,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-primary,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-primary {
    color: #0275d8;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-success,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-success,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-success {
    color: #fff;
    background-color: #5cb85c;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-success::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-success::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-success::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-success,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-success,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-success {
    color: #5cb85c;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-info,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-info,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-info {
    color: #fff;
    background-color: #5bc0de;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-info::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-info::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-info::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-info,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-info,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-info {
    color: #5bc0de;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-warning,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-warning,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-warning {
    color: #fff;
    background-color: #f0ad4e;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-warning::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-warning::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-warning::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-warning,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-warning,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-warning {
    color: #f0ad4e;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-danger,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-danger,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-danger {
    color: #fff;
    background-color: #d9534f;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-danger::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-danger::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-danger::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-danger,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-danger,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-danger {
    color: #d9534f;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-white,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-white,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-white {
    color: #fff;
    background-color: #fff;
    color: #0e0e0e;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-white::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-white::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-white::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-white,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-white,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-white {
    color: #fff;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-black,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-black,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-black {
    color: #fff;
    background-color: #000;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-black::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-black::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-black::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-black,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-black,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-black {
    color: #000;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-dark-1,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-dark-1,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-dark-1 {
    color: #fff;
    background-color: #0e0e0e;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-dark-1::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-dark-1::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-dark-1::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-dark-1,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-dark-1,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-dark-1 {
    color: #0e0e0e;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-dark-2,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-dark-2,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-dark-2 {
    color: #fff;
    background-color: #181818;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-dark-2::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-dark-2::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-dark-2::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-dark-2,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-dark-2,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-dark-2 {
    color: #181818;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-dark-3,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-dark-3,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-dark-3 {
    color: #fff;
    background-color: #222222;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-dark-3::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-dark-3::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-dark-3::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-dark-3,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-dark-3,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-dark-3 {
    color: #222222;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-dark-4,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-dark-4,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-dark-4 {
    color: #fff;
    background-color: #2d2d2d;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-dark-4::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-dark-4::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-dark-4::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-dark-4,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-dark-4,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-dark-4 {
    color: #2d2d2d;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-gray-1,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-gray-1,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-gray-1 {
    color: #fff;
    background-color: #fafafa;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-gray-1::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-gray-1::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-gray-1::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-gray-1,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-gray-1,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-gray-1 {
    color: #fafafa;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-gray-2,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-gray-2,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-gray-2 {
    color: #fff;
    background-color: #f7f7f7;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-gray-2::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-gray-2::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-gray-2::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-gray-2,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-gray-2,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-gray-2 {
    color: #f7f7f7;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-gray-3,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-gray-3,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-gray-3 {
    color: #fff;
    background-color: whitesmoke;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-gray-3::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-gray-3::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-gray-3::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-gray-3,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-gray-3,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-gray-3 {
    color: whitesmoke;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-gray-4,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-gray-4,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-gray-4 {
    color: #fff;
    background-color: #f2f2f2;
}

.nk-ibox-icon.nk-ibox-icon-bg-color-gray-4::after,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-gray-4::after,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-gray-4::after {
    opacity: 0;
}

.nk-ibox-icon.nk-ibox-icon-color-gray-4,
.nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-gray-4,
.nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-gray-4 {
    color: #f2f2f2;
}

/*---------------------------
  Element Content Boxes
 ----------------------------*/
.nk-box,
.nk-box-1,
.nk-box-2,
.nk-box-3,
.nk-box-4,
.nk-box-rounded,
.nk-box-rounded-1,
.nk-box-rounded-2,
.nk-box-rounded-3,
.nk-box-rounded-4 {
    position: relative;
    display: block;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}

.nk-box-1,
.nk-box-rounded-1 {
    padding: 20px;
}

.nk-box-2,
.nk-box-rounded-2 {
    padding: 40px;
}

.nk-box-3,
.nk-box-rounded-3 {
    padding: 60px;
}

.nk-box-4,
.nk-box-rounded-4 {
    padding: 80px;
}

.nk-box-rounded,
.nk-box-rounded-1,
.nk-box-rounded-2,
.nk-box-rounded-3,
.nk-box-rounded-4 {
    border-radius: 6px;
}

/* line between boxes
   Used JS code to add .nk-box-line-top and .nk-box-last classnames */
.nk-box-line::before {
    content: "";
    position: absolute;
    display: block;
    top: 2rem;
    right: 0;
    bottom: 2rem;
    border-left: 1px solid;
    opacity: .07;
}

.nk-box-line.nk-box-line-top::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 2rem;
    left: 2rem;
    border-bottom: 1px solid;
    opacity: .07;
}

.nk-box-line.nk-box-last::before {
    content: none;
}

/*---------------------------
  Element Counters
 ----------------------------*/
.nk-counter,
.nk-counter-2,
.nk-counter-3 {
    position: relative;
}

.nk-counter .nk-count,
.nk-counter-2 .nk-count,
.nk-counter-3 .nk-count {
    font-family: "Marcellus SC", serif;
    font-weight: 600;
}

/* Style 1 */
.nk-counter .nk-count {
    margin-bottom: 30px;
    font-size: 4rem;
}

/* Style 2 */
.nk-counter-2 .nk-count {
    margin-bottom: 10px;
    font-size: 4rem;
    line-height: 1;
    color: #e08821;
}

/* Style 3 */
.nk-counter-3 .nk-counter-icon {
    font-size: 3rem;
    line-height: 1;
    color: #e08821;
}

.nk-counter-3 .nk-count {
    font-size: 3rem;
    line-height: 3;
}

.nk-counter-3 .nk-counter-title {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 400;
    line-height: 1;
}

/*---------------------------
  Element Dividers
 ----------------------------*/
.nk-divider {
    display: block;
    width: 100%;
    border-bottom: 1px solid;
    opacity: .4;
}

/*---------------------------
  Element Breadcrumbs
 ----------------------------*/
.nk-breadcrumbs {
    padding: 30px 0;
    background-color: #0e0e0e;
}

.nk-breadcrumbs ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.nk-breadcrumbs ul > li {
    position: relative;
    display: inline-block;
}

.nk-breadcrumbs ul > li > span,
.nk-breadcrumbs ul > li > a {
    padding: 0 15px;
    transition: .2s opacity;
}

.nk-breadcrumbs ul > li > span,
.nk-breadcrumbs ul > li > a,
.nk-breadcrumbs ul > li > a:hover,
.nk-breadcrumbs ul > li > a:focus,
.nk-breadcrumbs ul > li > a:active {
    color: inherit;
    text-decoration: none;
}

.nk-breadcrumbs ul > li > a:hover,
.nk-breadcrumbs ul > li > a.hover {
    opacity: .8;
}

.nk-breadcrumbs ul > li + li::before {
    content: " / ";
    opacity: .6;
}

.nk-header-text-bottom .nk-breadcrumbs {
    padding: 0;
    background-color: transparent;
}

/*---------------------------
  Element Dropcaps
 ----------------------------*/
.nk-dropcap-circle {
    border-radius: 50%;
}

.nk-dropcap,
.nk-dropcap-2,
.nk-dropcap-3 {
    /* display: inline-block; */
    float: left;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.nk-dropcap {
    margin-right: 15px;
    font-size: 3rem;
}

.nk-dropcap-2 {
    margin-top: 6px;
    margin-right: 15px;
    font-size: 2rem;
    color: #fff;
    background-color: #0e0e0e;
}

.nk-dropcap-3 {
    width: 35px;
    height: 40px;
    margin-left: -10px;
    font-family: "Marcellus SC", serif;
    font-size: 6rem;
    font-weight: 600;
    line-height: 4rem;
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    opacity: .15;
    z-index: -1;
}

/*---------------------------
  Element Pagination
 ----------------------------*/
.nk-pagination {
    display: block;
}

.nk-pagination .nk-pagination-prev,
.nk-pagination .nk-pagination-next {
    position: relative;
    display: inline-block;
    min-height: 65px;
    padding: 17px 0;
    color: inherit;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
}

.nk-pagination .nk-pagination-prev.disabled,
.nk-pagination .nk-pagination-next.disabled {
    pointer-events: none;
    cursor: default;
    opacity: .2;
}

.nk-pagination .nk-pagination-prev .nk-icon-arrow-right,
.nk-pagination .nk-pagination-prev .nk-icon-arrow-left,
.nk-pagination .nk-pagination-next .nk-icon-arrow-right,
.nk-pagination .nk-pagination-next .nk-icon-arrow-left {
    position: absolute;
    top: 22px;
}

.nk-pagination .nk-pagination-prev .nk-icon-arrow-right,
.nk-pagination .nk-pagination-next .nk-icon-arrow-right {
    right: 0;
}

.nk-pagination .nk-pagination-prev .nk-icon-arrow-left,
.nk-pagination .nk-pagination-next .nk-icon-arrow-left {
    left: 0;
}

.nk-pagination .nk-pagination-prev {
    float: left;
    padding-left: 25px;
    text-align: left;
}

.nk-pagination .nk-pagination-next {
    float: right;
    padding-right: 25px;
    text-align: right;
}

.nk-pagination nav {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
}

.nk-pagination nav > * {
    display: inline-block;
    padding: 9px;
}

.nk-pagination nav > *.nk-pagination-current, .nk-pagination nav > *.nk-pagination-current-white {
    width: 34px;
    height: 34px;
    padding: 6px;
    line-height: 22px;
    color: #fff;
    background-color: #0e0e0e;
    border-radius: 17px;
}

.nk-pagination nav > *.nk-pagination-current:hover, .nk-pagination nav > *.nk-pagination-current-white:hover {
    opacity: 1;
}

.nk-pagination nav > *.nk-pagination-current-white {
    color: #0e0e0e;
    background-color: #fff;
}

.nk-pagination nav a {
    color: inherit;
    text-decoration: none;
    transition: .3s opacity;
}

.nk-pagination nav a:hover {
    opacity: .6;
}

.nk-pagination.nk-pagination-center .nk-pagination-prev,
.nk-pagination.nk-pagination-center .nk-pagination-next, .nk-pagination.nk-pagination-left .nk-pagination-prev,
.nk-pagination.nk-pagination-left .nk-pagination-next, .nk-pagination.nk-pagination-right .nk-pagination-prev,
.nk-pagination.nk-pagination-right .nk-pagination-next {
    float: none;
}

.nk-pagination.nk-pagination-center {
    text-align: center;
}

.nk-pagination.nk-pagination-left {
    text-align: left;
}

.nk-pagination.nk-pagination-right {
    text-align: right;
}

/*---------------------------
  Element Pricing
 ----------------------------*/
.nk-pricing {
    position: relative;
    height: 100%;
    padding: 0 30px;
    overflow: hidden;
    color: #0e0e0e;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 0 15px 0 rgba(14, 14, 14, 0.05);
}

.nk-pricing .nk-pricing-title {
    margin: 0;
    font-family: inherit;
    font-size: 1.8rem;
    font-weight: 400;
}

.nk-pricing .nk-pricing-price {
    font-family: "Marcellus SC", serif;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1;
}

.nk-pricing .nk-pricing-currency,
.nk-pricing .nk-pricing-period {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1rem;
    font-weight: 400;
}

.nk-pricing .nk-pricing-currency {
    vertical-align: top;
}

.nk-pricing .nk-pricing-features {
    padding: 0;
    margin: 0;
    list-style: none;
}

.nk-pricing .nk-pricing-features > li + li {
    margin-top: 20px;
}

.nk-pricing .nk-pricing-button {
    width: 100%;
    max-width: 220px;
    margin-right: auto;
    margin-left: auto;
}

.nk-pricing .nk-pricing-title,
.nk-pricing .nk-pricing-price,
.nk-pricing .nk-pricing-features,
.nk-pricing .nk-pricing-button {
    position: relative;
    padding: 40px 0;
}

.nk-pricing .nk-pricing-title::after,
.nk-pricing .nk-pricing-price::after,
.nk-pricing .nk-pricing-features::after,
.nk-pricing .nk-pricing-button::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid;
    opacity: .1;
}

.nk-pricing .nk-pricing-button::after {
    content: none;
}

/*---------------------------
  Element Testimonials
 ----------------------------*/
/* Style 1 */
.nk-testimonial {
    position: relative;
    display: block;
    width: 100%;
    margin: 0;
    text-align: center;
}

.nk-testimonial .nk-testimonial-name {
    margin-bottom: 0;
}

.nk-testimonial .nk-testimonial-source {
    margin-top: 10px;
    font-style: italic;
    opacity: .6;
}

.nk-testimonial .nk-testimonial-body {
    margin-bottom: 30px;
    font-size: 1.1rem;
}

@media (max-width: 575px) {
    .nk-testimonial .nk-testimonial-photo {
        display: none;
    }
    .nk-testimonial .nk-testimonial-name,
    .nk-testimonial .nk-testimonial-source,
    .nk-testimonial .nk-testimonial-body {
        margin-left: 0;
    }
}

/* Style 2 */
.nk-testimonial-2 {
    position: relative;
    display: block;
    width: 100%;
    padding: 50px;
    margin: 0;
    margin-top: 50px;
    text-align: center;
    background-color: #0e0e0e;
}

.nk-testimonial-2 .nk-testimonial-photo {
    position: absolute;
    top: -50px;
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
    overflow: hidden;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 50px;
}

.nk-testimonial-2 .nk-testimonial-source {
    margin-top: 10px;
    font-style: italic;
    opacity: .6;
}

.nk-testimonial-2 .nk-testimonial-body {
    position: relative;
    margin-top: 45px;
    margin-bottom: 40px;
    font-size: 1.1rem;
    z-index: 1;
}

.nk-testimonial-2 .nk-testimonial-body::before {
    content: "\201C";
    position: absolute;
    top: -35px;
    left: -15px;
    font-family: "Times New Roman", Times, serif;
    font-size: 150px;
    font-weight: 600;
    line-height: 135px;
    opacity: .08;
    z-index: -1;
}

/*---------------------------
  Element Blockquotes
 ----------------------------*/
.nk-blockquote {
    position: relative;
    padding: 30px 0;
    padding-top: 45px;
    padding-left: 20px;
    z-index: 1;
}

.nk-blockquote::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: 2px solid;
    opacity: .2;
}

.nk-blockquote .nk-blockquote-icon {
    position: absolute;
    top: 15px;
    left: 20px;
    font-family: "Times New Roman", Times, serif;
    font-size: 150px;
    font-weight: 600;
    line-height: 135px;
    color: #aaa;
    opacity: .1;
    z-index: -1;
}

.nk-blockquote .nk-blockquote-author {
    padding-left: 20px;
    margin-top: 15px;
    font-weight: 600;
}

.nk-blockquote > a {
    color: inherit;
    text-decoration: none;
}

.nk-blockquote em {
    display: block;
    margin-left: 20px;
}

.nk-blockquote-styled {
    position: relative;
    margin: 20px 0;
    overflow: hidden;
    background-color: #0e0e0e;
}

.nk-blockquote-styled .nk-blockquote-cont {
    position: relative;
    display: block;
    padding: 60px;
    color: #fff;
    z-index: 1;
}

.nk-blockquote-styled .nk-blockquote-cont .nk-blockquote-icon {
    top: 50px;
    left: 40px;
    line-height: 100px;
    color: #fff;
}

.nk-blockquote-styled .nk-blockquote-cont em {
    margin-left: 0;
    font-size: 1.7rem;
}

.nk-blockquote-styled .nk-blockquote-author {
    padding-left: 0;
}

/*---------------------------
  Element Info Boxes
 ----------------------------*/
.nk-info-box {
    position: relative;
    display: block;
    padding: 20px;
    padding-right: 40px;
    padding-left: 70px;
    margin-bottom: 1.5rem;
    overflow: hidden;
    color: #fff;
    background-color: #0e0e0e;
}

.nk-info-box.nk-info-box-noicon {
    padding-left: 40px;
}

.nk-info-box .nk-info-box-icon {
    position: absolute;
    display: block;
    margin-top: -3px;
    margin-left: -45px;
    font-size: 2rem;
    line-height: 1;
}

.nk-info-box .nk-info-box-close-btn {
    display: block;
    float: right;
    margin-right: -20px;
    cursor: pointer;
}

/*---------------------------
  Element Carousels
 ----------------------------*/
.nk-carousel,
.nk-carousel-2 {
    position: relative;
}

.nk-carousel .flickity-slider > div,
.nk-carousel-2 .flickity-slider > div {
    display: block;
    width: 100%;
    padding: 17px 0;
}

.flickity-page-dots {
    bottom: -35px;
}

.nk-carousel[data-dots="true"],
.nk-carousel-2[data-dots="true"],
.nk-carousel-3[data-dots="true"] {
    padding-bottom: 40px;
}

.flickity-page-dots .dot {
    width: auto;
    height: auto;
    padding: 8px 4px;
    margin: 0 1px;
    background: none;
    opacity: .4;
    transition: .2s opacity;
}

.flickity-page-dots .dot:hover {
    opacity: .6;
}

.flickity-page-dots .dot::after {
    content: "";
    display: block;
    width: 60px;
    height: 2px;
    background-color: #cacaca;
}

.nk-flickity-arrow {
    position: absolute;
    top: 50%;
    width: 45px;
    height: 45px;
    padding: 8px;
    line-height: 1.4;
    color: #fff;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    opacity: .6;
    transition: .3s opacity;
    transform: translateY(-50%);
    z-index: 1;
}

.nk-flickity-arrow::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 2px solid;
    border-radius: 4px;
    transform: rotate(45deg);
    z-index: -1;
}

.nk-flickity-arrow:hover {
    opacity: 1;
}

.nk-flickity-arrow.nk-flickity-arrow-prev {
    left: 30px;
    padding-left: 15px;
}

.nk-flickity-arrow.nk-flickity-arrow-next {
    right: 30px;
    padding-right: 15px;
}

.nk-flickity-arrow .nk-icon-arrow-right,
.nk-flickity-arrow .nk-icon-arrow-left {
    width: 16px;
    margin-top: 3px;
}

.flickity-viewport.is-dragging a {
    pointer-events: none;
}

.flickity-enabled.is-draggable .flickity-viewport,
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: url("../godlike-images/cursor-drag.svg") 24 24, pointer;
}

/* Style 1 */
.nk-carousel .flickity-slider > div > div {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
}

.nk-carousel .nk-carousel-prev,
.nk-carousel .nk-carousel-next {
    position: absolute;
    top: 12%;
    width: 25%;
    min-height: 80px;
    padding: 17px 0;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 1;
}

.nk-carousel .nk-carousel-prev .nk-carousel-arrow-name,
.nk-carousel .nk-carousel-next .nk-carousel-arrow-name {
    overflow: hidden;
    font-family: "Marcellus SC", serif;
    font-size: 1.2rem;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.nk-carousel .nk-carousel-prev .nk-icon-arrow-left,
.nk-carousel .nk-carousel-prev .nk-icon-arrow-right,
.nk-carousel .nk-carousel-next .nk-icon-arrow-left,
.nk-carousel .nk-carousel-next .nk-icon-arrow-right {
    position: absolute;
    top: 28px;
}

.nk-carousel .nk-carousel-prev .nk-icon-arrow-left,
.nk-carousel .nk-carousel-next .nk-icon-arrow-left {
    left: 0;
}

.nk-carousel .nk-carousel-prev .nk-icon-arrow-right,
.nk-carousel .nk-carousel-next .nk-icon-arrow-right {
    right: 0;
}

.nk-carousel .nk-carousel-prev {
    left: 0;
    padding-right: 20px;
    padding-left: 45px;
    text-align: left;
}

.nk-carousel .nk-carousel-next {
    right: 0;
    padding-right: 45px;
    padding-left: 20px;
    text-align: right;
}

@media (max-width: 991px) {
    .nk-carousel .flickity-slider > div > div {
        width: 90%;
    }
    .nk-carousel .nk-carousel-prev,
    .nk-carousel .nk-carousel-next {
        position: relative;
        display: inline-block;
        top: auto;
        width: 49%;
    }
}

@media (max-width: 575px) {
    .nk-carousel .nk-carousel-prev,
    .nk-carousel .nk-carousel-next {
        font-size: .8rem;
    }
    .nk-carousel .nk-carousel-prev .nk-carousel-arrow-name,
    .nk-carousel .nk-carousel-next .nk-carousel-arrow-name {
        font-size: 1rem;
    }
}

/* Style 2 */
.nk-carousel-2 .flickity-slider > div {
    width: 40%;
    max-width: 600px;
    margin: 0 20px;
    opacity: .4;
    transition: .3s opacity;
}

.nk-carousel-2 .flickity-slider > div.is-selected {
    opacity: 1;
}

@media (max-width: 1199px) {
    .nk-carousel-2 .flickity-slider > div {
        width: 50%;
        opacity: .6;
    }
}

@media (max-width: 767px) {
    .nk-carousel-2 .flickity-slider > div {
        width: 70%;
        margin: 0 20px;
        opacity: 1;
    }
}

@media (max-width: 575px) {
    .nk-carousel-2 .flickity-slider > div {
        width: 90%;
        margin: 0 20px;
    }
}

.nk-carousel-2.nk-carousel-no-margin .flickity-slider > div {
    margin: 0 !important;
}

.nk-carousel-2.nk-carousel-all-visible .flickity-slider > div {
    opacity: 1 !important;
}

.nk-carousel-2.nk-carousel-x1 .flickity-slider > div {
    width: 100%;
    max-width: none;
}

@media (max-width: 1199px) {
    .nk-carousel-2.nk-carousel-x1 .flickity-slider > div {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .nk-carousel-2.nk-carousel-x1 .flickity-slider > div {
        width: 100%;
    }
}

@media (max-width: 575px) {
    .nk-carousel-2.nk-carousel-x1 .flickity-slider > div {
        width: 100%;
    }
}

.nk-carousel-2.nk-carousel-x2 .flickity-slider > div {
    width: 33.3334%;
}

@media (max-width: 1199px) {
    .nk-carousel-2.nk-carousel-x2 .flickity-slider > div {
        width: 33.3334%;
    }
}

@media (max-width: 767px) {
    .nk-carousel-2.nk-carousel-x2 .flickity-slider > div {
        width: 50%;
    }
}

@media (max-width: 575px) {
    .nk-carousel-2.nk-carousel-x2 .flickity-slider > div {
        width: 100%;
    }
}

.nk-carousel-2.nk-carousel-x3 .flickity-slider > div {
    width: 25%;
}

@media (max-width: 1199px) {
    .nk-carousel-2.nk-carousel-x3 .flickity-slider > div {
        width: 33.3334%;
    }
}

@media (max-width: 767px) {
    .nk-carousel-2.nk-carousel-x3 .flickity-slider > div {
        width: 50%;
    }
}

@media (max-width: 575px) {
    .nk-carousel-2.nk-carousel-x3 .flickity-slider > div {
        width: 100%;
    }
}

.nk-carousel-2.nk-carousel-x4 .flickity-slider > div {
    width: 20%;
}

@media (max-width: 1199px) {
    .nk-carousel-2.nk-carousel-x4 .flickity-slider > div {
        width: 25%;
    }
}

@media (max-width: 767px) {
    .nk-carousel-2.nk-carousel-x4 .flickity-slider > div {
        width: 33.3334%;
    }
}

@media (max-width: 575px) {
    .nk-carousel-2.nk-carousel-x4 .flickity-slider > div {
        width: 50%;
    }
}

.nk-carousel-2.nk-carousel-x5 .flickity-slider > div {
    width: 14.285%;
}

@media (max-width: 1199px) {
    .nk-carousel-2.nk-carousel-x5 .flickity-slider > div {
        width: 20%;
    }
}

@media (max-width: 767px) {
    .nk-carousel-2.nk-carousel-x5 .flickity-slider > div {
        width: 25%;
    }
}

@media (max-width: 575px) {
    .nk-carousel-2.nk-carousel-x5 .flickity-slider > div {
        width: 50%;
    }
}

/* Wide Slider */
.nk-carousel-3 {
    position: relative;
}

.nk-carousel-3 .flickity-slider > div {
    display: block;
    opacity: .4;
    transition: .3s opacity;
}

.nk-carousel-3 .flickity-slider > div.is-selected {
    opacity: 1;
}

.nk-carousel-3 .flickity-slider > div > div,
.nk-carousel-3 .flickity-slider > div > div img {
    width: auto;
    height: 100%;
}

.nk-carousel-3 .nk-portfolio-title,
.nk-carousel-3 .nk-portfolio-sub-title {
    display: none;
}

.nk-carousel-3 .nk-carousel-prev,
.nk-carousel-3 .nk-carousel-next {
    position: relative;
    top: 12%;
    width: 25%;
    min-height: 80px;
    padding: 17px 0;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 1;
}

.nk-carousel-3 .nk-carousel-prev .nk-carousel-arrow-name,
.nk-carousel-3 .nk-carousel-next .nk-carousel-arrow-name {
    overflow: hidden;
    font-family: "Marcellus SC", serif;
    font-size: 1.2rem;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.nk-carousel-3 .nk-carousel-prev .nk-icon-arrow-left,
.nk-carousel-3 .nk-carousel-prev .nk-icon-arrow-right,
.nk-carousel-3 .nk-carousel-next .nk-icon-arrow-left,
.nk-carousel-3 .nk-carousel-next .nk-icon-arrow-right {
    position: absolute;
    top: 28px;
}

.nk-carousel-3 .nk-carousel-prev .nk-icon-arrow-left,
.nk-carousel-3 .nk-carousel-next .nk-icon-arrow-left {
    left: 0;
}

.nk-carousel-3 .nk-carousel-prev .nk-icon-arrow-right,
.nk-carousel-3 .nk-carousel-next .nk-icon-arrow-right {
    right: 0;
}

.nk-carousel-3 .nk-carousel-prev {
    float: left;
    left: 0;
    padding-right: 20px;
    padding-left: 45px;
    text-align: left;
}

.nk-carousel-3 .nk-carousel-next {
    float: right;
    right: 0;
    padding-right: 45px;
    padding-left: 20px;
    text-align: right;
}

.nk-carousel-3 .nk-carousel-current {
    display: block;
    width: 50%;
    padding: 17px;
    margin: 0 auto;
    text-align: center;
}

.nk-carousel-3 .nk-carousel-current .nk-carousel-name a,
.nk-carousel-3 .nk-carousel-current .nk-carousel-links a {
    color: inherit;
    text-decoration: none;
}

.nk-carousel-3 .nk-carousel-current .nk-carousel-name {
    margin-bottom: .5rem;
}

.nk-carousel-3 .nk-carousel-current .nk-carousel-links {
    font-size: .9rem;
}

.nk-carousel-3 .nk-carousel-current .nk-carousel-links a {
    opacity: .6;
    transition: .3s opacity;
}

.nk-carousel-3 .nk-carousel-current .nk-carousel-links a:hover {
    opacity: .9;
}

@media (max-width: 991px) {
    .nk-carousel-3 .nk-carousel-prev,
    .nk-carousel-3 .nk-carousel-next {
        position: relative;
        top: auto;
        width: 49%;
    }
    .nk-carousel-3 .nk-carousel-current {
        width: 100%;
    }
}

@media (max-width: 575px) {
    .nk-carousel-3 .nk-carousel-prev,
    .nk-carousel-3 .nk-carousel-next {
        font-size: .8rem;
    }
    .nk-carousel-3 .nk-carousel-prev .nk-carousel-arrow-name,
    .nk-carousel-3 .nk-carousel-next .nk-carousel-arrow-name {
        font-size: 1rem;
    }
}

/*---------------------------
  Element Image Boxes
 ----------------------------*/
.nk-image-box,
.nk-image-box-1,
.nk-image-box-1-a,
.nk-image-box-2,
.nk-image-box-3,
.nk-image-box-4 {
    position: relative;
    display: block;
    overflow: hidden;
}

.nk-image-box, .nk-image-box:hover, .nk-image-box:focus, .nk-image-box:active,
.nk-image-box a,
.nk-image-box a:hover,
.nk-image-box a:focus,
.nk-image-box a:active,
.nk-image-box-1,
.nk-image-box-1:hover,
.nk-image-box-1:focus,
.nk-image-box-1:active,
.nk-image-box-1 a,
.nk-image-box-1 a:hover,
.nk-image-box-1 a:focus,
.nk-image-box-1 a:active,
.nk-image-box-1-a,
.nk-image-box-1-a:hover,
.nk-image-box-1-a:focus,
.nk-image-box-1-a:active,
.nk-image-box-1-a a,
.nk-image-box-1-a a:hover,
.nk-image-box-1-a a:focus,
.nk-image-box-1-a a:active,
.nk-image-box-2,
.nk-image-box-2:hover,
.nk-image-box-2:focus,
.nk-image-box-2:active,
.nk-image-box-2 a,
.nk-image-box-2 a:hover,
.nk-image-box-2 a:focus,
.nk-image-box-2 a:active,
.nk-image-box-3,
.nk-image-box-3:hover,
.nk-image-box-3:focus,
.nk-image-box-3:active,
.nk-image-box-3 a,
.nk-image-box-3 a:hover,
.nk-image-box-3 a:focus,
.nk-image-box-3 a:active,
.nk-image-box-4,
.nk-image-box-4:hover,
.nk-image-box-4:focus,
.nk-image-box-4:active,
.nk-image-box-4 a,
.nk-image-box-4 a:hover,
.nk-image-box-4 a:focus,
.nk-image-box-4 a:active {
    text-decoration: none;
}

.nk-image-box img,
.nk-image-box-1 img,
.nk-image-box-1-a img,
.nk-image-box-2 img,
.nk-image-box-3 img,
.nk-image-box-4 img {
    display: block;
    width: 100%;
    height: auto;
    transition: .7s transform;
    will-change: transform;
}

.nk-image-box:not(.nk-no-effect):hover img, .nk-image-box:not(.nk-no-effect).hover img,
.nk-image-box-1:not(.nk-no-effect):hover img,
.nk-image-box-1:not(.nk-no-effect).hover img,
.nk-image-box-1-a:not(.nk-no-effect):hover img,
.nk-image-box-1-a:not(.nk-no-effect).hover img,
.nk-image-box-2:not(.nk-no-effect):hover img,
.nk-image-box-2:not(.nk-no-effect).hover img,
.nk-image-box-3:not(.nk-no-effect):hover img,
.nk-image-box-3:not(.nk-no-effect).hover img,
.nk-image-box-4:not(.nk-no-effect):hover img,
.nk-image-box-4:not(.nk-no-effect).hover img {
    transform: scale(1.1);
}

.nk-image-box-overlay {
    position: absolute;
    display: flex;
    align-items: flex-start;
}

.nk-image-box-overlay > * {
    width: 100%;
}

.nk-image-box-overlay.nk-image-box-center {
    align-items: center;
}

.nk-image-box-overlay.nk-image-box-bottom {
    align-items: flex-end;
}

.nk-image-box-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.nk-image-box-bottom-info {
    padding: 20px 0;
}

.nk-image-box-bottom-info .nk-image-box-title,
.nk-image-box-bottom-info .nk-image-box-sub-title {
    margin-bottom: .5rem;
}

.nk-image-box-bottom-info .nk-image-box-title a,
.nk-image-box-bottom-info .nk-image-box-sub-title a {
    color: inherit;
    text-decoration: none;
}

.nk-image-box-bottom-info .nk-image-box-links {
    font-size: .9rem;
    color: #888;
}

.nk-image-box-bottom-info .nk-image-box-links a {
    color: inherit;
    text-decoration: none;
}

.nk-image-box-bottom-info .nk-image-box-links a:hover, .nk-image-box-bottom-info .nk-image-box-links a.hover, .nk-image-box-bottom-info .nk-image-box-links a:focus, .nk-image-box-bottom-info .nk-image-box-links a:active {
    color: #555;
}

.nk-image-box-icon {
    font-size: 40px;
}

.nk-image-box-icon-btn {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 6px 4px;
    font-size: 1.15rem;
    line-height: 50px;
    color: inherit;
    border: 2px solid;
    border-radius: 25px;
    transition: .3s color, .3s background-color, .3s border;
    will-change: color, background-color, border;
}

.nk-image-box-icon-btn .svg-inline--fa {
    vertical-align: 0;
}

.nk-image-box-icon-btn:hover, .nk-image-box-icon-btn.hover {
    color: #0e0e0e;
    background-color: #fff;
    border-color: #fff;
}

.nk-image-box-big-title {
    font-size: 3.5rem;
    font-weight: 400;
}

@media (max-width: 575px) {
    .nk-image-box-big-title {
        font-size: 2.5rem;
    }
}

/* Style 0 */
.nk-image-box .nk-image-box-overlay {
    top: 45px;
    right: 45px;
    bottom: 45px;
    left: 45px;
}

.nk-image-box .nk-image-box-title,
.nk-image-box .nk-image-box-sub-title {
    width: 100%;
    margin: 0;
}

.nk-image-box .nk-image-box-sub-title {
    display: block;
    top: 50%;
    bottom: auto;
    margin-top: 10px;
}

/* Style 1 */
.nk-image-box-1 .nk-image-box-overlay,
.nk-image-box-1-a .nk-image-box-overlay {
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    color: #fff;
    text-align: center;
    background-color: rgba(14, 14, 14, 0.75);
    opacity: 0;
    transition: .4s opacity;
    transform-style: preserve-3d;
}

.nk-image-box-1 .nk-image-box-overlay > *,
.nk-image-box-1-a .nk-image-box-overlay > * {
    padding: 30px;
}

.nk-image-box-1:hover .nk-image-box-overlay,
.nk-image-box-1.hover .nk-image-box-overlay,
.nk-image-box-1-a:hover .nk-image-box-overlay,
.nk-image-box-1-a.hover .nk-image-box-overlay {
    opacity: 1;
}

.nk-image-box-1 .nk-image-box-title,
.nk-image-box-1 .nk-image-box-sub-title,
.nk-image-box-1-a .nk-image-box-title,
.nk-image-box-1-a .nk-image-box-sub-title {
    width: 100%;
    margin: 0;
}

.nk-image-box-1 .nk-image-box-sub-title,
.nk-image-box-1-a .nk-image-box-sub-title {
    display: block;
    top: 50%;
    bottom: auto;
    margin-top: 10px;
}

.nk-image-box-1-a .nk-image-box-overlay {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: .4s opacity, .4s transform;
    transform: scale(1.1);
}

.nk-image-box-1-a:hover .nk-image-box-overlay,
.nk-image-box-1-a.hover .nk-image-box-overlay {
    opacity: 1;
    transform: scale(1);
}

/* Style 2 */
.nk-image-box-2 .nk-image-box-overlay {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #fff;
    background-color: rgba(14, 14, 14, 0.2);
    opacity: 1;
    transition: .4s opacity;
    transform-style: preserve-3d;
}

.nk-image-box-2 .nk-image-box-overlay > * {
    padding: 30px;
    transition: .4s transform;
    transform: translateY(0);
}

.nk-image-box-2:hover .nk-image-box-overlay,
.nk-image-box-2.hover .nk-image-box-overlay {
    opacity: 0;
}

.nk-image-box-2:hover .nk-image-box-overlay > *,
.nk-image-box-2.hover .nk-image-box-overlay > * {
    transform: translateY(7px);
}

.nk-image-box-2.hover:hover .nk-image-box-overlay {
    opacity: 1;
}

.nk-image-box-2.hover:hover .nk-image-box-overlay > * {
    transform: translateY(0);
}

.nk-image-box-2 .nk-image-box-title,
.nk-image-box-2 .nk-image-box-sub-title {
    width: 100%;
    margin: 0;
}

.nk-image-box-2 .nk-image-box-sub-title {
    display: block;
    top: 50%;
    bottom: auto;
    margin-top: 10px;
}

/* Style 3 */
.nk-image-box-3 .nk-image-box-overlay {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #fff;
    background: linear-gradient(to top, rgba(14, 14, 14, 0.75) 0%, rgba(14, 14, 14, 0) 40%);
    transform-style: preserve-3d;
}

.nk-image-box-3 .nk-image-box-overlay::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(14, 14, 14, 0.2);
    transition: .4s opacity;
    z-index: -1;
}

.nk-image-box-3 .nk-image-box-overlay > * {
    padding: 30px;
}

.nk-image-box-3:hover .nk-image-box-overlay::after,
.nk-image-box-3.hover .nk-image-box-overlay::after {
    opacity: 0;
}

.nk-image-box-3.hover:hover .nk-image-box-overlay::after {
    opacity: 1;
}

.nk-image-box-3 .nk-image-box-title,
.nk-image-box-3 .nk-image-box-sub-title {
    width: 100%;
    margin: 0;
}

.nk-image-box-3 .nk-image-box-sub-title {
    display: block;
    top: 50%;
    bottom: auto;
    margin-top: 10px;
}

/* Style 4 */
.nk-image-box-4 .nk-image-box-overlay {
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #fff;
    text-align: center;
    background-color: rgba(14, 14, 14, 0.75);
}

.nk-image-box-4 .nk-image-box-overlay > * {
    padding: 30px;
}

.nk-image-box-4 .nk-image-box-title,
.nk-image-box-4 .nk-image-box-sub-title {
    width: 100%;
    margin: 0;
}

.nk-image-box-4 .nk-image-box-sub-title {
    display: block;
    top: 50%;
    bottom: auto;
    margin-top: 10px;
}

/*---------------------------
  Element Team Members
 ----------------------------*/
.nk-team-member {
    position: relative;
    width: 100%;
}

.nk-team-member::after {
    content: "";
    display: table;
    width: 100%;
    clear: both;
}

.nk-team-member .nk-team-member-photo,
.nk-team-member .nk-team-member-info {
    position: relative;
    float: left;
}

.nk-team-member .nk-team-member-info {
    width: 45%;
    margin-top: 50px;
    margin-bottom: 50px;
    transition: .7s transform ease;
}

.nk-team-member .nk-team-member-info .nk-title a {
    color: inherit;
    text-decoration: none;
    transition: .2s opacity;
}

.nk-team-member .nk-team-member-info .nk-title a:hover, .nk-team-member .nk-team-member-info .nk-title a.hover {
    opacity: .8;
}

.nk-team-member .nk-team-member-info.nk-team-member-info-bottom {
    position: absolute;
    right: 0;
    bottom: 0;
}

.nk-team-member .nk-team-member-photo {
    width: 65%;
    margin-right: -11%;
    transition: .5s transform ease;
}

.nk-team-member .nk-team-member-photo img {
    width: 100%;
    height: auto;
}

.nk-team-member.hover .nk-team-member-photo {
    transform: scale(0.95);
}

.nk-team-member.hover .nk-team-member-info {
    transform: translateY(7%) scale(1.05);
}

.nk-team-member.hover .nk-team-member-info.nk-team-member-info-bottom {
    transform: translateY(-7%) scale(1.05);
}

.nk-team-member.nk-team-member-revert .nk-team-member-photo {
    left: 35%;
    margin-right: -100%;
}

.nk-team-member.nk-team-member-revert .nk-team-member-info {
    left: 0%;
    text-align: right;
}

/*---------------------------
  Element Video
 ----------------------------*/
/* FullScreen Video */
.nk-video-fullscreen {
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-color: #0e0e0e;
    background-color: rgba(14, 14, 14, 0.9);
    z-index: 1200;
}

.nk-video-fullscreen .nk-video-fullscreen-cont {
    position: absolute;
}

.nk-video-fullscreen .nk-video-fullscreen-cont > iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.nk-video-fullscreen .nk-video-fullscreen-close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 40px;
    color: #fff;
    text-align: right;
    cursor: url("../godlike-images/cursor-nav-close.svg") 24 24, pointer;
    z-index: -1;
}

/* Toggle Video Button */
.nk-video-fullscreen-toggle,
.nk-video-plain-toggle {
    display: inline-block;
    cursor: pointer;
}

.nk-video-fullscreen-toggle .nk-video-icon,
.nk-video-plain-toggle .nk-video-icon {
    text-decoration: none;
}

/* toggle button on the right/left half of nk-box blocks */
.nk-video-fullscreen-toggle-right,
.nk-video-fullscreen-toggle-left {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: 2;
}

.nk-video-fullscreen-toggle-right > .nk-video-fullscreen-toggle,
.nk-video-fullscreen-toggle-left > .nk-video-fullscreen-toggle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 991px) {
    .nk-video-fullscreen-toggle-right,
    .nk-video-fullscreen-toggle-left {
        width: 100%;
        height: 0;
        padding-top: 56.25%;
    }
}

.nk-video-fullscreen-toggle-right {
    right: 0;
}

.nk-video-fullscreen-toggle-left {
    left: 0;
}

/* Plain Video */
.nk-plain-video {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
    cursor: pointer;
    background-position: 50% 50%;
    background-size: cover;
}

.nk-plain-video > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.nk-plain-video > .nk-video-plain-toggle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
}

/*---------------------------
  Element Gif
 ----------------------------*/
.nk-gif {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    cursor: pointer;
}

.nk-gif img {
    width: 100%;
    height: auto;
}

.nk-gif .nk-gif-toggle,
.nk-gif .nk-loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
    transition: .3s opacity, .3s visibility;
    transform: translate(-50%, -50%);
}

.nk-gif .nk-loading-spinner {
    visibility: hidden;
    opacity: 0;
}

.nk-gif.nk-gif-playing .nk-gif-toggle,
.nk-gif.nk-gif-loading .nk-gif-toggle {
    visibility: hidden;
    opacity: 0;
}

.nk-gif.nk-gif-loading .nk-loading-spinner {
    visibility: visible;
    opacity: 1;
}

.nk-gif.nk-gif-hover.nk-gif-loading .nk-loading-spinner,
.nk-gif.nk-gif-hover.nk-gif-loading.hover .nk-gif-toggle, .nk-gif.nk-gif-click.nk-gif-loading .nk-loading-spinner,
.nk-gif.nk-gif-click.nk-gif-loading.hover .nk-gif-toggle {
    visibility: hidden;
    opacity: 0;
}

.nk-gif.nk-gif-hover.nk-gif-loading.hover .nk-loading-spinner,
.nk-gif.nk-gif-hover.nk-gif-loading .nk-gif-toggle, .nk-gif.nk-gif-click.nk-gif-loading.hover .nk-loading-spinner,
.nk-gif.nk-gif-click.nk-gif-loading .nk-gif-toggle {
    visibility: visible;
    opacity: 1;
}

/*---------------------------
  Element Forms
 ----------------------------*/
/* Placeholders */
::-moz-placeholder, .form-control::-moz-placeholder {
    color: inherit;
    opacity: .5;
}
:-ms-input-placeholder, .form-control:-ms-input-placeholder {
    color: inherit;
    opacity: .5;
}
::placeholder,
.form-control::placeholder {
    color: inherit;
    opacity: .5;
}

/* Ratings */
.nk-rating {
    position: relative;
    display: inline-block;
    overflow: hidden;
    font-size: 1.2rem;
}

.nk-rating input {
    display: none;
}

.nk-rating input:checked ~ label span:first-child {
    display: none;
}

.nk-rating input:checked ~ label span:last-child {
    display: block;
}

.nk-rating label {
    position: relative;
    display: block;
    float: right;
    padding-right: 2px;
    padding-left: 2px;
    margin: 0;
    clear: none;
    line-height: 1;
    cursor: default;
    cursor: pointer;
}

.nk-rating label span:first-child {
    display: none;
    display: block;
}

.nk-rating label span:last-child {
    display: none;
}

.nk-rating label::after, .nk-rating label::before {
    content: none;
    display: none;
}

.nk-rating label:hover span:first-child,
.nk-rating label:hover ~ label span:first-child {
    display: none;
}

.nk-rating label:hover span:last-child,
.nk-rating label:hover ~ label span:last-child {
    display: block;
}

/* Size Selector */
.nk-size-selector::after {
    content: "";
    display: table;
    width: 100%;
    clear: both;
}

.nk-size-selector input {
    position: absolute;
    left: -100000px;
    opacity: .01;
}

.nk-size-selector label {
    position: relative;
    display: block;
    float: left;
    min-width: 60px;
    padding: 5px 25px;
    text-align: center;
    cursor: pointer;
    background-color: #0e0e0e;
}

.nk-size-selector label::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid;
    opacity: .1;
    transition: .15s opacity;
}

.nk-size-selector label:hover::after, .nk-size-selector label.hover::after {
    opacity: .5;
    z-index: 1;
}

.nk-size-selector input:checked + label::after {
    opacity: 1;
    z-index: 1;
}

.nk-size-selector label ~ label {
    margin-left: -1px;
}

/* Color Selector */
.nk-color-selector::after {
    content: "";
    display: table;
    width: 100%;
    clear: both;
}

.nk-color-selector input {
    position: absolute;
    left: -100000px;
    opacity: .01;
}

.nk-color-selector label {
    position: relative;
    display: block;
    float: left;
    width: 22px;
    height: 22px;
    margin-top: 6px;
    margin-right: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    font-size: 0;
    color: #fff;
    text-align: center;
    cursor: pointer;
    background-color: #fff;
    border-radius: 11px;
}

.nk-color-selector label::after {
    content: "";
    position: absolute;
    display: block;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    border: 1px solid;
    border-radius: 16px;
    opacity: 0;
    transition: .3s opacity;
}

.nk-color-selector input:checked + label::after {
    opacity: 1;
    z-index: 1;
}

.nk-color-selector label ~ label {
    margin-left: 16px;
}

/* From Style 1 */
.form-control {
    padding: 12px 20px;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.2;
    color: inherit;
    background: none;
    border: none;
    border-bottom: 1px solid #404040;
    border-radius: 0;
    transition: .3s border, .3s color;
}

.form-control:focus, .form-control.focus, .form-control:active {
    color: inherit;
    background: none;
    border-bottom: 1px solid #fff;
}

.form-control:focus {
    box-shadow: none;
}

input.form-control {
    height: auto;
}

select.form-control {
    padding: 6px 20px;
}

select.form-control option {
    color: #000;
}

@-moz-document url-prefix("") {
    select.form-control option {
        color: inherit;
    }
}

select.form-control[multiple] option {
    color: inherit;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: transparent;
    opacity: .4;
}

/* Custom Checkbox */
.custom-control-label::before {
    background-color: #181818;
    border-color: #2d2d2d;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #0e0e0e;
    background-color: #fff;
    border-color: #fff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%230e0e0e' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M4 8.5L6.5 11l6-6'/></svg>");
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.15);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #fff;
}

/* Input Number */
.nk-form-control-number {
    padding: 4px;
    white-space: nowrap;
    background-color: #0e0e0e;
    border-radius: 2px;
}

.nk-form-control-number > * {
    display: inline-block;
    width: auto;
    padding: 11px 15px;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.2;
    vertical-align: middle;
    border: 1px solid #fff;
}

.nk-form-control-number .nk-form-control-number-down,
.nk-form-control-number .nk-form-control-number-up {
    min-width: 27px;
    padding: 11px 8px;
    font-family: sans-serif;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    transition: .3s background-color;
}

.nk-form-control-number .nk-form-control-number-down:hover,
.nk-form-control-number .nk-form-control-number-up:hover {
    background-color: #2d2d2d;
}

.nk-form-control-number .nk-form-control-number-down {
    border-right: none;
}

.nk-form-control-number .nk-form-control-number-down::after {
    content: "-";
}

.nk-form-control-number .nk-form-control-number-up {
    border-left: none;
}

.nk-form-control-number .nk-form-control-number-up::after {
    content: "+";
}

.nk-form-control-number input {
    text-align: center;
    -moz-appearance: textfield;
}

.nk-form-control-number input:focus, .nk-form-control-number input.focus, .nk-form-control-number input:active {
    border-color: #fff;
}

.nk-form-control-number input::-webkit-inner-spin-button,
.nk-form-control-number input::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
}

/* Validation */
div.nk-error {
    padding: 0;
    margin: 5px 0;
    font-weight: 400;
    color: #d9534f;
    background-color: transparent;
    z-index: 1;
}

.form-control.nk-error,
.nk-form-style-1 .form-control.nk-error {
    border-bottom: 1px solid #d9534f;
}

.nk-form-response-success {
    display: none;
    margin-bottom: 10px;
    color: #5cb85c;
}

.nk-form-response-error {
    display: none;
    margin-bottom: 10px;
    color: #d9534f;
}

/*---------------------------
  Element Audio Player
 ----------------------------*/
/* Plain Audio Player */
.nk-audio-plain {
    display: table;
    width: 100%;
    padding: 20px 15px;
    color: #0e0e0e;
    background-color: #fff;
}

.nk-audio-plain > div {
    display: table-cell;
}

.nk-audio-plain .nk-audio-plain-title {
    width: 99%;
}

.nk-audio-plain .nk-audio-plain-buttons {
    white-space: nowrap;
}

.nk-audio-plain .nk-audio-plain-buttons > a {
    display: inline-block;
    width: 30px;
    color: inherit;
    text-align: center;
    text-decoration: none;
}

.nk-audio-plain .nk-audio-plain-play-pause {
    display: inline-block;
    width: 38px;
    height: 38px;
    margin-right: 15px;
    margin-left: 7px;
    font-size: 1.1rem;
    line-height: 38px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-color: #2d2d2d;
    border-radius: 19px;
}

.nk-audio-plain .nk-audio-plain-play-pause .nk-audio-plain-pause {
    display: none;
}

.nk-audio-plain.nk-audio-plain-playing .nk-audio-plain-play-pause .nk-audio-plain-play {
    display: none;
}

.nk-audio-plain.nk-audio-plain-playing .nk-audio-plain-play-pause .nk-audio-plain-pause {
    display: block;
}

.nk-audio-plain .nk-audio-plain-duration {
    display: inline-block;
    width: 58px;
    margin-right: 7px;
    font-size: .9rem;
    text-align: right;
}

.nk-audio-plain .nk-audio-progress {
    position: relative;
    right: 0;
    left: 0;
    height: 1px;
    margin-top: 2px;
    margin-bottom: 2px;
    cursor: pointer;
    background-color: #ddd;
    z-index: 1;
}

.nk-audio-plain .nk-audio-progress::before {
    content: "";
    position: absolute;
    display: block;
    top: -8px;
    right: 0;
    bottom: -8px;
    left: 0;
}

.nk-audio-plain .nk-audio-progress .nk-audio-progress-current {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    background-color: #777;
    transition: .3s background-color;
    z-index: 1;
}

.nk-audio-plain .nk-audio-progress .nk-audio-progress-current::after {
    content: "";
    position: absolute;
    top: -2px;
    right: -2px;
    width: 5px;
    height: 5px;
    background-color: #2d2d2d;
    border-radius: 2.5px;
    transition: .3s transform;
    transform: scale(0);
}

.nk-audio-plain .nk-audio-progress:hover .nk-audio-progress-current,
.nk-audio-plain .nk-audio-progress.hover .nk-audio-progress-current {
    background-color: #2d2d2d;
}

.nk-audio-plain .nk-audio-progress:hover .nk-audio-progress-current::after,
.nk-audio-plain .nk-audio-progress.hover .nk-audio-progress-current::after {
    transform: scale(1);
}

.nk-audio-plain.nk-audio-plain-dark {
    color: #fff;
    background-color: #181818;
}

.nk-audio-plain.nk-audio-plain-dark .nk-audio-plain-play-pause {
    color: #0e0e0e;
    background-color: #fff;
}

.nk-audio-plain.nk-audio-plain-dark .nk-audio-progress {
    background-color: #2d2d2d;
}

.nk-audio-plain.nk-audio-plain-dark .nk-audio-progress .nk-audio-progress-current {
    background-color: #bbb;
}

.nk-audio-plain.nk-audio-plain-dark .nk-audio-progress .nk-audio-progress-current::after {
    background-color: #fff;
}

.nk-audio-plain.nk-audio-plain-dark .nk-audio-progress:hover .nk-audio-progress-current,
.nk-audio-plain.nk-audio-plain-dark .nk-audio-progress.hover .nk-audio-progress-current {
    background-color: #fff;
}

/*---------------------------
  Element Countdown
 ----------------------------*/
.nk-countdown {
    width: 100%;
    text-align: center;
}

.nk-countdown > div {
    display: inline-block;
    min-width: 140px;
    padding: 10px 0;
}

.nk-countdown > div > span {
    display: block;
    padding-bottom: 15px;
    font-family: "Marcellus SC", serif;
    font-size: 4rem;
    font-weight: 600;
}

@media (max-width: 767px) {
    .nk-countdown > div {
        min-width: 85px;
    }
    .nk-countdown > div > span {
        font-size: 2.4rem;
    }
}

/*---------------------------
  Element Text Typed
 ----------------------------*/
.nk-typed > span {
    position: absolute;
    left: -9999px;
}

.nk-typed > span:first-child,
.nk-typed.ready > span {
    position: relative;
    left: auto;
}

.typed-cursor {
    font-weight: 400;
    opacity: 1;
    -webkit-animation: .9s nk-typed-blink infinite;
            animation: .9s nk-typed-blink infinite;
}

@-webkit-keyframes nk-typed-blink {
    0% {
        opacity: 1;
    }
    44% {
        opacity: 0;
    }
    88% {
        opacity: 1;
    }
}

@keyframes nk-typed-blink {
    0% {
        opacity: 1;
    }
    44% {
        opacity: 0;
    }
    88% {
        opacity: 1;
    }
}

/*---------------------------
  Element Link Effects
 ----------------------------*/
/* All Links */
.link-effect {
    padding: 5px;
    font-family: serif;
    font-style: italic;
    background-image: linear-gradient(to bottom, transparent 0, #0e0e0e 0), linear-gradient(to top, #fff 0, #fff 1px, transparent 1.0001px, transparent 100%);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 0 1.4em, 100% 1.4em;
    transition: .3s background-size;
}

.link-effect, .link-effect:hover, .link-effect:focus, .link-effect:active {
    color: inherit;
    text-decoration: none;
}

.link-effect:hover, .link-effect.hover {
    background-position: left center;
    background-size: 100% 1.4em, 100% 1.4em;
}

/* Link Effect 1 (rotate all letters) */
.link-effect-1 > span {
    position: relative;
    display: inline-block;
    transition: transform .3s;
    transform: perspective(1000px) rotateY(0deg);
    will-change: transform;
}

.link-effect-1 > span.active {
    transform: perspective(1000px) rotateY(360deg);
}

/* Link Effect 2 and 3 (color for letters from left to right and from top to bottom) */
.link-effect-2 > span,
.link-effect-3 > span {
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: #e08821;
    vertical-align: middle;
}

.link-effect-2 .link-effect-shade,
.link-effect-3 .link-effect-shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    overflow: hidden;
    color: #e6a04e;
    white-space: nowrap;
    transition: width .3s;
    z-index: 2;
    will-change: width;
}

.link-effect-2:hover .link-effect-shade,
.link-effect-3:hover .link-effect-shade {
    width: 100%;
}

.link-effect-3 .link-effect-shade {
    width: auto;
    height: 0%;
    transition: height .3s;
    will-change: height;
}

.link-effect-3:hover .link-effect-shade {
    width: auto;
    height: 100%;
}

.link-effect-4 .link-effect-inner {
    position: relative;
    display: inline-block;
}

.link-effect-4 .link-effect-inner::after {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    right: -10px;
    left: -10px;
    border-bottom: 1px solid;
    opacity: .5;
    transition: .2s transform;
    transform: scaleX(0);
    transform-origin: 100% 0;
}

.link-effect-4 .link-effect-inner .link-effect-shade {
    opacity: 0;
}

.link-effect-4 .link-effect-inner .link-effect-l,
.link-effect-4 .link-effect-inner .link-effect-r {
    position: absolute;
    top: -50%;
    overflow: hidden;
    transition: .2s transform;
}

.link-effect-4 .link-effect-inner .link-effect-l > span,
.link-effect-4 .link-effect-inner .link-effect-r > span {
    display: inline-block;
    transform: translateY(50%);
}

.link-effect-4 .link-effect-inner .link-effect-r {
    top: 50%;
}

.link-effect-4 .link-effect-inner .link-effect-r > span {
    transform: translateY(-50%);
}

.link-effect-4:hover .link-effect-inner::after,
.link-effect-4.hover .link-effect-inner::after {
    transform: scaleX(1);
    transform-origin: 0 0;
}

.link-effect-4:hover .link-effect-inner .link-effect-l,
.link-effect-4.hover .link-effect-inner .link-effect-l {
    transform: translateX(-2px);
}

.link-effect-4:hover .link-effect-inner .link-effect-r,
.link-effect-4.hover .link-effect-inner .link-effect-r {
    transform: translateX(2px);
}

.nk-navbar .dropdown > li > .link-effect-2,
.nk-navbar .dropdown > li > .link-effect-3 {
    opacity: 1;
}

.nk-navbar .link-effect-2 > span,
.nk-navbar .link-effect-3 > span {
    color: inherit;
}

.nk-navbar .dropdown .link-effect-2,
.nk-navbar .dropdown .link-effect-3 {
    color: rgba(255, 255, 255, 0.7);
}

.nk-navbar .link-effect-2,
.nk-navbar .link-effect-3,
.nk-navbar .link-effect-2 .link-effect-shade,
.nk-navbar .link-effect-3 .link-effect-shade,
.nk-navbar .active > .link-effect-2,
.nk-navbar .active > .link-effect-3 {
    color: #fff;
}

.nk-navbar .active > .link-effect-2 .link-effect-shade {
    width: 100%;
}

.nk-navbar .active > .link-effect-3 .link-effect-shade {
    height: 100%;
}

.nk-navbar-light .dropdown .link-effect-2,
.nk-navbar-light .dropdown .link-effect-3 {
    color: rgba(45, 45, 45, 0.85);
}

.nk-navbar-light .link-effect-2,
.nk-navbar-light .link-effect-3,
.nk-navbar-light .link-effect-2 .link-effect-shade,
.nk-navbar-light .link-effect-3 .link-effect-shade,
.nk-navbar-light .active > .link-effect-2,
.nk-navbar-light .active > .link-effect-3 {
    color: #2d2d2d;
}

/*---------------------------
  Element Icons
 ----------------------------*/
.nk-icon-burger {
    position: relative;
    display: inline-block;
    width: 15px;
    height: 17px;
    transition: .3s transform;
}

.nk-icon-burger > span {
    position: absolute;
    display: block;
    top: 50%;
    width: 3px;
    height: 3px;
    margin-top: -1.5px;
    background-color: #fff;
    border-radius: 1.5px;
}

.nk-icon-burger > span::after, .nk-icon-burger > span::before {
    content: "";
    position: absolute;
    display: block;
    left: 6px;
    width: 3px;
    height: 3px;
    background-color: #fff;
    border-radius: 1.5px;
}

.nk-icon-burger > span::after {
    left: 400%;
}

.nk-icon-burger > span, .nk-icon-burger > span::before, .nk-icon-burger > span::after {
    transition: .3s transform, .3s width, .3s height, .3s border-radius;
}

.nk-navbar-light .nk-icon-burger > span {
    background-color: #0e0e0e;
}

.nk-navbar-light .nk-icon-burger > span::after, .nk-navbar-light .nk-icon-burger > span::before {
    background-color: #0e0e0e;
}

.nk-icon-burger .nk-t-1 {
    margin-top: -7.5px;
}

.nk-icon-burger .nk-t-3 {
    margin-top: 4.5px;
}

.nk-icon-burger:hover .nk-t-1, .nk-icon-burger.hover .nk-t-1,
a:hover > .nk-icon-burger .nk-t-1,
a.hover > .nk-icon-burger .nk-t-1,
span:hover > .nk-icon-burger .nk-t-1,
span.hover > .nk-icon-burger .nk-t-1 {
    -webkit-animation: nk-icon-burger-hover-1 .5s;
            animation: nk-icon-burger-hover-1 .5s;
}

.nk-icon-burger:hover .nk-t-1::before, .nk-icon-burger.hover .nk-t-1::before,
a:hover > .nk-icon-burger .nk-t-1::before,
a.hover > .nk-icon-burger .nk-t-1::before,
span:hover > .nk-icon-burger .nk-t-1::before,
span.hover > .nk-icon-burger .nk-t-1::before {
    -webkit-animation: nk-icon-burger-hover-2 .5s;
            animation: nk-icon-burger-hover-2 .5s;
}

.nk-icon-burger:hover .nk-t-1::after, .nk-icon-burger.hover .nk-t-1::after,
a:hover > .nk-icon-burger .nk-t-1::after,
a.hover > .nk-icon-burger .nk-t-1::after,
span:hover > .nk-icon-burger .nk-t-1::after,
span.hover > .nk-icon-burger .nk-t-1::after {
    -webkit-animation: nk-icon-burger-hover-3 .5s;
            animation: nk-icon-burger-hover-3 .5s;
}

.nk-icon-burger:hover .nk-t-2, .nk-icon-burger.hover .nk-t-2,
a:hover > .nk-icon-burger .nk-t-2,
a.hover > .nk-icon-burger .nk-t-2,
span:hover > .nk-icon-burger .nk-t-2,
span.hover > .nk-icon-burger .nk-t-2 {
    -webkit-animation: nk-icon-burger-hover-4 .5s;
            animation: nk-icon-burger-hover-4 .5s;
}

.nk-icon-burger:hover .nk-t-2::before, .nk-icon-burger.hover .nk-t-2::before,
a:hover > .nk-icon-burger .nk-t-2::before,
a.hover > .nk-icon-burger .nk-t-2::before,
span:hover > .nk-icon-burger .nk-t-2::before,
span.hover > .nk-icon-burger .nk-t-2::before {
    -webkit-animation: nk-icon-burger-hover-5 .5s;
            animation: nk-icon-burger-hover-5 .5s;
}

.nk-icon-burger:hover .nk-t-2::after, .nk-icon-burger.hover .nk-t-2::after,
a:hover > .nk-icon-burger .nk-t-2::after,
a.hover > .nk-icon-burger .nk-t-2::after,
span:hover > .nk-icon-burger .nk-t-2::after,
span.hover > .nk-icon-burger .nk-t-2::after {
    -webkit-animation: nk-icon-burger-hover-6 .5s;
            animation: nk-icon-burger-hover-6 .5s;
}

.nk-icon-burger:hover .nk-t-3, .nk-icon-burger.hover .nk-t-3,
a:hover > .nk-icon-burger .nk-t-3,
a.hover > .nk-icon-burger .nk-t-3,
span:hover > .nk-icon-burger .nk-t-3,
span.hover > .nk-icon-burger .nk-t-3 {
    -webkit-animation: nk-icon-burger-hover-7 .5s;
            animation: nk-icon-burger-hover-7 .5s;
}

.nk-icon-burger:hover .nk-t-3::before, .nk-icon-burger.hover .nk-t-3::before,
a:hover > .nk-icon-burger .nk-t-3::before,
a.hover > .nk-icon-burger .nk-t-3::before,
span:hover > .nk-icon-burger .nk-t-3::before,
span.hover > .nk-icon-burger .nk-t-3::before {
    -webkit-animation: nk-icon-burger-hover-8 .5s;
            animation: nk-icon-burger-hover-8 .5s;
}

.nk-icon-burger:hover .nk-t-3::after, .nk-icon-burger.hover .nk-t-3::after,
a:hover > .nk-icon-burger .nk-t-3::after,
a.hover > .nk-icon-burger .nk-t-3::after,
span:hover > .nk-icon-burger .nk-t-3::after,
span.hover > .nk-icon-burger .nk-t-3::after {
    -webkit-animation: nk-icon-burger-hover-9 .5s;
            animation: nk-icon-burger-hover-9 .5s;
}

.nk-icon-burger.active,
.active .nk-icon-burger,
a.active:hover > .nk-icon-burger,
a.active.hover > .nk-icon-burger,
span.active:hover > .nk-icon-burger,
span.active.hover > .nk-icon-burger,
a:hover > .nk-icon-burger.active,
a.hover > .nk-icon-burger.active,
span:hover > .nk-icon-burger.active,
span.hover > .nk-icon-burger.active {
    transform: rotate(135deg);
}

.nk-icon-burger.active > span,
.active .nk-icon-burger > span,
a.active:hover > .nk-icon-burger > span,
a.active.hover > .nk-icon-burger > span,
span.active:hover > .nk-icon-burger > span,
span.active.hover > .nk-icon-burger > span,
a:hover > .nk-icon-burger.active > span,
a.hover > .nk-icon-burger.active > span,
span:hover > .nk-icon-burger.active > span,
span.hover > .nk-icon-burger.active > span {
    transition: .3s width, .3s opacity, .3s transform;
}

.nk-icon-burger.active .nk-t-1, .nk-icon-burger.active .nk-t-1::after, .nk-icon-burger.active .nk-t-1::before,
.nk-icon-burger.active .nk-t-2,
.nk-icon-burger.active .nk-t-2::after,
.nk-icon-burger.active .nk-t-2::before,
.nk-icon-burger.active .nk-t-3,
.nk-icon-burger.active .nk-t-3::after,
.nk-icon-burger.active .nk-t-3::before,
.active .nk-icon-burger .nk-t-1,
.active .nk-icon-burger .nk-t-1::after,
.active .nk-icon-burger .nk-t-1::before,
.active .nk-icon-burger .nk-t-2,
.active .nk-icon-burger .nk-t-2::after,
.active .nk-icon-burger .nk-t-2::before,
.active .nk-icon-burger .nk-t-3,
.active .nk-icon-burger .nk-t-3::after,
.active .nk-icon-burger .nk-t-3::before,
a.active:hover > .nk-icon-burger .nk-t-1,
a.active:hover > .nk-icon-burger .nk-t-1::after,
a.active:hover > .nk-icon-burger .nk-t-1::before,
a.active:hover > .nk-icon-burger .nk-t-2,
a.active:hover > .nk-icon-burger .nk-t-2::after,
a.active:hover > .nk-icon-burger .nk-t-2::before,
a.active:hover > .nk-icon-burger .nk-t-3,
a.active:hover > .nk-icon-burger .nk-t-3::after,
a.active:hover > .nk-icon-burger .nk-t-3::before,
a.active.hover > .nk-icon-burger .nk-t-1,
a.active.hover > .nk-icon-burger .nk-t-1::after,
a.active.hover > .nk-icon-burger .nk-t-1::before,
a.active.hover > .nk-icon-burger .nk-t-2,
a.active.hover > .nk-icon-burger .nk-t-2::after,
a.active.hover > .nk-icon-burger .nk-t-2::before,
a.active.hover > .nk-icon-burger .nk-t-3,
a.active.hover > .nk-icon-burger .nk-t-3::after,
a.active.hover > .nk-icon-burger .nk-t-3::before,
span.active:hover > .nk-icon-burger .nk-t-1,
span.active:hover > .nk-icon-burger .nk-t-1::after,
span.active:hover > .nk-icon-burger .nk-t-1::before,
span.active:hover > .nk-icon-burger .nk-t-2,
span.active:hover > .nk-icon-burger .nk-t-2::after,
span.active:hover > .nk-icon-burger .nk-t-2::before,
span.active:hover > .nk-icon-burger .nk-t-3,
span.active:hover > .nk-icon-burger .nk-t-3::after,
span.active:hover > .nk-icon-burger .nk-t-3::before,
span.active.hover > .nk-icon-burger .nk-t-1,
span.active.hover > .nk-icon-burger .nk-t-1::after,
span.active.hover > .nk-icon-burger .nk-t-1::before,
span.active.hover > .nk-icon-burger .nk-t-2,
span.active.hover > .nk-icon-burger .nk-t-2::after,
span.active.hover > .nk-icon-burger .nk-t-2::before,
span.active.hover > .nk-icon-burger .nk-t-3,
span.active.hover > .nk-icon-burger .nk-t-3::after,
span.active.hover > .nk-icon-burger .nk-t-3::before,
a:hover > .nk-icon-burger.active .nk-t-1,
a:hover > .nk-icon-burger.active .nk-t-1::after,
a:hover > .nk-icon-burger.active .nk-t-1::before,
a:hover > .nk-icon-burger.active .nk-t-2,
a:hover > .nk-icon-burger.active .nk-t-2::after,
a:hover > .nk-icon-burger.active .nk-t-2::before,
a:hover > .nk-icon-burger.active .nk-t-3,
a:hover > .nk-icon-burger.active .nk-t-3::after,
a:hover > .nk-icon-burger.active .nk-t-3::before,
a.hover > .nk-icon-burger.active .nk-t-1,
a.hover > .nk-icon-burger.active .nk-t-1::after,
a.hover > .nk-icon-burger.active .nk-t-1::before,
a.hover > .nk-icon-burger.active .nk-t-2,
a.hover > .nk-icon-burger.active .nk-t-2::after,
a.hover > .nk-icon-burger.active .nk-t-2::before,
a.hover > .nk-icon-burger.active .nk-t-3,
a.hover > .nk-icon-burger.active .nk-t-3::after,
a.hover > .nk-icon-burger.active .nk-t-3::before,
span:hover > .nk-icon-burger.active .nk-t-1,
span:hover > .nk-icon-burger.active .nk-t-1::after,
span:hover > .nk-icon-burger.active .nk-t-1::before,
span:hover > .nk-icon-burger.active .nk-t-2,
span:hover > .nk-icon-burger.active .nk-t-2::after,
span:hover > .nk-icon-burger.active .nk-t-2::before,
span:hover > .nk-icon-burger.active .nk-t-3,
span:hover > .nk-icon-burger.active .nk-t-3::after,
span:hover > .nk-icon-burger.active .nk-t-3::before,
span.hover > .nk-icon-burger.active .nk-t-1,
span.hover > .nk-icon-burger.active .nk-t-1::after,
span.hover > .nk-icon-burger.active .nk-t-1::before,
span.hover > .nk-icon-burger.active .nk-t-2,
span.hover > .nk-icon-burger.active .nk-t-2::after,
span.hover > .nk-icon-burger.active .nk-t-2::before,
span.hover > .nk-icon-burger.active .nk-t-3,
span.hover > .nk-icon-burger.active .nk-t-3::after,
span.hover > .nk-icon-burger.active .nk-t-3::before {
    border-radius: 0;
    -webkit-animation: none;
            animation: none;
}

.nk-icon-burger.active .nk-t-1,
.active .nk-icon-burger .nk-t-1,
a.active:hover > .nk-icon-burger .nk-t-1,
a.active.hover > .nk-icon-burger .nk-t-1,
span.active:hover > .nk-icon-burger .nk-t-1,
span.active.hover > .nk-icon-burger .nk-t-1,
a:hover > .nk-icon-burger.active .nk-t-1,
a.hover > .nk-icon-burger.active .nk-t-1,
span:hover > .nk-icon-burger.active .nk-t-1,
span.hover > .nk-icon-burger.active .nk-t-1 {
    height: 2px;
    transform: translateX(3px) translateY(6px);
}

.nk-icon-burger.active .nk-t-1::before,
.active .nk-icon-burger .nk-t-1::before,
a.active:hover > .nk-icon-burger .nk-t-1::before,
a.active.hover > .nk-icon-burger .nk-t-1::before,
span.active:hover > .nk-icon-burger .nk-t-1::before,
span.active.hover > .nk-icon-burger .nk-t-1::before,
a:hover > .nk-icon-burger.active .nk-t-1::before,
a.hover > .nk-icon-burger.active .nk-t-1::before,
span:hover > .nk-icon-burger.active .nk-t-1::before,
span.hover > .nk-icon-burger.active .nk-t-1::before {
    width: 2px;
    height: 4px;
    transform: translateX(-3px) translateY(-7px);
}

.nk-icon-burger.active .nk-t-1::after,
.active .nk-icon-burger .nk-t-1::after,
a.active:hover > .nk-icon-burger .nk-t-1::after,
a.active.hover > .nk-icon-burger .nk-t-1::after,
span.active:hover > .nk-icon-burger .nk-t-1::after,
span.active.hover > .nk-icon-burger .nk-t-1::after,
a:hover > .nk-icon-burger.active .nk-t-1::after,
a.hover > .nk-icon-burger.active .nk-t-1::after,
span:hover > .nk-icon-burger.active .nk-t-1::after,
span.hover > .nk-icon-burger.active .nk-t-1::after {
    width: 2px;
    transform: translateX(-9px) translateY(-3px);
}

.nk-icon-burger.active .nk-t-2,
.active .nk-icon-burger .nk-t-2,
a.active:hover > .nk-icon-burger .nk-t-2,
a.active.hover > .nk-icon-burger .nk-t-2,
span.active:hover > .nk-icon-burger .nk-t-2,
span.active.hover > .nk-icon-burger .nk-t-2,
a:hover > .nk-icon-burger.active .nk-t-2,
a.hover > .nk-icon-burger.active .nk-t-2,
span:hover > .nk-icon-burger.active .nk-t-2,
span.hover > .nk-icon-burger.active .nk-t-2 {
    width: 4px;
    height: 2px;
    transform: translateX(-1px);
}

.nk-icon-burger.active .nk-t-2::before,
.active .nk-icon-burger .nk-t-2::before,
a.active:hover > .nk-icon-burger .nk-t-2::before,
a.active.hover > .nk-icon-burger .nk-t-2::before,
span.active:hover > .nk-icon-burger .nk-t-2::before,
span.active.hover > .nk-icon-burger .nk-t-2::before,
a:hover > .nk-icon-burger.active .nk-t-2::before,
a.hover > .nk-icon-burger.active .nk-t-2::before,
span:hover > .nk-icon-burger.active .nk-t-2::before,
span.hover > .nk-icon-burger.active .nk-t-2::before {
    width: 2px;
    height: 2px;
    transform: translateX(1px);
}

.nk-icon-burger.active .nk-t-2::after,
.active .nk-icon-burger .nk-t-2::after,
a.active:hover > .nk-icon-burger .nk-t-2::after,
a.active.hover > .nk-icon-burger .nk-t-2::after,
span.active:hover > .nk-icon-burger .nk-t-2::after,
span.active.hover > .nk-icon-burger .nk-t-2::after,
a:hover > .nk-icon-burger.active .nk-t-2::after,
a.hover > .nk-icon-burger.active .nk-t-2::after,
span:hover > .nk-icon-burger.active .nk-t-2::after,
span.hover > .nk-icon-burger.active .nk-t-2::after {
    width: 4px;
    height: 2px;
    transform: translateX(-4px);
}

.nk-icon-burger.active .nk-t-3,
.active .nk-icon-burger .nk-t-3,
a.active:hover > .nk-icon-burger .nk-t-3,
a.active.hover > .nk-icon-burger .nk-t-3,
span.active:hover > .nk-icon-burger .nk-t-3,
span.active.hover > .nk-icon-burger .nk-t-3,
a:hover > .nk-icon-burger.active .nk-t-3,
a.hover > .nk-icon-burger.active .nk-t-3,
span:hover > .nk-icon-burger.active .nk-t-3,
span.hover > .nk-icon-burger.active .nk-t-3 {
    width: 2px;
    transform: translateX(6px) translateY(-4px);
}

.nk-icon-burger.active .nk-t-3::before,
.active .nk-icon-burger .nk-t-3::before,
a.active:hover > .nk-icon-burger .nk-t-3::before,
a.active.hover > .nk-icon-burger .nk-t-3::before,
span.active:hover > .nk-icon-burger .nk-t-3::before,
span.active.hover > .nk-icon-burger .nk-t-3::before,
a:hover > .nk-icon-burger.active .nk-t-3::before,
a.hover > .nk-icon-burger.active .nk-t-3::before,
span:hover > .nk-icon-burger.active .nk-t-3::before,
span.hover > .nk-icon-burger.active .nk-t-3::before {
    width: 2px;
    height: 4px;
    transform: translateX(-6px) translateY(3px);
}

.nk-icon-burger.active .nk-t-3::after,
.active .nk-icon-burger .nk-t-3::after,
a.active:hover > .nk-icon-burger .nk-t-3::after,
a.active.hover > .nk-icon-burger .nk-t-3::after,
span.active:hover > .nk-icon-burger .nk-t-3::after,
span.active.hover > .nk-icon-burger .nk-t-3::after,
a:hover > .nk-icon-burger.active .nk-t-3::after,
a.hover > .nk-icon-burger.active .nk-t-3::after,
span:hover > .nk-icon-burger.active .nk-t-3::after,
span.hover > .nk-icon-burger.active .nk-t-3::after {
    height: 2px;
    transform: translateX(-6px) translateY(-2px);
}

@-webkit-keyframes nk-icon-burger-hover-1 {
    50% {
        transform: translateX(-1.5px) translateY(-1.5px);
    }
}

@keyframes nk-icon-burger-hover-1 {
    50% {
        transform: translateX(-1.5px) translateY(-1.5px);
    }
}

@-webkit-keyframes nk-icon-burger-hover-2 {
    50% {
        transform: translateX(1.5px);
    }
}

@keyframes nk-icon-burger-hover-2 {
    50% {
        transform: translateX(1.5px);
    }
}

@-webkit-keyframes nk-icon-burger-hover-3 {
    50% {
        transform: translateX(3px);
    }
}

@keyframes nk-icon-burger-hover-3 {
    50% {
        transform: translateX(3px);
    }
}

@-webkit-keyframes nk-icon-burger-hover-4 {
    50% {
        transform: translateX(-1.5px);
    }
}

@keyframes nk-icon-burger-hover-4 {
    50% {
        transform: translateX(-1.5px);
    }
}

@-webkit-keyframes nk-icon-burger-hover-5 {
    50% {
        transform: translateX(1.5px);
    }
}

@keyframes nk-icon-burger-hover-5 {
    50% {
        transform: translateX(1.5px);
    }
}

@-webkit-keyframes nk-icon-burger-hover-6 {
    50% {
        transform: translateX(3px);
    }
}

@keyframes nk-icon-burger-hover-6 {
    50% {
        transform: translateX(3px);
    }
}

@-webkit-keyframes nk-icon-burger-hover-7 {
    50% {
        transform: translateX(-1.5px) translateY(1.5px);
    }
}

@keyframes nk-icon-burger-hover-7 {
    50% {
        transform: translateX(-1.5px) translateY(1.5px);
    }
}

@-webkit-keyframes nk-icon-burger-hover-8 {
    50% {
        transform: translateX(1.5px);
    }
}

@keyframes nk-icon-burger-hover-8 {
    50% {
        transform: translateX(1.5px);
    }
}

@-webkit-keyframes nk-icon-burger-hover-9 {
    50% {
        transform: translateX(3px);
    }
}

@keyframes nk-icon-burger-hover-9 {
    50% {
        transform: translateX(3px);
    }
}

.nk-icon-close {
    position: relative;
    display: inline-block;
    width: 21px;
    height: 32px;
    line-height: 32px;
}

.nk-icon-close::before, .nk-icon-close::after {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    left: 2px;
    width: 16px;
    height: 0;
    margin-top: -1px;
    border-bottom: 2px solid;
    transition: .3s width, .3s opacity, .3s transform;
    transform-origin: 50% 50%;
    will-change: width, opacity, transform;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transform-style: preserve-3d;
}

.nk-icon-close::before {
    transform: rotate(45deg);
}

.nk-icon-close::after {
    transform: rotate(-45deg);
}

.nk-icon-search {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 24px;
}

.nk-icon-search::before, .nk-icon-search::after {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    transition: .3s;
    transform: rotate(-45deg);
    transform-style: preserve-3d;
}

.nk-icon-search::before {
    width: 12px;
    height: 12px;
    margin-top: -7px;
    margin-left: 3px;
    border: 2px solid;
    border-radius: 6px;
}

.nk-icon-search::after {
    width: 0;
    height: 6px;
    margin-top: 3px;
    margin-left: 12px;
    border-right: 2px solid;
    transform-origin: 1px 0;
}

.nk-icon-search:hover::before,
a:hover > .nk-icon-search::before {
    transform: rotate(-45deg) scale(1.2);
}

.nk-icon-search:hover::after,
a:hover > .nk-icon-search::after {
    transform: rotate(-40deg) translateX(-1px) translateY(1px);
}

.nk-icon-search.active::before, .nk-icon-search.active::after,
.active .nk-icon-search::before,
.active .nk-icon-search::after,
a.active:hover > .nk-icon-search::before,
a.active:hover > .nk-icon-search::after {
    width: 1px;
    height: 16px;
}

.nk-icon-search.active::before,
.active .nk-icon-search::before,
a.active:hover > .nk-icon-search::before {
    width: 2px;
    height: 17px;
    margin-top: -8px;
    margin-left: 9px;
    border-width: 1px;
    transform: rotate(45deg);
}

.nk-icon-search.active::after,
.active .nk-icon-search::after,
a.active:hover > .nk-icon-search::after {
    width: 0;
    margin-top: -5px;
    margin-left: 3px;
    transform: rotate(-45deg);
}

.nk-icon-arrow-right, .nk-icon-arrow-left {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 12px;
    margin-bottom: 12px;
}

.nk-icon-arrow-right::after, .nk-icon-arrow-right::before, .nk-icon-arrow-left::after, .nk-icon-arrow-left::before {
    content: "";
    position: absolute;
    display: block;
    bottom: -1px;
    left: 0;
    width: 50%;
    height: 2px;
    background-color: currentColor;
    transition: .2s transform;
    transform-origin: .93px 1px;
    will-change: transform;
}

.nk-icon-arrow-right::after, .nk-icon-arrow-left::after {
    transform: rotate(40deg);
}

.nk-icon-arrow-right::before, .nk-icon-arrow-left::before {
    transform: rotate(-40deg);
}

.nk-icon-arrow-right:hover::after, .nk-icon-arrow-right.hover::after,
:hover > .nk-icon-arrow-right::after,
.hover > .nk-icon-arrow-right::after, .nk-icon-arrow-left:hover::after, .nk-icon-arrow-left.hover::after,
:hover > .nk-icon-arrow-left::after,
.hover > .nk-icon-arrow-left::after {
    transform: rotate(48deg);
}

.nk-icon-arrow-right:hover::before, .nk-icon-arrow-right.hover::before,
:hover > .nk-icon-arrow-right::before,
.hover > .nk-icon-arrow-right::before, .nk-icon-arrow-left:hover::before, .nk-icon-arrow-left.hover::before,
:hover > .nk-icon-arrow-left::before,
.hover > .nk-icon-arrow-left::before {
    transform: rotate(-48deg);
}

.nk-icon-arrow-right::after, .nk-icon-arrow-right::before {
    right: 0;
    left: auto;
    transform-origin: calc(100% - .93px) 1px;
}

.nk-video-icon,
.nk-video-icon-light,
.nk-gif-icon,
.nk-gif-icon-light {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    color: #fff;
    z-index: 0;
}

.nk-video-icon > *,
.nk-video-icon-light > *,
.nk-gif-icon > *,
.nk-gif-icon-light > * {
    display: block;
    margin-left: -2px;
    font-size: 1.3rem;
    line-height: 51px;
    color: inherit;
    text-align: center;
    transition: .3s transform ease-in-out;
}

.nk-video-icon::after, .nk-video-icon::before,
.nk-video-icon-light::after,
.nk-video-icon-light::before,
.nk-gif-icon::after,
.nk-gif-icon::before,
.nk-gif-icon-light::after,
.nk-gif-icon-light::before {
    content: "";
    position: absolute;
    display: block;
    transform-style: preserve-3d;
}

.nk-video-icon::after,
.nk-video-icon-light::after,
.nk-gif-icon::after,
.nk-gif-icon-light::after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 2px solid #fff;
    border-radius: 4px;
    transition: .3s transform ease-in-out;
    transform: rotate(45deg);
}

.nk-video-icon:hover > *, .nk-video-icon.hover > *,
.nk-video-icon-light:hover > *,
.nk-video-icon-light.hover > *,
.nk-gif-icon:hover > *,
.nk-gif-icon.hover > *,
.nk-gif-icon-light:hover > *,
.nk-gif-icon-light.hover > * {
    transform: scale(1.1);
}

.nk-video-icon:hover::after, .nk-video-icon.hover::after,
.nk-video-icon-light:hover::after,
.nk-video-icon-light.hover::after,
.nk-gif-icon:hover::after,
.nk-gif-icon.hover::after,
.nk-gif-icon-light:hover::after,
.nk-gif-icon-light.hover::after {
    transition: .3s transform ease-in-out;
    transform: rotate(45deg) scale(0.93);
}

.nk-loading-spinner,
.nk-loading-spinner-light {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px #0e0e0e;
}

.nk-loading-spinner i,
.nk-loading-spinner-light i {
    position: absolute;
    display: block;
    width: 28px;
    height: 28px;
    overflow: hidden;
    transform-origin: 40px 40px;
    -webkit-animation: nk-loading-spinner .7s infinite linear;
            animation: nk-loading-spinner .7s infinite linear;
}

.nk-loading-spinner i::after,
.nk-loading-spinner-light i::after {
    content: "";
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.8);
}

.nk-loading-spinner-light {
    box-shadow: inset 0 0 0 2px #fff;
}

.nk-loading-spinner-light i::after {
    box-shadow: inset 0 0 0 2px rgba(14, 14, 14, 0.8);
}

@-webkit-keyframes nk-loading-spinner {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes nk-loading-spinner {
    100% {
        transform: rotate(360deg);
    }
}

.nk-icon-toggle {
    display: inline-block;
    width: 20px;
    text-align: center;
    transition: .3s transform;
    transform-style: preserve-3d;
}

.nk-icon-toggle .nk-icon-toggle-front {
    display: inline-block;
    width: 100%;
    transform: rotateY(0deg);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.nk-icon-toggle .nk-icon-toggle-back {
    position: absolute;
    left: 0;
    width: 100%;
    transform: rotateY(-180deg);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

a.active > .nk-icon-toggle,
span.active > .nk-icon-toggle, .nk-icon-toggle.active {
    transform: rotateY(180deg);
}

/*---------------------------
  Element Isotope
 ----------------------------*/
.nk-isotope::after {
    content: "";
    display: table;
    clear: both;
}

.nk-isotope.nk-isotope-gap .nk-isotope-item {
    padding: 15px;
}

.nk-isotope.nk-isotope-gap-small .nk-isotope-item {
    padding: 10px;
}

.nk-isotope .nk-isotope-item {
    display: block;
    float: left;
    width: 25%;
}

.nk-isotope .nk-isotope-item.nk-isotope-item-x2 {
    width: 50%;
}

.nk-isotope .nk-isotope-item.nk-isotope-item-x3 {
    width: 75%;
}

.nk-isotope .nk-isotope-item.nk-isotope-item-x4 {
    width: 100%;
}

.nk-isotope.nk-isotope-3-cols .nk-isotope-item {
    width: 33.332%;
}

.nk-isotope.nk-isotope-3-cols .nk-isotope-item.nk-isotope-item-x2 {
    width: 66.665%;
}

.nk-isotope.nk-isotope-3-cols .nk-isotope-item.nk-isotope-item-x3 {
    width: 100%;
}

.nk-isotope.nk-isotope-2-cols .nk-isotope-item {
    width: 50%;
}

.nk-isotope.nk-isotope-2-cols .nk-isotope-item.nk-isotope-item-x2, .nk-isotope.nk-isotope-2-cols .nk-isotope-item.nk-isotope-item-x3 {
    width: 100%;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .nk-isotope .nk-isotope-item,
    .nk-isotope.nk-isotope-3-cols .nk-isotope-item {
        width: 33.3334%;
    }
    .nk-isotope .nk-isotope-item.nk-isotope-item-x2, .nk-isotope .nk-isotope-item.nk-isotope-item-x3,
    .nk-isotope.nk-isotope-3-cols .nk-isotope-item.nk-isotope-item-x2,
    .nk-isotope.nk-isotope-3-cols .nk-isotope-item.nk-isotope-item-x3 {
        width: 100%;
    }
}

@media (min-width: 767px) and (max-width: 991px) {
    .nk-isotope .nk-isotope-item,
    .nk-isotope.nk-isotope-3-cols .nk-isotope-item,
    .nk-isotope.nk-isotope-2-cols .nk-isotope-item {
        width: 50%;
    }
    .nk-isotope .nk-isotope-item.nk-isotope-item-x2, .nk-isotope .nk-isotope-item.nk-isotope-item-x3,
    .nk-isotope.nk-isotope-3-cols .nk-isotope-item.nk-isotope-item-x2,
    .nk-isotope.nk-isotope-3-cols .nk-isotope-item.nk-isotope-item-x3,
    .nk-isotope.nk-isotope-2-cols .nk-isotope-item.nk-isotope-item-x2,
    .nk-isotope.nk-isotope-2-cols .nk-isotope-item.nk-isotope-item-x3 {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .nk-isotope .nk-isotope-item,
    .nk-isotope .nk-isotope-item.nk-isotope-item-x2,
    .nk-isotope .nk-isotope-item.nk-isotope-item-x3,
    .nk-isotope.nk-isotope-3-cols .nk-isotope-item,
    .nk-isotope.nk-isotope-2-cols .nk-isotope-item {
        width: 100%;
    }
}

.nk-isotope-filter {
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
    list-style: none;
}

.nk-isotope-filter > li {
    display: inline-block;
    padding: 5px 10px;
    cursor: pointer;
    transition: .3s color;
}

.nk-isotope-filter > li::after {
    content: "";
    position: absolute;
    display: block;
    right: 10px;
    bottom: 5px;
    left: 10px;
    border-bottom: 1px solid;
    opacity: 0;
    transition: .3s opacity;
}

.nk-isotope-filter > li.active {
    position: relative;
    color: #e08821;
}

.nk-isotope-filter > li.active::after {
    opacity: .5;
}

.nk-isotope-filter > li:hover {
    color: #e08821;
}

/*---------------------------
  Element Action Like
 ----------------------------*/
/* Like / Dislike Animation */
.nk-like-animation,
.nk-dislike-animation {
    position: fixed;
    display: none;
    top: 50%;
    right: 0;
    left: 0;
    height: 300px;
    margin-top: -150px;
    font-size: 7rem;
    font-weight: 600;
    line-height: 300px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: default;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: #5cb85c;
    z-index: 2000;
}

@media (max-width: 767px) {
    .nk-like-animation,
    .nk-dislike-animation {
        font-size: 5rem;
    }
}

@media (max-width: 575px) {
    .nk-like-animation,
    .nk-dislike-animation {
        font-size: 3rem;
    }
}

@media (max-height: 500px) {
    .nk-like-animation,
    .nk-dislike-animation {
        height: 200px;
        margin-top: -100px;
        line-height: 200px;
    }
}

.nk-dislike-animation {
    background-color: #d9534f;
}

/* Heart */
.nk-action-heart {
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    transition: .3s opacity;
}

.nk-action-heart, .nk-action-heart:focus, .nk-action-heart:hover, .nk-action-heart:active {
    color: inherit;
    text-decoration: none;
}

.nk-action-heart > span {
    display: inline-block;
    margin-right: 5px;
    font-size: 1.2em;
    vertical-align: middle;
}

.nk-action-heart > .num {
    font-size: 1em;
}

.nk-action-heart .liked-icon {
    display: none;
}

.nk-action-heart.liked .liked-icon {
    display: inline-block;
}

.nk-action-heart.liked .like-icon {
    display: none;
}

/* Like / Dislike */
.nk-action-like {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    margin-left: 13px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.nk-action-like, .nk-action-like:focus, .nk-action-like:hover, .nk-action-like:active {
    color: inherit;
    text-decoration: none;
}

.nk-action-like > span,
.nk-action-like > svg {
    font-size: 1em;
}

.nk-action-like > .num {
    font-size: .9em;
}

.nk-action-like > .like-icon,
.nk-action-like > .dislike-icon {
    padding: 5px;
    cursor: pointer;
    opacity: .8;
    transition: .3s opacity;
}

.nk-action-like > .like-icon:hover, .nk-action-like > .like-icon.hover,
.nk-action-like > .dislike-icon:hover,
.nk-action-like > .dislike-icon.hover {
    opacity: 1;
}

.nk-action-like > svg.like-icon,
.nk-action-like > svg.dislike-icon {
    padding: 0;
    margin: 10px;
    font-size: .9em;
}

.nk-action-like.disliked > .like-icon,
.nk-action-like.liked > .dislike-icon {
    opacity: .4;
}

.nk-action-like.disliked > .dislike-icon,
.nk-action-like.liked > .like-icon {
    opacity: 1;
}

/*------------------------------------------------------------------

  Blog

 -------------------------------------------------------------------*/
.nk-blog-post {
    margin-bottom: 65px;
}

.nk-blog-isotope .nk-blog-post {
    margin-bottom: 40px;
}

.nk-blog-post-single {
    margin-bottom: 0;
}

.nk-post-title {
    margin-bottom: 20px;
}

.nk-post-title > a {
    color: inherit;
    text-decoration: none;
    transition: .3s opacity;
}

.nk-post-title > a:hover, .nk-post-title > a.hover {
    opacity: .8;
}

.nk-post-thumb {
    position: relative;
}

.nk-post-thumb > a::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: inset 0 0 150px 30px rgba(0, 0, 0, 0.6);
}

.nk-post-content {
    position: relative;
}

.nk-post-category {
    position: absolute;
    top: 0;
    left: 0;
    margin: 50px;
    z-index: 1;
}

.nk-post-category > span,
.nk-post-category > a {
    position: relative;
    display: inline-block;
    padding: 3px 20px;
    margin-right: 3px;
    margin-bottom: 9px;
    margin-left: 3px;
    font-size: .9rem;
    color: inherit;
    text-decoration: none;
}

.nk-post-category > span::after,
.nk-post-category > a::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid;
    opacity: .5;
}

.nk-post-type {
    position: absolute;
    top: 48px;
    left: 50px;
    padding: 0;
    font-size: 1.2rem;
    z-index: 1;
}

.nk-post-type + .nk-post-category {
    left: 35px;
}

.nk-post-text {
    padding: 70px;
    background-color: #0e0e0e;
}

@media (max-width: 767px) {
    .nk-post-text {
        padding: 30px;
    }
}

.nk-post-text::after, .nk-post-text::before {
    content: "";
    display: table;
    clear: both;
}

.nk-post-text .pull-right,
.nk-post-text .pull-left {
    margin: 15px;
}

.nk-post-text .pull-right {
    margin-right: 0;
}

.nk-post-text .pull-left {
    margin-left: 0;
}

.nk-post-text > p:last-child {
    margin-bottom: 0;
}

.nk-post-text .nk-post-tags {
    margin-top: 1.7rem;
}

.nk-post-author {
    width: 100%;
    padding: 70px;
}

.nk-post-author::after {
    content: "";
    display: table;
    width: 100%;
    clear: both;
}

@media (max-width: 767px) {
    .nk-post-author {
        padding: 30px;
    }
}

.nk-post-author .nk-post-author-photo {
    float: left;
}

.nk-post-author .nk-post-author-photo img {
    width: 100px;
    height: auto;
    border-radius: 50px;
}

.nk-post-author .nk-post-author-photo ~ * {
    margin-left: 125px;
}

.nk-post-author .nk-post-author-name {
    text-decoration: none;
}

.nk-post-author .nk-post-author-name > a {
    color: inherit;
    text-decoration: none;
    transition: .3s opacity;
}

.nk-post-author .nk-post-author-name > a:hover, .nk-post-author .nk-post-author-name > a.hover {
    opacity: .8;
}

.nk-post-author .nk-widget-social {
    margin-top: 20px;
    text-align: left;
}

/* Blog List */
.nk-blog-list .nk-blog-post::after {
    content: "";
    display: table;
    width: 100%;
    clear: both;
}

.nk-blog-list .nk-post-thumb {
    position: relative;
    display: block;
    float: left;
    width: 65%;
    margin-right: -11%;
    transition: .5s transform ease;
    transform: scale(1);
}

.nk-blog-list .nk-post-thumb::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    box-shadow: inset 0 0 0 0 transparent;
    transition: .5s box-shadow ease;
}

.nk-blog-list .nk-blog-post.hover .nk-post-thumb {
    transform: scale(0.95);
}

.nk-blog-list .nk-blog-post.hover .nk-post-thumb::after {
    box-shadow: inset 0 0 100px 0 black;
}

.nk-blog-list .nk-post-content {
    display: block;
    float: left;
    width: 45%;
    margin-top: 50px;
    margin-bottom: 50px;
    transition: .7s transform ease;
    transform: translateY(0) scale(1);
}

.nk-blog-list .nk-blog-post.hover .nk-post-content {
    transform: translateY(7%) scale(1.05);
}

.nk-blog-list .nk-blog-post:nth-child(2n) .nk-post-category {
    right: 0;
    left: auto;
}

.nk-blog-list .nk-blog-post:nth-child(2n) .nk-post-type {
    right: 50px;
    left: auto;
}

.nk-blog-list .nk-blog-post:nth-child(2n) .nk-post-type + .nk-post-category {
    right: 35px;
}

.nk-blog-list .nk-blog-post:nth-child(2n) .nk-post-thumb {
    margin-right: 0;
    margin-right: -100%;
    margin-left: 35%;
}

.nk-blog-list .nk-blog-post:nth-child(2n) .nk-post-content {
    text-align: right;
}

@media (max-width: 575px) {
    .nk-blog-list .nk-blog-post .nk-post-thumb,
    .nk-blog-list .nk-blog-post .nk-post-content,
    .nk-blog-list .nk-blog-post:nth-child(2n) .nk-post-thumb,
    .nk-blog-list .nk-blog-post:nth-child(2n) .nk-post-content {
        float: none;
        width: 100%;
        margin: 0;
    }
    .nk-blog-list .nk-blog-post .nk-post-thumb,
    .nk-blog-list .nk-blog-post:nth-child(2n) .nk-post-thumb {
        margin-bottom: 20px;
    }
    .nk-blog-list .nk-blog-post .nk-post-content,
    .nk-blog-list .nk-blog-post:nth-child(2n) .nk-post-content {
        margin: 0;
        text-align: center;
    }
    .nk-blog-list .nk-blog-post .nk-post-type,
    .nk-blog-list .nk-blog-post:nth-child(2n) .nk-post-type {
        right: auto;
        left: 30px;
    }
    .nk-blog-list .nk-blog-post .nk-post-category,
    .nk-blog-list .nk-blog-post:nth-child(2n) .nk-post-category {
        right: auto;
        left: 15px;
    }
}

/* Blog Classic */
.nk-blog-list-classic .nk-post-thumb,
.nk-blog-grid-2 .nk-post-thumb,
.nk-blog-grid-3 .nk-post-thumb {
    position: relative;
    display: block;
}

.nk-blog-list-classic .nk-post-thumb::after,
.nk-blog-grid-2 .nk-post-thumb::after,
.nk-blog-grid-3 .nk-post-thumb::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    box-shadow: inset 0 0 0 0 transparent;
    transition: .5s box-shadow ease;
}

.nk-blog-list-classic .nk-post-thumb:hover::after,
.nk-blog-grid-2 .nk-post-thumb:hover::after,
.nk-blog-grid-3 .nk-post-thumb:hover::after {
    box-shadow: inset 0 0 100px 0 black;
}

.nk-blog-list-classic .nk-post-content,
.nk-blog-grid-2 .nk-post-content,
.nk-blog-grid-3 .nk-post-content {
    padding: 70px;
    background-color: #0e0e0e;
}

@media (max-width: 767px) {
    .nk-blog-list-classic .nk-post-content,
    .nk-blog-grid-2 .nk-post-content,
    .nk-blog-grid-3 .nk-post-content {
        padding: 30px;
    }
}

.nk-blog-list-classic .nk-post-title,
.nk-blog-grid-2 .nk-post-title,
.nk-blog-grid-3 .nk-post-title {
    margin-bottom: 10px;
}

.nk-blog-list-classic .nk-post-date,
.nk-blog-grid-2 .nk-post-date,
.nk-blog-grid-3 .nk-post-date {
    margin-bottom: 1.7rem;
    font-size: .9rem;
    opacity: .6;
}

.nk-blog-list-classic .nk-post-text,
.nk-blog-grid-2 .nk-post-text,
.nk-blog-grid-3 .nk-post-text {
    padding: 0;
    margin-bottom: 1.7rem;
    background-color: transparent;
}

@media (max-width: 767px) {
    .nk-blog-list-classic .nk-post-text,
    .nk-blog-grid-2 .nk-post-text,
    .nk-blog-grid-3 .nk-post-text {
        padding: 0;
    }
}

/* Blog Grid */
.nk-blog-grid-2::after,
.nk-blog-grid-3::after {
    content: "";
    display: block;
    clear: both;
}

.nk-blog-grid-2 .nk-post-type,
.nk-blog-grid-3 .nk-post-type {
    top: 38px;
    left: 40px;
}

.nk-blog-grid-2 .nk-post-category,
.nk-blog-grid-3 .nk-post-category {
    margin: 40px;
}

.nk-blog-grid-2 .nk-post-content,
.nk-blog-grid-3 .nk-post-content {
    padding: 40px;
}

.nk-blog-grid-2 .nk-post-text,
.nk-blog-grid-3 .nk-post-text {
    margin-bottom: 0;
}

.nk-blog-grid-2 .nk-blog-post {
    float: left;
    width: 50%;
    padding-right: 30px;
}

.nk-blog-grid-2 .nk-blog-post:nth-child(2n) {
    padding-right: 0;
    padding-left: 30px;
}

.nk-blog-grid-2 .nk-blog-post:nth-child(2n + 1) {
    clear: left;
}

@media (max-width: 767px) {
    .nk-blog-grid-2 .nk-blog-post {
        width: 100%;
        padding-right: 0;
    }
    .nk-blog-grid-2 .nk-blog-post:nth-child(2n) {
        padding-left: 0;
    }
}

.nk-blog-grid-3 .nk-blog-post {
    float: left;
    width: 33.33%;
    padding-right: 40px;
}

.nk-blog-grid-3 .nk-blog-post:nth-child(3n) {
    padding-right: 0;
    padding-left: 40px;
}

.nk-blog-grid-3 .nk-blog-post:nth-child(3n + 2) {
    padding-right: 20px;
    padding-left: 20px;
}

.nk-blog-grid-3 .nk-blog-post:nth-child(3n + 1) {
    clear: left;
}

@media (max-width: 991px) {
    .nk-blog-grid-3 .nk-blog-post {
        width: 50%;
        padding-right: 30px;
    }
    .nk-blog-grid-3 .nk-blog-post:nth-child(1n) {
        padding-right: 30px;
        padding-left: 0;
    }
    .nk-blog-grid-3 .nk-blog-post:nth-child(2n) {
        padding-right: 0;
        padding-left: 30px;
    }
    .nk-blog-grid-3 .nk-blog-post:nth-child(3n + 1) {
        clear: none;
    }
    .nk-blog-grid-3 .nk-blog-post:nth-child(2n + 1) {
        clear: left;
    }
}

@media (max-width: 767px) {
    .nk-blog-grid-3 .nk-blog-post {
        width: 100%;
        padding-right: 0;
    }
    .nk-blog-grid-3 .nk-blog-post:nth-child(1n) {
        padding-right: 0;
        padding-left: 0;
    }
}

.nk-blog-container {
    height: 100%;
}

.nk-blog-container-offset {
    margin-top: -180px;
}

@media (max-width: 991px) {
    .nk-blog-container-offset {
        margin-top: -110px;
    }
}

.nk-comments {
    padding: 70px;
    background-color: #0e0e0e;
}

.nk-comments .nk-comment {
    padding-top: 1px;
}

.nk-comments .nk-comment::after {
    content: "";
    display: table;
    clear: both;
}

.nk-comments .nk-comment > p:last-of-type {
    margin-bottom: 12px;
}

.nk-comments .nk-comment h4 {
    margin-top: 25px;
}

.nk-comments .nk-comment .nk-comment,
.nk-comments .nk-comment + .nk-comment {
    padding-top: 40px;
    clear: both;
}

.nk-comments .nk-comment .nk-comment-avatar {
    position: relative;
    float: left;
    margin-top: 10px;
    margin-right: 25px;
    z-index: 1;
}

.nk-comments .nk-comment .nk-comment-avatar img {
    width: 60px;
    height: auto;
    border-radius: 30px;
}

.nk-comments .nk-comment .nk-comment-avatar ~ * {
    margin-left: 85px;
}

.nk-comments .nk-comment .nk-comment {
    margin-left: 55px;
}

@media (max-width: 991px) {
    .nk-comments .nk-comment .nk-comment {
        margin-left: 30px;
    }
}

.nk-comments .nk-comment .nk-comment-name {
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 5px;
}

.nk-comments .nk-comment .nk-comment-name a,
.nk-comments .nk-comment .nk-comment-name a:hover,
.nk-comments .nk-comment .nk-comment-name a:focus,
.nk-comments .nk-comment .nk-comment-name a:active {
    color: inherit;
    text-decoration: none;
}

.nk-comments .nk-comment .nk-comment-meta {
    position: relative;
    margin-bottom: 10px;
}

.nk-comments .nk-comment .nk-comment-meta::after {
    content: "";
    display: table;
    clear: right;
}

.nk-comments .nk-comment .nk-comment-meta .nk-comment-date {
    display: inline-block;
    font-size: .9rem;
    opacity: .6;
}

.nk-comments .nk-comment .nk-comment-meta .nk-comment-date::before {
    content: "•";
    margin-right: 6px;
    margin-left: 4px;
}

.nk-comments .nk-comment .nk-comment-reply {
    display: inline-block;
    margin-left: 12px;
    font-size: .9rem;
}

.nk-comments .nk-comment .nk-comment-reply a,
.nk-comments .nk-comment .nk-comment-reply a:hover,
.nk-comments .nk-comment .nk-comment-reply a:focus,
.nk-comments .nk-comment .nk-comment-reply a:active {
    color: inherit;
    text-decoration: none;
}

.nk-comments .nk-comment .nk-action-heart {
    display: inline-block;
}

.nk-comments .nk-comment .nk-action-heart > span {
    margin-right: 2px;
    font-size: .9rem;
}

@media (max-width: 767px) {
    .nk-comments {
        padding: 30px;
    }
    .nk-comments .nk-comment .nk-comment-avatar {
        margin-top: 25px;
    }
    .nk-comments .nk-comment .nk-comment-avatar img {
        width: 40px;
        border-radius: 20px;
    }
    .nk-comments .nk-comment .nk-comment-avatar ~ * {
        margin-left: 45px;
    }
    .nk-comments .nk-comment .nk-comment-avatar ~ .nk-comment {
        margin-left: 15px;
    }
    .nk-comments .nk-comment .nk-comment-meta {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .nk-comments .nk-comment .nk-comment-meta .nk-comment-date {
        display: block;
    }
    .nk-comments .nk-comment .nk-comment-meta .nk-comment-date::before {
        content: none;
    }
    .nk-comments .nk-comment .nk-comment-meta ~ * {
        margin-left: 0;
    }
    .nk-comments .nk-comment .nk-comment-meta + * {
        clear: left;
    }
}

.nk-reply {
    margin-top: 80px;
}

/* Audio post */
.nk-post-audio {
    position: relative;
}

.nk-post-audio > div {
    position: absolute;
    right: 20px;
    bottom: 20px;
    left: 20px;
    width: auto;
}

/*------------------------------------------------------------------

  Media Gallery

 -------------------------------------------------------------------*/
.nk-popup-gallery .nk-gallery-item,
.nk-video-gallery .nk-gallery-item {
    cursor: url("../godlike-images/cursor-gallery-item.svg") 24 24, pointer;
}

.nk-popup-gallery .nk-gallery-item-box,
.nk-video-gallery .nk-gallery-item-box {
    width: 65%;
    margin: 0 auto;
    margin-bottom: 65px;
}

.nk-popup-gallery .nk-gallery-item-box > a,
.nk-video-gallery .nk-gallery-item-box > a {
    position: relative;
    display: block;
    transition: .5s transform ease;
}

.nk-popup-gallery .nk-gallery-item-box > a > img,
.nk-video-gallery .nk-gallery-item-box > a > img {
    width: 100%;
    height: auto;
}

.nk-popup-gallery .nk-gallery-item-box > a:hover, .nk-popup-gallery .nk-gallery-item-box > a.hover,
.nk-video-gallery .nk-gallery-item-box > a:hover,
.nk-video-gallery .nk-gallery-item-box > a.hover {
    transform: scale(0.95);
}

.nk-popup-gallery .nk-gallery-item-box > a::after,
.nk-video-gallery .nk-gallery-item-box > a::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: inset 0 0 150px 30px rgba(0, 0, 0, 0.6);
    transition: .8s box-shadow;
}

.nk-popup-gallery .nk-gallery-item-box > a:hover::after,
.nk-popup-gallery .nk-gallery-item-box > a.hover::after,
.nk-video-gallery .nk-gallery-item-box > a:hover::after,
.nk-video-gallery .nk-gallery-item-box > a.hover::after {
    box-shadow: inset 0 0 150px 30px rgba(0, 0, 0, 0);
}

.nk-popup-gallery-2-col,
.nk-video-gallery-2-col,
.nk-popup-gallery-3-col,
.nk-video-gallery-3-col {
    margin-right: -32.5px;
    margin-left: -32.5px;
}

.nk-popup-gallery-2-col::after,
.nk-video-gallery-2-col::after,
.nk-popup-gallery-3-col::after,
.nk-video-gallery-3-col::after {
    content: "";
    display: table;
    width: 100%;
    clear: both;
}

.nk-popup-gallery-2-col .nk-gallery-item-box,
.nk-video-gallery-2-col .nk-gallery-item-box,
.nk-popup-gallery-3-col .nk-gallery-item-box,
.nk-video-gallery-3-col .nk-gallery-item-box {
    float: left;
    width: 50%;
    padding-right: 32.5px;
    padding-left: 32.5px;
}

@media (max-width: 1199px) {
    .nk-popup-gallery-2-col,
    .nk-video-gallery-2-col,
    .nk-popup-gallery-3-col,
    .nk-video-gallery-3-col {
        margin-right: -15px;
        margin-left: -15px;
    }
    .nk-popup-gallery-2-col .nk-gallery-item-box,
    .nk-video-gallery-2-col .nk-gallery-item-box,
    .nk-popup-gallery-3-col .nk-gallery-item-box,
    .nk-video-gallery-3-col .nk-gallery-item-box {
        padding-right: 15px;
        padding-left: 15px;
        margin-bottom: 30px;
    }
}

@media (max-width: 767px) {
    .nk-popup-gallery-2-col .nk-gallery-item-box,
    .nk-video-gallery-2-col .nk-gallery-item-box,
    .nk-popup-gallery-3-col .nk-gallery-item-box,
    .nk-video-gallery-3-col .nk-gallery-item-box {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .nk-popup-gallery-3-col .nk-gallery-item-box,
    .nk-video-gallery-3-col .nk-gallery-item-box {
        width: 33.333%;
    }
}

/*------------------------------------------------------------------

  Store

 -------------------------------------------------------------------*/
.nk-store .nk-product {
    position: relative;
    transition: 0s .3s z-index;
    z-index: 1;
}

.nk-store .nk-product:hover, .nk-store .nk-product.hover {
    transition: 0s z-index;
    z-index: 2;
}

.nk-store .nk-product > div {
    position: relative;
    min-height: 100%;
    padding: 40px;
    padding-top: 65px;
    padding-bottom: 75px;
    text-align: center;
    transition: .3s background-color, .6s transform, 0s .3s z-index;
    z-index: 1;
}

.nk-store .nk-product > div:hover, .nk-store .nk-product > div.hover {
    background-color: #0e0e0e;
    transition: .3s background-color, .6s transform;
    transform: scale(1.05);
}

.nk-store .nk-product > div:hover .nk-product-rating, .nk-store .nk-product > div.hover .nk-product-rating {
    opacity: 1;
}

.nk-store-order {
    float: right;
    margin-top: -9px;
}

.nk-store-count {
    padding-bottom: 9px;
}

.nk-product-title {
    margin-top: 60px;
    margin-bottom: 0;
}

.nk-product-title > a {
    color: inherit;
    text-decoration: none;
    transition: .3s opacity;
}

.nk-product-title > a:hover, .nk-product-title > a.hover {
    opacity: .8;
}

.nk-product-image {
    position: relative;
    display: block;
}

.nk-product-category {
    display: block;
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: .9rem;
    font-style: italic;
    opacity: .8;
}

.nk-product-category > a {
    text-decoration: none;
}

.nk-product-price {
    display: block;
    font-size: 1.2rem;
    line-height: 1;
}

.nk-product-price del {
    margin-left: 15px;
    font-size: .7em;
    vertical-align: middle;
    opacity: .5;
}

.nk-product-rating {
    position: relative;
    display: inline-block;
    font-size: .8rem;
    line-height: 1;
    vertical-align: text-bottom;
}

.nk-product-rating .nk-product-rating-front {
    position: absolute;
    display: block;
    overflow: hidden;
    white-space: nowrap;
}

.nk-product-rating .nk-product-rating-back {
    display: block;
    white-space: nowrap;
}

.nk-product-rating, .nk-product-rating:hover, .nk-product-rating:active, .nk-product-rating:focus {
    color: inherit;
    text-decoration: none;
}

.nk-store .nk-product-rating {
    position: absolute;
    bottom: 30px;
    left: 50%;
    opacity: 0;
    transition: .3s opacity;
    transform: translateX(-50%);
}

.nk-comment .nk-review-rating.nk-product-rating {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: .9rem;
}

.nk-product-bottom {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    visibility: hidden;
    background-color: #0e0e0e;
    opacity: 0;
    transition: .3s opacity, .3s visibility;
    z-index: -1;
}

.nk-product-bottom > div {
    display: table;
    width: 100%;
}

.nk-product-bottom > div > * {
    display: table-cell;
    padding-bottom: 30px;
    color: inherit;
    text-decoration: none;
}

.nk-product:hover .nk-product-bottom,
.nk-product.hover .nk-product-bottom {
    visibility: visible;
    opacity: 1;
}

/* Single product */
.nk-store-product .nk-product-category {
    margin-top: 0;
}

.nk-store-product .nk-product-title {
    margin-top: 0;
    margin-bottom: 60px;
}

.nk-store-product .nk-product-description,
.nk-store-product .nk-product-size,
.nk-store-product .nk-product-color {
    margin-top: 60px;
    margin-bottom: 60px;
}

.nk-store-product .nk-product-price {
    display: inline-block;
    margin-top: 30px;
    font-size: 1.7rem;
    vertical-align: middle;
}

.nk-store-product .nk-product-addtocart {
    margin-top: -30px;
}

.nk-store-product .nk-product-addtocart::after {
    content: "";
    display: table;
    clear: both;
}

.nk-store-product .nk-product-addtocart .nk-form-control-number {
    display: inline-block;
    vertical-align: bottom;
}

.nk-store-product .nk-product-addtocart .nk-btn {
    margin-top: 30px;
    margin-right: 40px;
}

.nk-store-product .nk-product-rating {
    font-size: 1.1rem;
}

.nk-store-product .nk-product-progress {
    margin-top: 20px;
}

.nk-store-product .nk-product-progress table {
    width: 100%;
}

.nk-store-product .nk-product-progress table td:first-child {
    width: 60px;
}

.nk-store-product .nk-product-progress .nk-progress {
    margin-top: 16px;
    margin-bottom: 16px;
}

/* Store Cart */
.nk-store-cart .nk-store-cart-products td {
    vertical-align: middle;
    border-color: transparent;
}

.nk-store-cart .nk-store-cart-products thead th {
    padding-top: 0;
}

.nk-store-cart .nk-store-cart-products tbody .nk-product-cart-thumb {
    width: 190px;
    padding-top: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    padding-left: 20px;
}

.nk-store-cart .nk-store-cart-products tbody .nk-product-cart-thumb img {
    max-width: 130px;
    height: auto;
}

.nk-store-cart .nk-store-cart-products .nk-product-cart-title > * {
    margin: 0;
}

.nk-store-cart .nk-store-cart-products .nk-product-cart-remove a {
    font-size: 1.2rem;
    color: inherit;
    text-decoration: none;
}

.nk-store-cart .nk-store-cart-products .nk-product-cart-quantity {
    width: 105px;
}

.nk-store-cart .nk-store-cart-products .nk-product-cart-price,
.nk-store-cart .nk-store-cart-products .nk-product-cart-total {
    width: 150px;
}

.nk-store-cart .nk-store-cart-products .nk-product-cart-remove {
    width: 40px;
}

.nk-store-cart .nk-store-cart-products .nk-product-cart-price,
.nk-store-cart .nk-store-cart-products .nk-product-cart-quantity,
.nk-store-cart .nk-store-cart-products .nk-product-cart-total,
.nk-store-cart .nk-store-cart-products .nk-product-cart-remove {
    text-align: center;
}

@media (max-width: 767px) {
    .nk-store-cart .nk-store-cart-products {
        margin-bottom: 0;
    }
    .nk-store-cart .nk-store-cart-products tr {
        display: block;
        width: 100%;
        padding-bottom: 30px;
        margin-bottom: 30px;
        text-align: left;
        border-bottom: 1px solid #181818;
    }
    .nk-store-cart .nk-store-cart-products td {
        display: block;
        clear: both;
    }
    .nk-store-cart .nk-store-cart-products td,
    .nk-store-cart .nk-store-cart-products .nk-product-cart-price,
    .nk-store-cart .nk-store-cart-products .nk-product-cart-quantity,
    .nk-store-cart .nk-store-cart-products .nk-product-cart-total,
    .nk-store-cart .nk-store-cart-products .nk-product-cart-remove {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        text-align: right;
    }
    .nk-store-cart .nk-store-cart-products tbody .nk-product-cart-thumb {
        padding-top: 0;
        padding-right: 0;
        padding-left: 0;
        text-align: left;
    }
    .nk-store-cart .nk-store-cart-products td[data-title]::before {
        content: attr(data-title) ": ";
        float: left;
        font-weight: 600;
    }
    .nk-store-cart .nk-store-cart-products .nk-product-cart-quantity .nk-form-control-number {
        float: right;
    }
}

.nk-store-cart .nk-store-cart-totals {
    margin-top: 30px;
}

.nk-store-cart .nk-store-cart-totals tr td:first-child {
    padding-left: 0;
}

.nk-store-cart .nk-store-cart-totals tr td:last-child {
    padding-right: 0;
    text-align: right;
}

.nk-store-cart .nk-store-cart-totals td {
    border-color: transparent;
}

.nk-store-cart .nk-store-cart-totals .nk-store-cart-totals-total td {
    position: relative;
    padding-top: 60px;
    font-size: 1.1rem;
    font-weight: 600;
}

.nk-store-cart .nk-store-cart-totals .nk-store-cart-totals-total td::before {
    content: "";
    position: absolute;
    display: block;
    top: 25px;
    right: 0;
    left: 0;
    border-top: 1px solid;
    opacity: .2;
}

/* Checkout */
.nk-store-checkout .nk-store-cart-order tr td:first-child,
.nk-store-checkout .nk-store-cart-order tr th:first-child {
    padding-left: 20px;
    border-right: 0;
}

.nk-store-checkout .nk-store-cart-order tr td:last-child,
.nk-store-checkout .nk-store-cart-order tr th:last-child {
    padding-right: 20px;
    text-align: right;
    border-left: 0;
}

.nk-store-checkout .nk-store-cart-order .nk-store-cart-totals-total td {
    font-size: 1.1rem;
    font-weight: 600;
}

.nk-store-checkout .nk-store-cart-order td {
    background-color: #181818;
}

.nk-store-checkout .nk-store-cart-order .nk-store-cart-totals-subtotal td,
.nk-store-checkout .nk-store-cart-order .nk-store-cart-totals-shipping td,
.nk-store-checkout .nk-store-cart-order .nk-store-cart-totals-total td {
    background-color: transparent;
}

/* Account */
.nk-store-account-links ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.nk-store-account-links ul > li > a {
    display: block;
    padding: 6.5px 0;
    margin: 10px 0;
    transition: .2s color;
}

.nk-store-account-links ul > li > a, .nk-store-account-links ul > li > a:hover, .nk-store-account-links ul > li > a:active, .nk-store-account-links ul > li > a:focus {
    color: inherit;
    text-decoration: none;
}

.nk-store-account-links ul > li > a:hover {
    color: #999999;
}

.nk-store-account-links ul > li.active > a {
    color: #e08821;
}

/*------------------------------------------------------------------

  Forum

 -------------------------------------------------------------------*/
/* Threads List */
.nk-forum {
    padding: 0;
    margin: 0;
    list-style: none;
}

.nk-forum > li {
    display: table;
    width: 100%;
    padding: 60px;
    background-color: #0e0e0e;
}

@media (max-width: 991px) {
    .nk-forum > li {
        padding: 40px;
    }
}

.nk-forum > li + li {
    padding-top: 0;
}

.nk-forum > li.nk-forum-locked > * {
    opacity: .4;
}

.nk-forum > li > * {
    display: table-cell;
    vertical-align: top;
}

.nk-forum > li .nk-forum-icon,
.nk-forum > li .nk-forum-title {
    padding-right: 25px;
}

.nk-forum > li .nk-forum-count {
    padding-right: 35px;
    font-size: .9rem;
    white-space: nowrap;
}

.nk-forum > li .nk-forum-icon {
    min-width: 55px;
}

.nk-forum > li .nk-forum-icon > span {
    display: block;
    font-size: 1.8rem;
    line-height: 1;
}

.nk-forum > li .nk-forum-title {
    width: 100%;
}

.nk-forum > li .nk-forum-title > h2,
.nk-forum > li .nk-forum-title > h3,
.nk-forum > li .nk-forum-title > h4 {
    margin-bottom: 0;
    font-size: 1.2rem;
}

.nk-forum > li .nk-forum-title > h2 > a,
.nk-forum > li .nk-forum-title > h3 > a,
.nk-forum > li .nk-forum-title > h4 > a {
    transition: .2s opacity;
}

.nk-forum > li .nk-forum-title > h2 > a,
.nk-forum > li .nk-forum-title > h2 > a:hover,
.nk-forum > li .nk-forum-title > h2 > a:focus,
.nk-forum > li .nk-forum-title > h2 > a:active,
.nk-forum > li .nk-forum-title > h3 > a,
.nk-forum > li .nk-forum-title > h3 > a:hover,
.nk-forum > li .nk-forum-title > h3 > a:focus,
.nk-forum > li .nk-forum-title > h3 > a:active,
.nk-forum > li .nk-forum-title > h4 > a,
.nk-forum > li .nk-forum-title > h4 > a:hover,
.nk-forum > li .nk-forum-title > h4 > a:focus,
.nk-forum > li .nk-forum-title > h4 > a:active {
    color: inherit;
    text-decoration: none;
}

.nk-forum > li .nk-forum-title > h2 > a:hover,
.nk-forum > li .nk-forum-title > h2 > a.hover,
.nk-forum > li .nk-forum-title > h3 > a:hover,
.nk-forum > li .nk-forum-title > h3 > a.hover,
.nk-forum > li .nk-forum-title > h4 > a:hover,
.nk-forum > li .nk-forum-title > h4 > a.hover {
    opacity: .8;
}

.nk-forum > li .nk-forum-title .nk-forum-title-sub {
    margin-top: 10px;
    font-size: .9rem;
    font-style: italic;
}

.nk-forum > li .nk-forum-title .nk-forum-title-sub a {
    font-weight: 600;
    color: inherit;
}

.nk-forum > li .nk-forum-activity-avatar img {
    float: left;
    width: 34px;
    height: auto;
    margin-right: 15px;
    border-radius: 17px;
}

.nk-forum > li .nk-forum-activity {
    min-width: 130px;
    max-width: 130px;
}

.nk-forum > li .nk-forum-activity .nk-forum-activity-date {
    font-size: .9rem;
    font-style: italic;
}

.nk-forum > li .nk-forum-activity .nk-forum-activity-title {
    overflow: hidden;
    font-size: .9rem;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.nk-forum > li .nk-forum-activity .nk-forum-activity-title > a {
    width: 100%;
    transition: .2s opacity;
}

.nk-forum > li .nk-forum-activity .nk-forum-activity-title > a,
.nk-forum > li .nk-forum-activity .nk-forum-activity-title > a:hover,
.nk-forum > li .nk-forum-activity .nk-forum-activity-title > a:focus,
.nk-forum > li .nk-forum-activity .nk-forum-activity-title > a:active {
    color: inherit;
    text-decoration: none;
}

.nk-forum > li .nk-forum-activity .nk-forum-activity-title > a:hover,
.nk-forum > li .nk-forum-activity .nk-forum-activity-title > a.hover {
    opacity: .8;
}

@media (max-width: 1199px) {
    .nk-forum > li .nk-forum-activity {
        display: none;
    }
}

@media (max-width: 991px) {
    .nk-forum > li .nk-forum-count,
    .nk-forum > li .nk-forum-activity-avatar {
        display: none;
    }
}

@media (max-width: 575px) {
    .nk-forum > li .nk-forum-icon {
        display: none;
    }
}

/* Single Topic */
.nk-forum-topic > li,
.nk-forum-topic > li + li {
    padding-top: 60px;
}

.nk-forum-topic > li + li {
    margin-top: 50px;
}

.nk-forum-topic > li {
    display: block;
}

.nk-forum-topic > li > * {
    display: block;
}

.nk-forum-topic > li .nk-forum-topic-author {
    float: left;
    width: 120px;
    text-align: center;
}

.nk-forum-topic > li .nk-forum-topic-author img {
    width: 70px;
    height: auto;
    margin: 0 auto;
    border-radius: 35px;
}

.nk-forum-topic > li .nk-forum-topic-author-name {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: "Marcellus SC", serif;
    font-size: 1.2rem;
    font-weight: 600;
}

.nk-forum-topic > li .nk-forum-topic-author-name > a {
    transition: .2s opacity;
}

.nk-forum-topic > li .nk-forum-topic-author-name > a,
.nk-forum-topic > li .nk-forum-topic-author-name > a:hover,
.nk-forum-topic > li .nk-forum-topic-author-name > a:focus,
.nk-forum-topic > li .nk-forum-topic-author-name > a:active {
    color: inherit;
    text-decoration: none;
}

.nk-forum-topic > li .nk-forum-topic-author-name > a:hover,
.nk-forum-topic > li .nk-forum-topic-author-name > a.hover {
    opacity: .8;
}

.nk-forum-topic > li .nk-forum-topic-author-since {
    margin-top: 10px;
    font-size: .9rem;
    opacity: .6;
}

.nk-forum-topic > li .nk-forum-topic-content {
    margin-left: 160px;
}

.nk-forum-topic > li .nk-forum-topic-content p:last-child {
    margin-bottom: 0;
}

.nk-forum-topic > li .nk-forum-topic-footer {
    position: relative;
    padding-top: 70px;
    clear: both;
}

.nk-forum-topic > li .nk-forum-topic-footer::before {
    content: "";
    position: absolute;
    display: block;
    top: 35px;
    right: 0;
    left: 0;
    border-top: 1px solid #181818;
}

.nk-forum-topic > li .nk-forum-topic-footer::after {
    content: "";
    display: block;
    clear: both;
}

.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-topic-date {
    font-size: .9rem;
}

.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn {
    float: right;
    font-size: .9rem;
}

.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > a > span {
    margin-right: 5px;
    vertical-align: middle;
}

.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > a,
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > span {
    padding: 7px 14px;
    border-radius: 2px;
    transition: .2s background-color;
}

.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > a,
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > a:hover,
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > a:focus,
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > a:active {
    color: inherit;
    text-decoration: none;
}

.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > a:hover,
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > a.hover,
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > span:hover,
.nk-forum-topic > li .nk-forum-topic-footer .nk-forum-action-btn > span.hover {
    background-color: #181818;
}

.nk-forum-topic > li .nk-forum-topic-attachments {
    padding: 40px;
    margin-top: 40px;
    background-color: #181818;
}

@media (max-width: 767px) {
    .nk-forum-topic > li {
        padding: 30px;
    }
    .nk-forum-topic > li + li {
        padding-top: 30px;
    }
    .nk-forum-topic > li .nk-forum-topic-author {
        float: none;
        width: 100%;
        text-align: left;
    }
    .nk-forum-topic > li .nk-forum-topic-author::after {
        content: "";
        display: table;
        margin-bottom: 25px;
        clear: both;
    }
    .nk-forum-topic > li .nk-forum-topic-author img {
        float: left;
        width: 50px;
        height: auto;
        margin: 0;
    }
    .nk-forum-topic > li .nk-forum-topic-author .nk-forum-topic-author-name {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 70px;
        line-height: 1.4;
    }
    .nk-forum-topic > li .nk-forum-topic-author .nk-forum-topic-author-role {
        margin-left: 70px;
        font-size: .8rem;
    }
    .nk-forum-topic > li .nk-forum-topic-author .nk-forum-topic-author-since {
        display: none;
    }
    .nk-forum-topic > li .nk-forum-topic-content {
        margin-left: 0;
    }
}

/*------------------------------------------------------------------

  Social Network

 -------------------------------------------------------------------*/
/* Menu */
.nk-social-menu ul,
.nk-social-menu-inline ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.nk-social-menu ul > li > a,
.nk-social-menu-inline ul > li > a {
    display: block;
    padding: 6.5px 0;
    margin: 10px 0;
    transition: .2s color;
}

.nk-social-menu ul > li > a, .nk-social-menu ul > li > a:hover, .nk-social-menu ul > li > a:active, .nk-social-menu ul > li > a:focus,
.nk-social-menu-inline ul > li > a,
.nk-social-menu-inline ul > li > a:hover,
.nk-social-menu-inline ul > li > a:active,
.nk-social-menu-inline ul > li > a:focus {
    color: inherit;
    text-decoration: none;
}

.nk-social-menu ul > li > a:hover,
.nk-social-menu-inline ul > li > a:hover {
    color: #999999;
}

.nk-social-menu ul > li.active > a,
.nk-social-menu-inline ul > li.active > a {
    color: #e08821;
}

.nk-social-menu .nk-badge,
.nk-social-menu-inline .nk-badge {
    float: right;
    margin-top: 6px;
    color: #fff;
}

.nk-social-menu-inline ul {
    margin-right: -16.5px;
    margin-bottom: 16.5px;
    margin-left: -16.5px;
}

.nk-social-menu-inline ul > li {
    display: inline-block;
}

.nk-social-menu-inline ul > li > a {
    padding: 6.5px;
    margin: 10px;
}

/* Profile Info */
.nk-social-profile {
    position: relative;
    z-index: 1;
}

.nk-social-profile.nk-social-profile-container-offset {
    margin-top: -100px;
}

.nk-social-profile .nk-social-profile-avatar {
    padding-right: 40px;
}

.nk-social-profile .nk-social-profile-avatar img {
    width: 100%;
    height: auto;
}

.nk-social-profile .nk-social-profile-info-last-seen {
    float: right;
    margin-top: 18px;
    font-size: .9rem;
    opacity: .6;
}

.nk-social-profile .nk-social-profile-info-admins {
    float: right;
    max-width: 50%;
    padding-top: 15px;
    text-align: right;
}

.nk-social-profile .nk-social-profile-info-admins > h2 {
    margin-bottom: 0;
    font-family: inherit;
    font-size: .9rem;
    font-weight: inherit;
    opacity: .6;
}

.nk-social-profile .nk-social-profile-info-admins ul {
    padding: 0;
    margin: 0;
    margin-top: 15px;
    margin-right: -8px;
    margin-left: -8px;
    list-style-type: none;
}

.nk-social-profile .nk-social-profile-info-admins ul::after {
    content: "";
    display: block;
    clear: both;
}

.nk-social-profile .nk-social-profile-info-admins ul > li {
    float: right;
    margin: 8px;
}

.nk-social-profile .nk-social-profile-info-admins ul img {
    width: 40px;
    height: auto;
    border-radius: 20px;
}

.nk-social-profile .nk-social-profile-info-username {
    padding-top: 15px;
    font-size: .9rem;
    opacity: .6;
}

.nk-social-profile .nk-social-profile-info-actions {
    margin-top: 15px;
}

.nk-social-profile .nk-social-profile-info-description {
    margin-top: 15px;
}

@media (max-width: 991px) {
    .nk-social-profile .nk-social-profile-avatar {
        padding-right: 0;
    }
    .nk-social-profile .nk-social-profile-avatar img {
        max-width: 220px;
        height: auto;
    }
}

@media (max-width: 767px) {
    .nk-social-profile .nk-social-profile-info-last-seen {
        display: none;
    }
    .nk-social-profile .nk-social-profile-info-admins {
        float: none;
        max-width: 100%;
        text-align: left;
    }
    .nk-social-profile .nk-social-profile-info-admins ul > li {
        float: left;
    }
}

/* Content */
.nk-social-container {
    padding: 70px;
    background-color: #0e0e0e;
}

@media (max-width: 767px) {
    .nk-social-container {
        padding: 30px;
    }
}

/* Sort */
.nk-social-sort {
    display: flex;
    align-items: center;
    width: 50%;
    margin-left: auto;
}

.nk-social-sort::after {
    content: "";
    display: block;
    clear: both;
}

.nk-social-sort label {
    margin-right: 10px;
    margin-bottom: 0;
    white-space: nowrap;
}

@media (max-width: 991px) {
    .nk-social-sort {
        width: 100%;
    }
}

/* Activity */
.nk-social-activity {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.nk-social-activity > li {
    display: flex;
}

.nk-social-activity > li + li {
    margin-top: 45px;
}

.nk-social-activity .nk-social-activity-avatar img {
    width: 60px;
    height: auto;
    margin-right: 25px;
    border-radius: 30px;
}

.nk-social-activity .nk-social-activity-content {
    flex: 1;
}

.nk-social-activity .nk-social-activity-content img {
    max-width: 100%;
    height: auto;
}

.nk-social-activity .nk-social-activity-meta {
    padding: 5px 15px;
    margin-bottom: 15px;
    font-size: .9em;
    background-color: #181818;
}

.nk-social-activity .nk-social-activity-meta .nk-social-activity-meta-time {
    color: inherit;
}

.nk-social-activity .nk-social-activity-meta img {
    width: 20px;
    height: auto;
    margin-left: 2px;
    border-radius: 10px;
}

.nk-social-activity .nk-social-activity-mention {
    font-weight: 600;
    color: inherit;
}

.nk-social-activity .nk-social-activity-actions {
    margin-top: 15px;
    font-size: .9em;
    text-align: right;
}

.nk-social-activity .nk-social-activity-actions a {
    color: inherit;
    text-decoration: none;
    transition: .2s color;
}

.nk-social-activity .nk-social-activity-actions a:hover {
    color: #999999;
}

.nk-social-activity .nk-social-activity-actions > * + * {
    margin-left: 12px;
}

.nk-social-activity .nk-social-activity-actions .nk-badge {
    color: #fff;
    vertical-align: top;
}

.nk-social-activity .nk-social-activity-actions .nk-action-heart > span {
    margin-right: 2px;
    font-size: .9rem;
}

.nk-social-activity .nk-social-activity-comments,
.nk-social-activity .nk-social-activity-replies {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.nk-social-activity .nk-social-activity-comments > li,
.nk-social-activity .nk-social-activity-replies > li {
    display: flex;
    margin-top: 30px;
}

.nk-social-activity .nk-social-activity-comments .nk-social-activity-avatar img,
.nk-social-activity .nk-social-activity-replies .nk-social-activity-avatar img {
    width: 30px;
    height: auto;
    margin-right: 20px;
    border-radius: 15px;
}

.nk-social-activity .nk-social-activity-comments-show-all a {
    display: block;
    width: 100%;
    padding: 10px 0;
    font-style: italic;
    color: inherit;
    text-align: center;
    text-decoration: none;
}

.nk-social-activity .nk-social-activity-load-more {
    display: block;
    text-align: center;
}

/* Notifications */
.nk-social-notifications table {
    margin-bottom: 0;
}

.nk-social-notifications .nk-social-notifications-bulk-select {
    width: 10px;
}

.nk-social-notifications .nk-social-notifications-description {
    width: 55%;
}

.nk-social-notifications .nk-social-notifications-description a,
.nk-social-notifications .nk-social-notifications-actions a {
    color: inherit;
    text-decoration: none;
    transition: .3s color;
}

.nk-social-notifications .nk-social-notifications-description a:hover,
.nk-social-notifications .nk-social-notifications-actions a:hover {
    color: #999999;
}

.nk-social-notifications .nk-social-notifications-actions {
    margin-right: -5px;
    margin-left: -5px;
    font-size: .9em;
}

.nk-social-notifications .nk-social-notifications-actions a {
    margin-right: 5px;
    margin-left: 5px;
    white-space: nowrap;
}

.nk-social-notifications-options,
.nk-social-messages-options {
    display: flex;
    align-items: center;
    width: 50%;
}

.nk-social-notifications-options select,
.nk-social-messages-options select {
    margin-right: 10px;
}

@media (max-width: 991px) {
    .nk-social-notifications-options,
    .nk-social-messages-options {
        width: 100%;
    }
}

/* Messages */
.nk-social-messages .nk-social-messages-description {
    position: relative;
    padding: 20px;
    padding-left: 80px;
}

.nk-social-messages .nk-social-messages-unread {
    background-color: rgba(255, 255, 255, 0.03);
}

.nk-social-messages .nk-social-messages-since {
    float: right;
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: .9rem;
    font-style: italic;
    line-height: 1.2;
    color: inherit;
    opacity: .3;
    transition: .3s opacity, .3s visiblity;
}

.nk-social-messages .nk-social-messages-remove,
.nk-social-messages .nk-social-messages-favorite {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 1.1rem;
    color: inherit;
    text-decoration: none;
    visibility: hidden;
    opacity: 0;
    transition: .3s opacity, .3s visiblity;
}

.nk-social-messages .nk-social-messages-favorite {
    right: 45px;
}

.nk-social-messages tr:hover .nk-social-messages-since {
    visibility: hidden;
    opacity: 0;
}

.nk-social-messages tr:hover .nk-social-messages-remove,
.nk-social-messages tr:hover .nk-social-messages-favorite {
    visibility: visible;
    opacity: .3;
}

.nk-social-messages tr:hover .nk-social-messages-remove:hover,
.nk-social-messages tr:hover .nk-social-messages-favorite:hover {
    opacity: .8;
}

.nk-social-messages .nk-social-messages-from {
    float: left;
    margin-left: -60px;
}

.nk-social-messages .nk-social-messages-from img {
    width: 40px;
    height: auto;
    border-radius: 20px;
}

.nk-social-messages .nk-social-messages-from a {
    text-decoration: none;
}

.nk-social-messages .nk-social-messages-subject {
    margin-bottom: 10px;
    line-height: 1;
}

.nk-social-messages .nk-social-messages-subject a {
    font-weight: 600;
    color: inherit;
    text-decoration: none;
    transition: .3s color;
}

.nk-social-messages .nk-social-messages-subject a:hover {
    color: #999999;
}

.nk-social-messages .nk-social-messages-excerpt a {
    color: #999999;
    text-decoration: none;
    transition: .3s color;
}

.nk-social-messages .nk-social-messages-excerpt a:hover {
    color: #737373;
}

@media (max-width: 767px) {
    .nk-social-messages .nk-social-messages-description {
        padding-left: 20px;
    }
    .nk-social-messages .nk-social-messages-from {
        margin-right: 20px;
        margin-left: 0;
    }
}

/* Single Message */
.nk-social-messages-single {
    display: flex;
    margin-top: 45px;
}

.nk-social-messages-single .nk-social-messages-single-avatar img {
    width: 60px;
    height: auto;
    margin-right: 25px;
    border-radius: 30px;
}

.nk-social-messages-single .nk-social-messages-single-content {
    flex: 1;
}

.nk-social-messages-single .nk-social-messages-single-meta {
    padding: 5px 15px;
    margin-bottom: 15px;
    font-size: .9em;
    background-color: #181818;
}

.nk-social-messages-single .nk-social-messages-single-meta .nk-social-messages-single-meta-name {
    margin-right: 10px;
    font-size: 1.1rem;
}

.nk-social-messages-single .nk-social-messages-single-meta .nk-social-messages-single-meta-favorite {
    float: right;
    font-size: 1.1rem;
    color: inherit;
    text-decoration: none;
}

.nk-social-messages-single .nk-social-messages-single-meta .nk-social-messages-single-meta-time {
    color: inherit;
}

.nk-social-messages-single .nk-social-messages-single-meta img {
    width: 20px;
    height: auto;
    margin-left: 2px;
    border-radius: 10px;
}

/* Firends */
.nk-social-friends {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.nk-social-friends > li {
    position: relative;
    display: flex;
}

.nk-social-friends > li + li {
    padding-top: 30px;
    margin-top: 30px;
}

.nk-social-friends > li + li::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    width: 100%;
    border-bottom: 1px solid;
    opacity: .1;
}

.nk-social-friends .nk-social-friends-avatar img {
    width: 60px;
    height: auto;
    margin-right: 25px;
    border-radius: 30px;
}

.nk-social-friends .nk-social-friends-content {
    flex: 1;
}

.nk-social-friends .nk-social-friends-name {
    font-size: 1.2rem;
    font-weight: 600;
}

.nk-social-friends .nk-social-friends-name a {
    color: inherit;
    text-decoration: none;
    transition: .3s color;
}

.nk-social-friends .nk-social-friends-name a:hover {
    color: #999999;
}

.nk-social-friends .nk-social-friends-meta {
    font-size: .9em;
    opacity: .6;
}

.nk-social-friends .nk-social-friends-info {
    float: left;
    width: 60%;
}

.nk-social-friends .nk-social-friends-actions {
    float: right;
    text-align: right;
}

/* User Groups */
.nk-social-groups {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.nk-social-groups > li {
    position: relative;
    display: flex;
}

.nk-social-groups > li + li {
    padding-top: 30px;
    margin-top: 30px;
}

.nk-social-groups > li + li::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    width: 100%;
    border-bottom: 1px solid;
    opacity: .1;
}

.nk-social-groups .nk-social-groups-avatar img {
    width: 60px;
    height: auto;
    margin-right: 25px;
    border-radius: 30px;
}

.nk-social-groups .nk-social-groups-content {
    flex: 1;
}

.nk-social-groups .nk-social-groups-name {
    font-size: 1.2rem;
    font-weight: 600;
}

.nk-social-groups .nk-social-groups-name a {
    color: inherit;
    text-decoration: none;
    transition: .3s color;
}

.nk-social-groups .nk-social-groups-name a:hover {
    color: #999999;
}

.nk-social-groups .nk-social-groups-meta,
.nk-social-groups .nk-social-groups-actions-meta {
    font-size: .9em;
    opacity: .6;
}

.nk-social-groups .nk-social-groups-description {
    margin-top: 15px;
    font-size: .9em;
}

.nk-social-groups .nk-social-groups-info {
    float: left;
    width: 60%;
}

.nk-social-groups .nk-social-groups-actions {
    float: right;
    text-align: right;
}

/* Settings */
.nk-social-settings-table .nk-social-settings-table-title {
    width: 75%;
}

.nk-social-settings-table .nk-social-settings-table-check {
    width: 40px;
    text-align: center;
}

/*------------------------------------------------------------------

  Plugins

 -------------------------------------------------------------------*/
/*---------------------------
  Plugin NanoScroller
 ----------------------------*/
.nano {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.nano .nano-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: scroll;
    overflow-x: hidden;
    outline: none;
    -webkit-overflow-scrolling: touch;
}

.nano .nano-content::-webkit-scrollbar {
    display: none;
}

.nano > .nano-pane {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 6px;
    visibility: hidden\9;
    background: rgba(0, 0, 0, 0.4);
    opacity: .01;
    transition: .2s opacity;
    will-change: opacity;
}

.nano > .nano-pane > .nano-slider {
    position: relative;
    margin: 0 1px;
    background: #fff;
    opacity: .5;
}

.nano > .nano-pane:hover > .nano-slider {
    opacity: .6;
}

.has-scrollbar > .nano-content::-webkit-scrollbar {
    display: block;
}

.nano:hover > .nano-pane, .nano-pane.active, .nano-pane.flashed {
    visibility: visible\9;
    opacity: .99;
}

/*---------------------------
  Plugin PhotoSwipe
 ----------------------------*/
.pswp__button--arrow--left {
    left: 65px;
}

.pswp__button--arrow--right {
    right: 65px;
}

.pswp__button--arrow--left::before,
.pswp__button--arrow--right::before {
    top: 24px;
    width: 55px;
    height: 55px;
    background-color: #0e0e0e;
}

.pswp__button--arrow--left::before {
    left: 8px;
    background-position: -126px -32px;
}

.pswp__button--arrow--right::before {
    right: 8px;
    background-position: -83px -32px;
}

.pswp__top-bar {
    top: 25px;
    right: 65px;
    left: 65px;
    width: auto;
}

@media (max-width: 575px) {
    .pswp__button--arrow--left {
        left: 25px;
    }
    .pswp__button--arrow--right {
        right: 25px;
    }
    .pswp__top-bar {
        top: 15px;
        right: 25px;
        left: 25px;
    }
}

.photoswipe-description {
    position: absolute;
    left: -100000px;
    visibility: hidden;
    opacity: .01;
}

.pswp__caption__center {
    max-width: 520px;
    padding: 40px;
    font-size: inherit;
    line-height: inherit;
}

/*---------------------------
  Plugin Summernote
 ----------------------------*/
.note-editor.note-frame {
    border: none;
}

.panel-heading.note-toolbar {
    padding: 10px 26px;
    background-color: #181818;
}

.note-editor.note-frame .note-editing-area .note-editable {
    padding: 25px;
    color: inherit;
    background-color: #0e0e0e;
}

.note-editor.note-frame .note-statusbar {
    background-color: black;
}

.note-toolbar {
    background-color: #181818;
}

.note-toolbar .note-btn {
    padding: 6px 15px;
    color: inherit;
    background-color: #0e0e0e;
    border: none;
}

.note-toolbar .note-btn:hover {
    background-color: #010101;
}

.note-toolbar .dropdown-menu {
    color: inherit;
    background-color: #181818;
    box-shadow: 0 4px 20px 0 black;
}

.note-toolbar .dropdown-menu a {
    display: block;
    padding: 4px 10px;
    color: inherit;
}

.note-toolbar .dropdown-menu a:hover,
.note-toolbar .dropdown-menu a:focus,
.note-toolbar .dropdown-menu a:active {
    text-decoration: none;
}

.note-toolbar .dropdown-menu a:hover {
    background-color: #0e0e0e;
}

.note-toolbar .dropdown-toggle::after {
    display: none;
}

.note-popover .popover-content .note-color-palette div .note-color-btn,
.note-toolbar.panel-heading .note-color-palette div .note-color-btn {
    border-color: #0e0e0e;
}

.note-popover .popover-content > .btn-group,
.panel-heading.note-toolbar > .btn-group {
    margin-top: 10px;
    margin-right: 15px;
    margin-bottom: 10px;
}

.link-dialog .custom-checkbox input {
    top: 6px;
    left: 0;
    opacity: 1;
    z-index: 1;
}
