


:root{
  --brand: #e08821;
  --brand-50: #aeecff;
  --brand-2: #0f83a7;
  --black: #000000;
  --black-2: #888; 
  --text: #444;
  --box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
  --bg-gr-down: linear-gradient(145deg, #d4d7dc, #fdffff);

  --success: #60d360;
  --danger: #F43F5E;
}

::selection {
  color: #fff;
  background: var(--brand);
}

:focus{
  outline: none!important;
}


*::before *::after {
  box-sizing: border-box;
}

footer{
  z-index: 1;
  background: #000;
  position: relative;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: block; 
}


@font-face {
    font-family: 'DM Mono';
    font-weight: normal;
    src: local('DM Mono'), url(../font/DMMono-Regular.ttf) format('truetype');
  }

body{
    background: var(--black);
    color: #fff;
    font-family: "Roboto Condensed", 'Helvetica Neue' , sans-serif;
    /* cursor: none; */
      
}

a{
  color: var(--brand)
}

a:hover{
  text-decoration: none;
}

.ubuntu{
  /* font-family: 'Ubuntu', 'Helvetica Neue', sans-serif; */
  font-weight: bold;
}

.bg-default{
  background-color:var(--black);
}


.lighten{
  mix-blend-mode: screen;
}

*, ::before, ::after{
    box-sizing: border-box;
  }

.text-bold{
    font-weight: bold;
}

.text-brand{
  color: var(--brand)
}


.modal-content{
  background: var(--black);
  background-image: url(https://wp.nkdev.info/godlike/wp-content/uploads/2016/10/bg-menu.jpg);
  background-size: cover;
  border: none;
}



.bg-img-1{
  background: var(--black);
  background-image: url(https://wp.nkdev.info/godlike/wp-content/uploads/2016/10/bg-menu.jpg);
  background-size: auto;
  border: none;
  /* background-blend-mode: color-dodge; */
}

.bg-img-2 {
  background:#464646;;
  background-image: url(https://wp.nkdev.info/godlike/wp-content/uploads/2016/10/image-3-1920x1036.jpg);
  background-size: cover;
  border: none;
  background-blend-mode: multiply;
}

.bg-img-3 {
  background: #464646;
  background-image: url(https://wp.nkdev.info/godlike/wp-content/uploads/2016/10/image-1.jpg);
  background-size: cover;
  border: none;
  background-blend-mode: multiply;
}

.bg-img-4 {
  background: #464646;
  background-image: url(https://wp.nkdev.info/godlike/wp-content/uploads/2016/10/post-5.jpg);
  background-size: cover;
  border: none;
  background-blend-mode: multiply;
}
.bg-img-5 {
  background: #464646;
  background-image: url(https://wp.nkdev.info/godlike/wp-content/uploads/2016/10/post-7.jpg  );
  background-size: cover;
  border: none;
  background-blend-mode: multiply;
}




.modal-header{
  border-bottom: 0px;
}

.modal-footer{
  border-top: 0px;
}



.flat-up{
    background:var(--bg);
    box-shadow:  var(--box-shadow)
}

.down-up{
    background: var(--bg-gr-down);
    box-shadow:  var(--box-shadow)
}

.up-up{
    background: linear-gradient(145deg, #fdffff, #d4d7dc);
    box-shadow:  var(--box-shadow)
}

.flat-down{
    background:var(--bg);
    box-shadow: inset 16px 16px 32px #c9cbcf, 
            inset -16px -16px 32px #ffffff;
}


.navbar-nav .nav-link {
    color: #fff;
}


.number{
  font-family: "Roboto", 'Helvetica Neue' , sans-serif;
  letter-spacing: -.02em;
}

.logo-icon{
    height: 1.5em;
}

.btn{
    /* transform: scale(1); */
    transition: all .5s;
}

.btn:hover{
    box-shadow: var(--box-shadow-sm);
    transition: all .5s;
    /* transform: scale(.95); */
  }

  .btn:active{
    box-shadow: var(--box-shadow-sm);
    transition: all .5s;
    /* transform: scale(.95); */
  }
  
  .btn:focus{
    box-shadow: var(--box-shadow-sm);
    transition: all .5s;
    /* transform: scale(.95); */
  }


.btn.disabled, .btn:disabled {
    opacity: 0.3;
}

.nk-btn.disabled, .nk-btn:disabled {
  opacity: 0.3;
}

.nav-link.active{
    color: var(--brand)!important;
}


.hero {
    position: relative;
    text-align: left;
    padding: 40px 0;
}

.card{
    border-radius: .5em;
    /* background: transparent; */
    border: none;
    transition: all .5s;
}

/* .nav-pills{
  box-shadow: var(--box-shadow);
  border-radius: 10rem;
  padding: 7px;
} */

.nav-link{
  cursor: pointer;
}

.nav-pills .nav-link:hover{
  transition: all .5s;
  /* box-shadow: var(--box-shadow-inset-sm); */
  border-radius: 10rem;
}
.nav-pills .nav-link.active{
  background: transparent;
  /* box-shadow: var(--box-shadow-inset); */
  border-radius: 10rem;
}

.navbar{
    padding: 1.2rem 1rem;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #fff;
}

.card-info{
    border-radius: .2em;
}

.card-pro{
    position: absolute;
    background: linear-gradient( 45deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 10%, rgba(208,222,33,1) 20%, rgba(79,220,74,1) 30%, rgba(63,218,216,1) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100% );
    filter: blur(10px);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.token-icon{
    height: 4em;
}

.token-1em{
  height: 1em;
}

.text-xs{
  font-size: 0.6em;
}

.text-sm{
  font-size: 0.8em;
}

.text-input{
  font-size: 1.1em;
  font-weight: bold;
  padding: .25em;
  word-break: break-all;
}

.text-2{
    font-size: 1.5em;
}

.text-3{
    font-size: 2em;
}
.text-4{
    font-size: 2.5em;
}
.text-5{
    font-size: 3em;
}
.text-6{
    font-size: 4em;
}





.stake-input{
    background: transparent;
    font-size: 1.8em;
    padding: .5em;
}
.stake-input:focus{
  border-color: var(--black-2)
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-control:focus {
    background-color: transparent;
    outline: none!important;
    box-shadow: none;
}


  
  .loading-title{
    font-size: 1.5em;  
  }


  .brand-highlight {
    background: white;
    color: var(--brand)
}

.btn-brand{
    color: #fff!important;
    background: var(--brand)
}

.btn-success{
  background: var(--success);
  border-color: var(--success);
}

.btn-danger{
  background: var(--danger);
  border-color: var(--danger);
}

.btn-brand:hover{
  color: var(--brand)!important;
  background: var(--brand-50)

}

.btn-outline-brand{
  color: var(--brand)!important;
  background: var(--black);
  border: 1px solid var(--brand)
}

.badge-brand {
  background: var(--brand)!important;
  border: 1px solid var(--brand);
  color: #fff;
}

.badge-brand:hover {
  background: #fff!important;
  border: 1px solid var(--brand);
  color: var(--brand);
}

.btn-outline-brand:hover{
  color: white!important;
  background: var(--brand);
}

.btn-pill{
  border-radius: 10em;
  padding-left: 2em;
  padding-right: 2em;
}


  .img-inline{
      height: 1.3em;
  }

  .img-stroke{
    -webkit-filter: drop-shadow(2px 2px 0 white)
                drop-shadow(-2px 2px 0 white)
                drop-shadow(2px -2px 0 white)
                drop-shadow(-2px -2px 0 white);

    filter: drop-shadow(2px 2px 0 white)
            drop-shadow(-2px 2px 0 white)
            drop-shadow(2px -2px 0 white)
            drop-shadow(-2px -2px 0 white);
  }

  .img-minus{
    margin-left: -1.5em;
  }

  .btn.disabled.btn-swap {
    opacity: .9;
    color: #999;
    /* background:#3dc55d; */
    /* box-shadow: var(--box-shadow-inset); */
}

.btn.disabled.btn-redeem {
  opacity: .9;
  color: #999;
  /* background:#eb505f; */
  /* box-shadow: var(--box-shadow-inset); */
}

.navbar-brand {
  font-size: 1.5rem;
}

.cube-video {
  position: absolute;
  width: 309px;
  top: -66px;
  left: -144px;
  z-index: -1;
}

.maintain-video {
  width: 309px;
}




/* INLINE LOADER */

.loader {
  height : 8px;
  width  : 36px; /* (6 * <margin: 2px>) + (3 * <width: 8px>) */
}

.loader-box {
  display                   : inline-block;
  height                    : 8px;
  width                     : 8px;
  margin                    : 0px 2px;
  background-color          : var(--brand);
  animation-name            : fadeOutIn;
  animation-duration        : 500ms;
  animation-iteration-count : infinite;
  animation-direction       : alternate;
}

.loader-box:nth-child(1) { animation-delay: 250ms; } /* (1/2) * <animation-duration: 500ms */
.loader-box:nth-child(2) { animation-delay: 500ms; } /* (2/2) * <animation-duration: 500ms */
.loader-box:nth-child(3) { animation-delay: 750ms; } /* (3/2) * <animation-duration: 500ms */

@keyframes fadeOutIn {
  0%   { background-color : var(--brand); }
  100% { background-color : #fff; }
}



.tab-title{
  padding: 1em;
  /* background: var(--brand);
  color: #fff; */

  position: relative;
  overflow: hidden;
  display: inline-block;
}

.tab-title:hover, .tab-title.active{
  color: #fff;
  border-bottom: 2px solid;
}


.tab-title.active::after{
  /* background: #fff; */
  background: #fff;
  border-radius: .5em .5em 0 0 ;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/* .tab-title:hover::after{
  background: var(--brand);
} */

.tab-title::after{
  position: absolute;
  border-radius: 4px;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: var(--brand);
  content: '';
  -webkit-transition: border-radius .3s, background-color 0.3s, -webkit-transform 0.3s;
  transition: border-radius .3s, background-color 0.3s, transform 0.3s;
  -webkit-transition-timing-function: ease, cubic-bezier(0.7,0,0.3,1);
  transition-timing-function: ease, cubic-bezier(0.7,0,0.3,1);
  -webkit-transform: translate3d(0,100%,0) translate3d(0,-3px,0);
  transform: translate3d(0,100%,0) translate3d(0,-3px,0);
}

.tab-title-container{
  margin-bottom: 0px;
}

.sw__control {
  background:transparent!important;
  padding: .1em;
  font-size: 1.2em;
  color: #fff!important;
  border: 1px solid #444!important;
  border-radius: 0em!important;
  cursor: pointer!important;
}

.sw__control:hover {
  background:var(--brand)!important;
  transition: background-color .5s ease;
}

.sw__control:focus, .sw__control.sw__control--is-focused{
  outline: none;
  box-shadow: none;
}

.sw__value-container.sw__value-container--has-value {
  background: transparent!important;
}

.sw__single-value{
  color: #fff!important;
}

.sw__menu{
  background: var(--brand) !important;
  border-radius: 0em!important;
  text-align: left;

}


.sw__menu-list{
  padding: 0 !important;
  color: #fff;
  border-radius: 0em!important;

}

.sw__option{
  padding: 0.4em!important;
  cursor: pointer!important;

}

.sw__option:hover{
  background-color: rgb(255, 184, 30);
}

/* .sw__option:hover, .sw__option:focus{
  background: var(--brand-2);
} */

.sw__option.sw__option--is-selected{
    background: rgb(255, 191, 107);
    color: var(--brand);
}

.sw__indicator{
  color: white!important;
}


.card.card-focus{
  text-align: center;
  border: none;
  background-color: #0e0e0e;
}

.card.card-tab{
  text-align: center;
  border: none;
  background-color: #0e0e0e;
  border-radius: 0 0.5em .5em .5em;
}

.row-title{
  min-width: 10em;
}

.row-title-lg{
  min-width: 15em;
}


.radios {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio input {
  position: absolute;
  pointer-events: none;
  visibility: hidden;
}
.radio input:focus + label {
  background: #ffefd9;
  color: var(--brand)
}
.radio input:focus + label .checker {
  border-color: var(--brand);
}

.radio label {
  display: flex;
  align-items: center;
  height: 28px;
  border-radius: 14px;
  margin: 10px;
  padding: 0 8px 0 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.radio label:hover {
  background: #ffefd9;
  color: var(--brand)

}
.radio label:hover .checker {
  box-shadow: inset 0 0 0 2px var(--brand);
}
.radio .checker {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 8px;
  box-shadow: inset 0 0 0 2px #ccc;
  transition: box-shadow 0.3s ease;
}

.radio .checker.checked {
  box-shadow: inset 0 0 0 6px var(--brand)!important;
}

textarea.form-control{
  resize: none;
}

.form-tight{
  height: auto;
  padding: .8em;
}

.statis-bar{
  padding: 2em 1em;
  border-radius: .5em;
}

.note{
  word-break: break-all;
  font-family: 'OxygenMono', monospace;
  letter-spacing: -.02em;
}


.nk-navbar .nk-nav > li > a {
  padding: 5px 15px;
}

/* LOADER */
.content {
  width: 100%;
  margin: 4em auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content .loading {
  width: 80px;
  height: 50px;
  position: relative;
}
.content .loading p {
  top: 0;
  padding: 0;
  margin: 0;
  color: var(--brand);
  animation: text 3.5s ease both infinite;
  font-size: 12px;
  letter-spacing: 1px;
}
@keyframes text {
  0% {
    letter-spacing: 1px;
    transform: translateX(0px);
  }
  40% {
    letter-spacing: 2px;
    transform: translateX(26px);
  }
  80% {
    letter-spacing: 1px;
    transform: translateX(32px);
  }
  90% {
    letter-spacing: 2px;
    transform: translateX(0px);
  }
  100% {
    letter-spacing: 1px;
    transform: translateX(0px);
  }
}
.content .loading span {
  background-color: #fcc591;
  border-radius: 50px;
  display: block;
  height: 16px;
  width: 16px;
  bottom: 0;
  position: absolute;
  transform: translateX(64px);
  animation: loading 3.5s ease both infinite;
}
.content .loading span:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--brand);
  border-radius: inherit;
  animation: loading2 3.5s ease both infinite;
}
@keyframes loading {
  0% {
    width: 16px;
    transform: translateX(0px);
  }
  40% {
    width: 100%;
    transform: translateX(0px);
  }
  80% {
    width: 16px;
    transform: translateX(64px);
  }
  90% {
    width: 100%;
    transform: translateX(0px);
  }
  100% {
    width: 16px;
    transform: translateX(0px);
  }
}
@keyframes loading2 {
  0% {
    transform: translateX(0px);
    width: 16px;
  }
  40% {
    transform: translateX(0%);
    width: 80%;
  }
  80% {
    width: 100%;
    transform: translateX(0px);
  }
  90% {
    width: 80%;
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0px);
    width: 16px;
  }
}

.z-1{
  z-index: -1;
}

.nk-btn::before{
  z-index: inherit;
}

#react-select-35-input{
  display: none;
}

.project{
 cursor:pointer;
 max-width: 90%;

}

.img-nft{
  max-width: 100%;
  border-radius: 12px;
}


a.nk-nav-logo {
  /* display: block!important; */
  width: 160px!important;
}

.scale-down, .scale-up{
  transition-duration: .3s;
  -webkit-transform: scaleX(100%) scaleY(100%);
  transform: scaleX(100%) scaleY(100%);
}

.scale-down:hover{
  -webkit-transform: scaleX(90%) scaleY(90%);
  transform: scaleX(90%) scaleY(90%);
}

.scale-up:hover{
  -webkit-transform: scaleX(110%) scaleY(110%);
  transform: scaleX(110%) scaleY(110%);
}

.translate-up{
  transition-duration: .3s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.translate-up:hover{
  -webkit-transform: translateY(0);
  transform: translateY(-10px);
}

.bottom-space{
  margin-bottom: 2rem;
}