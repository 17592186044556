@font-face {
  font-family: 'Helvetica Neue';
  /* font-weight: bold; */
  src: local('Helvetica Neue'), url(../font/HelveticaNeue.ttc) format('OpenType');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(../font/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Condensed';
  src: local('Roboto Condensed'), url(../font/RobotoCondensed-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Marcellus SC';
  font-weight: normal;
  src: local('Marcellus SC'), url(../font/MarcellusSC-Regular.ttf) format('truetype');
}